import React from "react";
import { Grid, Typography } from "@material-ui/core";
import SectionContainer from "./sectionContainer";
import ReactSpeedometer from "react-d3-speedometer";
import useNpsData from "./useNpsData";

const NPSData = ({ data }) => {

    const {
        npsValue,
        npsVotesData,
        npsResultData,
        npsResultDataItem,
        getOptionData,
    } = useNpsData(data);

    return (
        <>
            <SectionContainer title="Distribución de respuestas" ignoreContainer>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {
                        [...Array(10).keys()].map((item, index) => {
                            const optionData = getOptionData(index);
                            return (
                                <div key={item} style={{ padding: 10, margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {optionData.icon}
                                    <Typography align="center" className="whiteText" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        {item + 1}
                                    </Typography>
                                    <Typography align="center" className="whiteText" style={{ fontWeight: 600, fontSize: '2rem' }}>
                                        {optionData.votes}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            </SectionContainer>
            <Grid container item xs={12}>
                <Grid container direction='row' item xs={12} md={6} alignItems="center">
                    <SectionContainer title="Porcentajes" useRightBorder>
                        {
                            npsVotesData.map((item) => {
                                return (
                                    <Grid key={item.title} container xs={12} alignItems="center" justify="center" item style={{ minHeight: 45 }}>
                                        <Grid container item xs={6} alignItems="center">
                                            <div style={{ height: 20, width: 20, backgroundColor: item?.color, marginRight: 15 }} />
                                            <Typography className='whiteText' style={{ fontSize: 20, textTransform: 'capitalize' }}>
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                {item.count}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {
                                                Boolean(item?.percentage) &&
                                                <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                    {`${item.percentage}%`}
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </SectionContainer>
                </Grid>
                <Grid container direction='row' item xs={12} md={6}>
                    <SectionContainer title='Valor NPS'>
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    border: `10px solid ${npsResultDataItem.color}`,
                                    height: 200,
                                    width: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                    {'NPS'}
                                </Typography>
                                <Typography style={{ fontSize: 40, fontWeight: 600, color: npsResultDataItem.color }}>
                                    {npsValue}
                                </Typography>
                            </div>
                        </div>
                    </SectionContainer>
                </Grid>
            </Grid>
            <SectionContainer title={npsResultDataItem.title} ignoreContainer>
                <div className="flex flex-col items-center">
                    <ReactSpeedometer
                        maxValue={100}
                        minValue={-100}
                        value={npsValue}
                        segments={5}
                        customSegmentStops={[-100, 0, 50, 75, 100]}
                        needleColor={"#CCC"}
                        segmentColors={npsResultData.map(item => item.color)}
                        height={200}
                        textColor={"var(--mainText)"}
                        needleTransitionDuration={0}
                    />
                    <div className="flex justify-center flex-wrap" style={{ marginBottom: 20 }}>
                        {
                            npsResultData.map((item) => {
                                return (
                                    <div key={item.sectionTitle} className="flex items-center justify-center" style={{ margin: '0px 10px' }}>
                                        <div style={{ height: 20, width: 20, backgroundColor: item.color, marginRight: 10 }} />
                                        <Typography className='whiteText' style={{ fontSize: 20 }}>
                                            {item.sectionTitle}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </SectionContainer>
        </>
    );
}

export default NPSData;
