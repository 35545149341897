import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidNameCallback, invalidNPSCallback, isDuplicate, pollNames) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'startDate' || fieldName === 'endDate') {
        if (isCreate) return null;
        if (isDuplicate) {
          if (data.useForOnboarding === "Y") return new Date();
          return null;
        }
        
        return createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'pollName') {
        return (isCreate || isDuplicate) ? '' : data[fieldName];
      }

      if (fieldName === 'mode') {
        return isCreate ? 'P' : data[fieldName];
      }

      if (fieldName === 'highlighted') {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'isAnonymous') {
        return isCreate ? 'Y' : data[fieldName];
      }

      if (fieldName === 'useForOnboarding') {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'questions') {
        return isCreate ? [] : data[fieldName];
      }

      if (fieldName === 'points') {
        return isCreate ? 0 : (data[fieldName] || 0);
      }

      if (fieldName === 'files') {
        return isCreate ? null : (data.fileName ? [{ name: data.fileName }] : null);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "pollName",
      "pollDescription",
      "type",
      "visualizationRole",
      "mode",
      "highlighted",
      "isAnonymous",
      "useForOnboarding",
      "startDate",
      "endDate",
      "pollFamily",
      "pollReference",
      "questions",
      "process",
      "image",
      "points",
      "files",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const {
    pollName,
    pollDescription,
    type,
    mode,
    visualizationRole,
    startDate,
    endDate,
    pollFamily,
    pollReference,
    highlighted,
    isAnonymous,
    useForOnboarding,
    questions,
    process,
    image,
    points,
    files,
  } = formData;

  const datesAreValid = () => {
    const newStartDate = startDate.setHours(0, 0, 0, 0).valueOf();
    const newEndDate = endDate.setHours(0, 0, 0, 0).valueOf();
    return newStartDate <= newEndDate;
  }

  const questionsAreValid = () => {
    if (questions.length === 0) return false;

    for (const { question, type, options } of questions) {
      if (!question || !type) return false;

      //si es de opciones
      if (type === "O" || type === "L") {
        if (options.length < 2) return false;
        if (options.some(option => !option)) return false;
      }
    }

    return true;
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'pollName': yup.string().required(),
      'pollDescription': yup.string().required(),
      'type': yup.string().required(),
      'mode': yup.string().required(),
      'visualizationRole': yup.string().required(),
      'startDate': yup.date().required(),
      'endDate': yup.date().required(),
      'process': yup.string(),
      'pollFamily': yup.string()
        .when('type', {
          is: (value) => value === 'C' || value === 'N',
          then: yup.string().required()
        }),
      'pollReference': yup.string()
        .when('type', {
          is: (value) => value === 'C' || value === 'N',
          then: yup.string().required()
        }),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const nameIsValid = () => {
    if (!isCreate && !isDuplicate) return true;
    return !(pollNames.includes(pollName));
  }

  const npsIsValid = () => {
    if (isDuplicate) return true;
    if (type !== 'N') return true;
    if (questions.filter(item => item.type === 'O').length !== 1) return false;
    const { options } = questions.find(item => item.type === 'O');
    if (options.length !== 10) return false;
    return options.every((item, index) => item === (index + 1).toString());
  }

  const submit = async () => {
    if (await dataIsValid() && datesAreValid() && questionsAreValid()) {
      if (npsIsValid()) {
        if (nameIsValid()) {
          const dataToSend = {
            pollName,
            pollDescription,
            type,
            mode,
            highlighted,
            isAnonymous,
            image,
            visualizationRole,
            process,
            useForOnboarding,
            startDate: formatDateYYYYMMDD(startDate),
            endDate: formatDateYYYYMMDD(endDate),
          }

          if (type === "C" || type === "N") {
            dataToSend.pollFamily = pollFamily;
            dataToSend.pollReference = pollReference;
          }

          if (isAnonymous === "N") {
            dataToSend.points = points;
          }

          if (useForOnboarding === "Y") {
            dataToSend.startDate = formatDateYYYYMMDD(new Date());
            dataToSend.endDate = formatDateYYYYMMDD(new Date());
          }

          const hasRealFile = Boolean(files?.[0]?.size); // si no tiene size, esta seteado el mockeado con el fileName que vino de back
          dataToSend.FILES = hasRealFile ? files[0] : null;

          if (!isCreate) {
            // si tenia archivo, y ahora no tengo, es porque borre en local y hay que borrar en back
            dataToSend.deleteFile = Boolean(data.fileName && files === null);
            dataToSend.code = data.code;
          }

          dataToSend.questions = JSON.stringify(questions.map((question) => {
            const newFormattedQuestion = {
              question: question.question,
              type: question.type,
              image: question.image,
            };

            if (question.type === "L" || question.type === "O") {
              question.options.forEach((option, index) => {
                newFormattedQuestion[`option${index + 1}`] = option;
              })
            }
            return newFormattedQuestion;
          }));

          (isCreate || isDuplicate) ? createCallback(dataToSend) : modifyCallback(dataToSend);
          return true;
        } else {
          invalidNameCallback();
          return false;
        }
      } else {
        invalidNPSCallback();
        return false;
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const createQuestion = () => {
    //agregar el objeto default a questions
    const newEmptyQuestion = {
      question: "",
      type: "",
      image: "",
      options: [],
    }

    setFormValue([...questions, newEmptyQuestion], 'questions');
  }

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setFormValue(newQuestions, 'questions');
  }

  const duplicateQuestion = (index) => {
    const { question, type, image, options } = questions[index];
    setFormValue([...questions, { question, type, image, options }], 'questions');
    dispatch(openSnackbarAction({ msg: 'Pregunta duplicada', severity: 'info' }));
  }

  const modifyQuestion = (index, value, fieldName) => {
    const updatedQuestion = { ...questions[index] };
    updatedQuestion[fieldName] = value;

    // si se setea tipo Descripción, borrarle las opciones
    if (fieldName === 'type' && value === 'D') {
      updatedQuestion.option = [];
    }

    const newQuestions = [...questions];
    newQuestions.splice(index, 1, updatedQuestion);
    setFormValue(newQuestions, 'questions');
  }


  const setFormValue = (value, fieldName) => {
    if (fieldName === 'dateFrom') {
      //si la nueva fecha inicio es mayor a la fecha fin, borrar la fecha fin
      setFormData(prev => ({
        ...prev,
        [fieldName]: value,
        endDate: (prev.endDate && !datesAreValid(value, prev.endDate)) ? null : prev.endDate,
      }));

      return;
    }

    if (fieldName === 'useForOnboarding' && value === "Y") {
      setFormData(prev => ({
        ...prev,
        [fieldName]: value,
        visualizationRole: "TD",
      }));

      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    createQuestion,
    deleteQuestion,
    duplicateQuestion,
    modifyQuestion,
    submit,
  };
}

export default useHandleForm;
