import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, FileCopyIcon, FilterIcon } from "@icarius-icons";
import { FiltersDialog } from "@icarius-common/filtersDialog";
import useFilterDialog from "@icarius-common/filtersDialog/components/useFilterDialog";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { GetAppIcon } from "@icarius-icons/index";
import Form from "./form";
import { deletePollSettingsAction, getPollSettingsAction } from "../../actions";
import DeleteDialog from "../dialogs/deleteDialog";

const Item = (props) => {

  const {
    data,
    pollNames,
    roles,
    modes,
    processList,
    pollTypes,
    color,
    closeNewPoll,
    openDuplicateDialog,
    exportPoll,
    isLoading,
    getIsActive,
  } = props;

  const dispatch = useDispatch();
  const hideWhitespace = useMediaQuery('(max-width: 959px)')

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const { filtersDialogIsOpen, setFiltersDialogIsOpen } = useFilterDialog();

  const handleDeletePoll = () => {
    if (isCreate) {
      closeNewPoll();
    } else {
      dispatch(deletePollSettingsAction(data.code, data.pollName));
    }
  }

  const roleText = useMemo(() => {
    if (isCreate || !data.visualizationRole) return "";

    return roles.find((role) => role.key === data.visualizationRole).value;
  }, [data, roles, isCreate])

  const pollTypeText = useMemo(() => {
    if (isCreate || !data.type) return "";

    return `${pollTypes.find((type) => type.key === data.type).value}${data.isAnonymous === "Y" ? "/Anónima" : ""}${data.useForOnboarding === "Y" ? "/De Onboarding" : ""}`;
  }, [data, pollTypes, isCreate])

  const handleClosePoll = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const getHasFilters = () => {
    return Object.keys(data.filterData).some((filterName) => !data.filterData[filterName].includes("-"));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePoll}>
            {
              isCreate ?
                <DataContainer sm={12} md={12} lg={12} text={getLocalizedString("pollCreation")} />
                :
                <>
                  <DataContainer lg={4} md={4} sm={12} text={data.pollName} />
                  <DataContainer lg={4} md={4} sm={6} text={getLocalizedString(getIsActive(data) ? "active" : "inactive")} />
                  <DataContainer lg={4} md={4} sm={6} text={`${data.startDate} - ${data.endDate}`} />
                  {!hideWhitespace && <DataContainer lg={4} md={4} sm={6} text={''} />}
                  <DataContainer lg={4} md={4} sm={6} text={pollTypeText} />
                  <DataContainer lg={4} md={4} sm={6} text={roleText} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={2} justify={isCreate ? "flex-end" : "space-around"}>
            {
              !isCreate &&
              <Tooltip title={'Exportar encuesta'}>
                <IconButton size={"small"} disableRipple onClick={() => exportPoll(data)}>
                  <GetAppIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            {
              !isCreate &&
              <Tooltip title={getLocalizedString("pollDuplication")}>
                <IconButton size={"small"} disableRipple onClick={() => openDuplicateDialog(data)}>
                  <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            {
              !isCreate && data.useForOnboarding !== "Y" &&
              <Tooltip title={getLocalizedString("filter")}>
                <IconButton size={"small"} disableRipple onClick={() => setFiltersDialogIsOpen(true)}>
                  <FilterIcon style={{ fill: getHasFilters() ? color : "var(--icons)", height: "15px" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => setDeleteDialogIsOpen(true)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          closePoll={isCreate ? closeNewPoll : handleClosePoll}
          data={data}
          roles={roles}
          modes={modes}
          processList={processList}
          pollTypes={pollTypes}
          pollNames={pollNames}
        />
      }
      {
        deleteDialogIsOpen &&
        <DeleteDialog
          open={deleteDialogIsOpen}
          poll={data}
          handleClose={() => setDeleteDialogIsOpen(false)} handleDelete={handleDeletePoll}
        />
      }
      {
        !isCreate && filtersDialogIsOpen && !isLoading &&
        <FiltersDialog
          open={filtersDialogIsOpen}
          type={"ENC"}
          code={data.code}
          data={data.filterData}
          isDisabled={data?.inUse}
          handleFetchUpdatedData={() => dispatch(getPollSettingsAction())}
          handleClose={() => setFiltersDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default Item;
