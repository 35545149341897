import React from "react";
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import MoodIcon from '@material-ui/icons/Mood';
import { generalColors, getNPSColor } from "@icarius-utils/colors";

const useNpsData = (data) => {

    const COUNT_GOOD_MIN_VALUE = 9;
    const COUNT_BAD_MAX_VALUE = 6;

    const getPercentage = (amount) => (amount / data.length * 100).toFixed(0);
    const getNPSIcon = (index) => {
        if (index > 7) return <MoodIcon style={{ fill: getNPSColor(index + 1) }} />;
        if (index > 5) return <SentimentSatisfiedIcon style={{ fill: getNPSColor(index + 1) }} />;
        return <MoodBadIcon style={{ fill: getNPSColor(index + 1) }} />;
    };

    const npsAnswersCount = {
        bad: data.filter(item => Number(item.RESPUESTA) <= COUNT_BAD_MAX_VALUE)?.length || 0,
        neutral: data.filter(item => Number(item.RESPUESTA) > COUNT_BAD_MAX_VALUE && Number(item.RESPUESTA) < COUNT_GOOD_MIN_VALUE)?.length || 0,
        good: data.filter(item => Number(item.RESPUESTA) >= COUNT_GOOD_MIN_VALUE)?.length || 0,
    }

    const npsAnswersPercentage = {
        bad: getPercentage(npsAnswersCount.bad),
        neutral: getPercentage(npsAnswersCount.neutral),
        good: getPercentage(npsAnswersCount.good),
    }

    const npsVotesData = [
        {
            title: 'Detractores',
            percentage: npsAnswersPercentage.bad,
            count: npsAnswersCount.bad,
            color: generalColors.red,
        },
        {
            title: 'Neutros',
            percentage: npsAnswersPercentage.neutral,
            count: npsAnswersCount.neutral,
            color: generalColors.yellow,
        },
        {
            title: 'Promotores',
            percentage: npsAnswersPercentage.good,
            count: npsAnswersCount.good,
            color: generalColors.green,
        },
        {
            title: 'Total',
            count: data.length,
        }
    ];

    const npsValue = npsAnswersPercentage.good - npsAnswersPercentage.bad;

    const npsResultData = [
        {
            title: 'NPS crítico',
            color: generalColors.red,
            sectionTitle: '-100 a -1 (crítico)',
        },
        {
            title: 'NPS bueno',
            color: generalColors.yellow,
            sectionTitle: '0 a 49 (bueno)',
        },
        {
            title: 'NPS muy bueno',
            color: generalColors.blue,
            sectionTitle: '50 a 74 (muy bueno)',
        },
        {
            title: 'NPS excelente',
            color: generalColors.green,
            sectionTitle: '75 a 100 (excelente)',
        },
    ];

    const npsResultDataItem = (() => {
        if (npsValue >= 75) return npsResultData[3];
        if (npsValue >= 50) return npsResultData[2];
        if (npsValue >= 0) return npsResultData[1];
        return npsResultData[0];
    })();

    const getOptionData = (option) => {
        return ({
            votes: data.filter(item => Number(item.RESPUESTA) === option + 1).length || 0,
            icon: getNPSIcon(option),
        })
    };

    return {
        npsValue,
        npsVotesData,
        npsResultData,
        npsResultDataItem,
        getOptionData,
    }
}

export default useNpsData;
