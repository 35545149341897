import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip, ListItem } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, FilterIcon } from "@icarius-icons";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import PublicationForm from "./publicationForm";
import { deletePublicationSettingsAction, getPublicationSettingsAction } from "../../actions";
import { FiltersDialog } from "@icarius-common/filtersDialog";
import useFilterDialog from "@icarius-common/filtersDialog/components/useFilterDialog";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import DateDisplay from "@icarius-common/abmComponents/dateDisplay";
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from "@material-ui/core/styles";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { openDialogAction } from "@icarius-common/dialog/actions";

const useStyles = makeStyles({
  noPadding: {
    padding: '0px'
  }
});

const Publication = (props) => {

  const { data, index, roles, publicationTypes, color, closeNewPublication, isLoading } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const { filtersDialogIsOpen, setFiltersDialogIsOpen } = useFilterDialog();

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNewPublication();
    } else {
      dispatch(deletePublicationSettingsAction(data.code));
    }
  }

  const isActive = useMemo(() => {
    if (isCreate) return false;

    const newEndDate = createDateFromDDMMYYYY(data.endDate).setHours(0, 0, 0, 0).valueOf();
    const newStartDate = createDateFromDDMMYYYY(data.startDate).setHours(0, 0, 0, 0).valueOf();

    const today = new Date().setHours(0, 0, 0, 0).valueOf();

    return today >= newStartDate && today <= newEndDate;
  }, [data, isCreate])

  const roleText = useMemo(() => {
    if (isCreate || !data.visualizationRole) return "";

    return roles.find((role) => role.key === data.visualizationRole).value;
  }, [data, roles, isCreate])

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const getHasFilters = () => {
    return Object.keys(data.filterData).some((filterName) => !data.filterData[filterName].includes("-"));
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("publicationDeleteDialogTitle"),
      msg: getLocalizedString("confirmDeletePublication"),
      onConfirm: handleDeletePublication,
    }));
  }

  const publicationItem = (
    <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
      <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
        <Grid container item xs={1}>
          <DragIndicatorIcon />
        </Grid>
        <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
          {
            isCreate ?
              <DataContainer lg={12} sm={12} md={12} text={getLocalizedString("publicationCreation")} />
              :
              <>
                <DataContainer sm={12} lg={2} md={4} text={data.name} />
                <DataContainer lg={1} md={2} text={getLocalizedString(isActive ? "active" : "inactive")} />
                <DataContainer lg={3}>
                  <DateDisplay label={getLocalizedString("publicationFrom")} date={data.startDate} />
                </DataContainer>
                <DataContainer lg={3}>
                  <DateDisplay label={getLocalizedString("publicationTo")} date={data.endDate} />
                </DataContainer>
                <DataContainer sm={8} md={9} lg={3} text={roleText} />
              </>
          }
        </Grid>
        <Grid container direction="row" item xs={1} justify="space-between">
          {
            !isCreate &&
            <Tooltip title={getLocalizedString("filter")}>
              <IconButton size={"small"} disableRipple onClick={() => setFiltersDialogIsOpen(true)}>
                <FilterIcon style={{ fill: getHasFilters() ? color : "var(--icons)", height: "15px" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title={getLocalizedString("delete")}>
            <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
              <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  )


  return (
    <>
      {
        isCreate ?
          publicationItem
          :
          <Draggable draggableId={data.code} index={index}>
            {(provided) => (
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classes.noPadding}
              >
                {publicationItem}
              </ListItem>
            )}
          </Draggable>
      }
      {
        isOpen &&
        <PublicationForm
          color={color}
          data={data}
          roles={roles}
          publicationTypes={publicationTypes}
          handleClose={isCreate ? closeNewPublication : handleClosePublication}
        />
      }
      {
        !isCreate && filtersDialogIsOpen && !isLoading &&
        <FiltersDialog
          open={filtersDialogIsOpen}
          type={"PUB"}
          code={data.code}
          data={data.filterData}
          handleFetchUpdatedData={() => dispatch(getPublicationSettingsAction())}
          handleClose={() => setFiltersDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default Publication;
