import * as actionTypes from "./actionTypes";
import { getPollSettingsAPI, deletePollSettinsAPI, createPollSettinsAPI, modifyPollSettinsAPI } from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE_ONBOARDING":
      errorString = 'La encuesta está en uso en los materiales y recursos de Onboarding, no puede ser eliminada';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getPollSettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_POLL_SETTINGS });
  try {
    let response = await getPollSettingsAPI();

    let polls = response.data && response.data.result;
    let pollTypes = response.data && response.data.pollTypes;
    let questionTypes = response.data && response.data.questionTypes;
    let processList = response.data && response.data.processList;
    let modes = response.data && response.data.modes;
    let roles = response.data && response.data.roles;
    let dateFormat = response.data && response.data.date_format;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_POLL_SETTINGS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_POLL_SETTINGS_FULFILLED,
      payload: {
        polls,
        pollTypes,
        questionTypes,
        roles,
        dateFormat,
        modes,
        processList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_POLL_SETTINGS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deletePollSettingsAction = (code, pollName) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_POLL_SETTINGS });
  try {
    let response = await deletePollSettinsAPI({ code, pollName });

    let polls = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_POLL_SETTINGS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_POLL_SETTINGS_FULFILLED,
      payload: { polls },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("pollDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_POLL_SETTINGS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createPollSettingsAction = (poll) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_POLL_SETTINGS });
  try {
    let formData = new FormData();
    Object.keys(poll).forEach((key) => formData.append(key, poll[key]));
    let response = await createPollSettinsAPI(formData);

    let polls = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_POLL_SETTINGS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_POLL_SETTINGS_FULFILLED,
      payload: { polls },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("pollCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_POLL_SETTINGS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyPollSettingsAction = (poll) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_POLL_SETTINGS });
  try {
    let formData = new FormData();
    Object.keys(poll).forEach((key) => formData.append(key, poll[key]));

    let response = await modifyPollSettinsAPI(formData);

    let polls = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_POLL_SETTINGS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_POLL_SETTINGS_FULFILLED,
      payload: { polls },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("pollModifiedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_POLL_SETTINGS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};