import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublicationSettingsAction } from "../actions";
import { getLoading, getPublications, getRoles, getPublicationTypes } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import Publication from "./publication/publication";
import NoPublications from "./publication/noPublications";
import { RESET_STATE } from "../actionTypes";
import { getGalleryIsLoading } from "@icarius-common/galleryDialog/selectors";
import { getFiltersIsLoading } from "@icarius-common/filtersDialog/selectors";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import PublicationTypeList from './publicationTypeList';

const PublicationSettings = () => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const roles = useSelector(getRoles);
  const publications = useSelector(getPublications);
  const publicationTypes = useSelector(getPublicationTypes);
  const isLoadingLocal = useSelector(getLoading);
  const galleryIsLoading = useSelector(getGalleryIsLoading);
  const filtersIsLoading = useSelector(getFiltersIsLoading);

  const isLoading = isLoadingLocal || galleryIsLoading || filtersIsLoading;

  useEffect(() => {
    dispatch(getPublicationSettingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getPublicationsIsEmpty = () => {
    return !publications || (publications && publications.every(publicationArr => publicationArr.length === 0));
  }

  return (
    <ABMLayout
      title={getLocalizedString("publicationSettings")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newPublication")}
      createButtonOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Publication
            data={null}
            roles={roles}
            publicationTypes={publicationTypes}
            color={color}
            isLoading={isLoading}
            closeNewPublication={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        publications && publications.map((publicationArr, index) => {
          return (
            publicationArr.length > 0 ? (
              <PublicationTypeList
                key={index}
                index={index}
                publicationArr={publicationArr}
                publicationTypes={publicationTypes}
                roles={roles}
                color={color}
                isLoading={isLoading}
              />
            ) : null
          )
        })
      }
      {
        !isLoading && getPublicationsIsEmpty() &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <NoPublications
            onClick={() => setCreateIsOpen(true)}
          />
        </Grid>
      }
    </ABMLayout>
  )
}

export default PublicationSettings;