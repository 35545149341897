import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import RatingRenderer from "@icarius-table/renderersAndFilters/ratingRenderer";
import IndicatorRenderer from "@icarius-table/renderersAndFilters/indicatorRenderer";
import RichTextRenderer from "@icarius-table/renderersAndFilters/richTextRenderer";
import NineBoxImageRenderer from "@icarius-table/renderersAndFilters/nineBoxImageRenderer";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import ProgressFilter from "@icarius-table/renderersAndFilters/progressFilter";
import {
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  IconButton,
} from "@material-ui/core";
import {
  getQueryResultAction,
  getTemplatesAction,
  exportQueryAction,
  importQueryAction,
  getQueryGroupsAction
} from "../actions";
import {
  getFilters,
  getDateFormat,
  getQueryResult,
  getTemplatesRows,
  getIsLoadingTemplates,
  getLocale,
  getContext,
} from "../selectors";
import {
  DeleteIcon,
  SaveIcon,
  AddIcon,
  PublishIcon,
  GetAppIcon,
} from "@icarius-icons";
import {
  tooltipOptions,
  setChartType,
  createAggridColumn,
} from "@icarius-table/utils";
import { RESET_ROWS } from "../actionTypes";
import TemplateDialog from "./dialog/templateDialog";
import UpdateTemplate from "./dialog/updateDialog";
import SaveTemplate from "./dialog/saveTemplate";
import CommonPage from "@icarius-common/commonPage";
import SetParamDialog from "@icarius-common/setParamDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DotsMenu from "@icarius-common/dotsMenu";
import { customFileTypes, templatesOptions } from "@icarius-utils/constants";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { FavouriteButton } from "@icarius-common/favouriteButton";
import { getAppColor, getUserData } from "src/app/selectors";
import ContextView from "./contextView";
import { downloadBlob } from "@icarius-utils/download";
import { exportIcariusSmart } from "@icarius-table/exportFile";

class GridScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "",
      templateSeleccionado: null,
      showOnlyCurrentEmployees: true,
      queryCodeSelected: "",
      gridDef: [],
      updateDialogIsOpen: false,
      templateDialogIsOpen: false,
      createTemplateDialogIsOpen: false,
      setParamDialogIsOpen: false,
      currentParam: null,
      alreadyPresetQuery: false,
      viewContext: false,
    };
    this.currentChartRef = null;
  }

  componentDidUpdate() {
    const { queries, queryPreset, userPreset } = this.props;
    if (!this.state.alreadyPresetQuery) {
      if (queryPreset) {
        const query = queries.find(o => o.code === queryPreset);
        if (query) {
          this.setState({
            queryCodeSelected: queryPreset,
            template: "",
            templateSeleccionado: null,
            alreadyPresetQuery: true
          });

          if (query.parameters !== undefined) {
            this.handleOpenParamDialog(query.parameters);
          } else {
            //llamado a la api de ejecutar consulta
            this.props.dispatch(getQueryResultAction({ code: queryPreset, employeeCode: userPreset }))
              .then(() => this.setState({ gridDef: this.createColumnDefs() }));
            this.props.dispatch(getTemplatesAction(queryPreset));
          }
        }
      }
    }
  }

  createColumnDefs = () => {
    let columnDefs = [];
    for (let prop in this.props.filters) {
      if (Object.prototype.hasOwnProperty.call(this.props.filters, prop)) {
        let item = createAggridColumn(prop, this.props.filters[prop]);

        // si es el primero agregar checkbox
        if (Object.keys(this.props.filters)[0] === prop) {
          item.checkboxSelection = (params) => params.columnApi.getRowGroupColumns().length === 0;
          item.headerCheckboxSelection = (params) => params.columnApi.getRowGroupColumns().length === 0;
          item.headerCheckboxSelectionFilteredOnly = true;
          item.enableRowGroup = true;
        }

        columnDefs.push(item);
      }
    }
    return columnDefs;
  }

  handleCloseParamDialog = () => this.setState({ setParamDialogIsOpen: false, currentParam: null });
  handleOpenParamDialog = (params) => {
    this.setState({ setParamDialogIsOpen: true, currentParam: params });

  }

  handleOpenTemplateDialog = () => this.setState({ templateDialogIsOpen: true });
  handleCloseTemplateDialog = () => this.setState({ templateDialogIsOpen: false });
  handleOpenUpdateDialog = () => this.setState({ updateDialogIsOpen: true });
  handleCloseUpdateDialog = () => this.setState({ updateDialogIsOpen: false });
  handleOpenCreateTemplateDialog = () => this.setState({ createTemplateDialogIsOpen: true });
  handleCloseCreateTemplateDialog = () => this.setState({ createTemplateDialogIsOpen: false });

  handleImportTemplates = (gridRef) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = `.${customFileTypes.icarius}`;
    input.onchange = e => {

      // getting a hold of the file reference
      let file = e.target.files[0];

      // setting up the reader
      let reader = new FileReader();
      reader.readAsText(file); // this is reading as data url
      // here we tell the reader what to do when it's done reading...
      reader.onload = event => {
        try {
          const utf8Object = this.b64_to_utf8(event.target.result);
          const parsedObject = JSON.parse(utf8Object);
          if (parsedObject.query && parsedObject.parameters) {
            this.uploadTemplateToServer(parsedObject, gridRef);
          } else {
            this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("incorrectQuery"), severity: "error" }));
          }
        } catch (e) {
          this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("incorrectQuery"), severity: "error" }));
        }
      }

    }

    input.click();
  }

  uploadTemplateToServer = (parsedObject, gridRef) => {
    this.props.dispatch(importQueryAction(parsedObject)).then(response => {
      if (response.data.status === "OK") {
        this.props.dispatch(getQueryGroupsAction());
        this.props.handleCloseGrid();
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("queryImport"), severity: "success" }));
      } else {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
    }).catch(e => {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    });
  }

  handleExportTemplates = (exportTemplates) => {
    const { queryCodeSelected } = this.state;
    const exportObject = { code: queryCodeSelected, exportTemplates: exportTemplates };
    // Llamo al backend para que me pase toda la data

    // Ahora que ya tengo esto, genero el archivo
    this.props.dispatch(exportQueryAction(exportObject)).then(resp => {
      try {
        if (resp.data.status === "OK") {
          const data = resp.data.result;

          const encryptedObject = this.utf8_to_b64(JSON.stringify(data, undefined, 1));

          let element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encryptedObject);
          element.setAttribute('download', `${queryCodeSelected}.${customFileTypes.icarius}`);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        } else {
          this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
        }
      } catch (e) {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
    });
  }

  utf8_to_b64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
  }

  resetGrid = (gridRef) => {
    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    gridRef.api.onFilterChanged();
    gridRef.api.onSortChanged();

    if (this.currentChartRef) {
      this.currentChartRef.destroyChart();
      this.currentChartRef = null;
    }
  }

  getRole = (role) => {
    const foundItem = templatesOptions.find(t => t.code === role);
    if (foundItem) return foundItem.label;
    return "";
  }

  handleSelectQueryWithParams = (params, gridRef) => {
    const query = { code: this.state.queryCodeSelected, parameters: params, employeeCode: this.props.userPreset }
    //pegarle a la api de ejecutar
    //pegarle a la api de traer templates
    this.props.dispatch(getQueryResultAction(query)).then(resp => this.setState({ gridDef: this.createColumnDefs() }));
    this.props.dispatch(getTemplatesAction(this.state.queryCodeSelected));

    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  }

  handleSelectQuery = (e, queries, gridRef) => {
    if (e.target.value !== this.state.queryCodeSelected) {

      // Vacio la grilla
      this.props.dispatch({ type: RESET_ROWS });
      this.resetGrid(gridRef);

      const query = queries.find(o => o.code === e.target.value);
      if (query) {

        this.setState({ queryCodeSelected: e.target.value, template: "", templateSeleccionado: null });
        if (query.parameters !== undefined) {
          this.handleOpenParamDialog(query.parameters);
        } else {
          //llamado a la api de ejecutar consulta
          this.props.dispatch(getQueryResultAction({ code: e.target.value, employeeCode: this.props.userPreset }))
            .then(() => this.setState({ gridDef: this.createColumnDefs() }));
          this.props.dispatch(getTemplatesAction(e.target.value));
        }
      }
    }
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  }

  handleSelectPlanillaClick = (e, templates, gridRef) => {
    const tipoPlantilla = templates.find(o => o.Code === e.target.value);

    this.clearChart(gridRef);

    if (tipoPlantilla) {
      this.setState({ template: e.target.value });
      this.setState({ templateSeleccionado: tipoPlantilla });
      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.setColumnState(JSON.parse(tipoPlantilla.U_columnstate));
      gridRef.columnApi.setColumnGroupState(
        JSON.parse(tipoPlantilla.U_groupstate)
      );
      gridRef.columnApi.applyColumnState({
        state: JSON.parse(tipoPlantilla.U_columnstate),
        applyOrder: true,
      });
      gridRef.api.setFilterModel(JSON.parse(tipoPlantilla.U_filterstate));
      gridRef.columnApi.setPivotMode(JSON.parse(tipoPlantilla.U_pivotstate));
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();

      if (tipoPlantilla.U_graphicstate) {
        let chartModel = JSON.parse(tipoPlantilla.U_graphicstate);
        chartModel.cellRange.rowEndIndex = 0;
        chartModel.cellRange.rowEndIndex = gridRef.api.getDisplayedRowCount() || chartModel.cellRange.rowEndIndex;

        let optionsFromDB = chartModel.chartOptions;
        let createRangeChartParams = {
          cellRange: chartModel.cellRange,
          chartType: chartModel.chartType,
          chartPalette: chartModel.chartPalette,
          processChartOptions: function (params) {
            let options = optionsFromDB;
            setChartType(params.type);
            options.seriesDefaults.tooltip.renderer = tooltipOptions;

            return options;
          }
        };
        this.currentChartRef = gridRef.api.createRangeChart(createRangeChartParams);
      }
    } else {
      this.setState({ template: "" });
      this.setState({ templateSeleccionado: null });
      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.resetColumnGroupState();
      gridRef.columnApi.setPivotMode(false);
      gridRef.api.setFilterModel(null);
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();
      gridRef.columnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
    }
  };

  clearChart = (gridRef) => {
    if (this.currentChartRef) {
      this.currentChartRef.destroyChart();
      this.currentChartRef = null;
    }

    gridRef.api.chartService.activeCharts.forEach(item => item.destroyChart());

    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    gridRef.columnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null,
        rowGroup: null,
        pivot: null,
        pinned: null
      }
    });
  }

  handleSelectAfterCreate = (code, templates) => {
    const tipoPlantilla = (templates || []).find(o => o.Code === code);
    if (tipoPlantilla) {
      this.setState({ templateSeleccionado: tipoPlantilla });
      this.setState({ template: tipoPlantilla.Code });
    }
  };

  handleConfirmDelete = (gridRef) => {
    this.setState({ template: "" });
    this.setState({ templateSeleccionado: null });
    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null,
        rowGroup: null,
        pivot: null,
        pinned: null
      }
    });
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    this.clearChart(gridRef);
    gridRef.api.onFilterChanged();
    gridRef.api.onSortChanged();
  };

  handleClickDelete = () => {
    this.handleOpenTemplateDialog();
  };

  handeClickUpdate = () => {
    this.handleOpenUpdateDialog();
  };

  handleToggleViewContext = () => {
    this.setState((prevState) => ({ viewContext: !prevState.viewContext }));
  };

  handleExportIcariusSmart = (gridRef) => {
    const { context, title } = this.props;

    exportIcariusSmart(
      gridRef,
      title,
      (smartFileData) => {
        let csvData = smartFileData;
        csvData = context.result ? csvData + "\r\n" + JSON.stringify(context.result) : csvData;
        csvData = (context.result && context.comment) ? csvData + "\r\n" : csvData;
        csvData = context.comment ? csvData + "\r\n" + JSON.stringify(context.comment) : csvData;

        downloadBlob(csvData, customFileTypes.icariusSmart, title);
      }
    );
  };

  render() {
    const {
      isLoadingTemplates,
      templates,
      queryResult,
      context,
      color,
      dateFormat,
      locale,
      queries,
      title,
      favouriteList,
      handleChangeFavourite,
      userData,
      isFromHiddenGroup,
    } = this.props;

    const CustomSelect = withStyles({
      select: {
        width: 270,
      },
    })(Select);

    const goBackItem = () => !isFromHiddenGroup && (
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={this.props.handleCloseGrid}
        type={"goBack"}
      />
    )

    const contextItem = () => Boolean(context) && (
      <CustomIconButton
        title={this.state.viewContext ? 'Ocultar resultado del contexto' : 'Ver resultado del contexto'}
        onClick={this.handleToggleViewContext}
        type={this.state.viewContext ? "hide" : "preview"}
      />
    )

    const downloadIcariusSmartItem = (gridRef) => ['M', 'E'].includes(userData.level) && Boolean(context) && (
      <CustomIconButton
        title={'Descargar para Icarius Smart'}
        onClick={() => this.handleExportIcariusSmart(gridRef)}
        type={"icariusSmart"}
      />
    )

    const favItem = () => {
      if (isFromHiddenGroup || !this.state.queryCodeSelected) return null;
      const isFavourite = favouriteList?.includes(this.state.queryCodeSelected) || false;

      return (
        <FavouriteButton
          type={"CON"}
          code={this.state.queryCodeSelected}
          isFavourite={isFavourite}
          handleChangeFavourite={handleChangeFavourite}
        />
      )
    }

    const selectQueries = (gridRef) =>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10 }}>
        <div style={{ maxWidth: 450, display: "flex", marginRight: 10 }}>
          <div style={{ maxWidth: 300 }}>
            <FormControl>
              <CustomSelect
                value={this.state.queryCodeSelected}
                onChange={e => this.handleSelectQuery(e, queries, gridRef)}
                displayEmpty
              >
                <MenuItem value="" disabled>{getLocalizedString("selectQuery")}</MenuItem>
                {
                  queries.map((query, index) => (
                    <MenuItem key={index} value={query.code}>
                      {query.name}
                    </MenuItem>
                  ))
                }
              </CustomSelect>
            </FormControl>
          </div>
        </div>
        <Tooltip title={getLocalizedString("importQuery")}>
          <IconButton aria-label="import" onClick={() => this.handleImportTemplates(gridRef)}>
            <PublishIcon />
          </IconButton>
        </Tooltip>
        {
          this.state.queryCodeSelected && queryResult && queryResult.length > 0 &&
          <div style={{ maxWidth: 550, display: "flex", alignItems: "center" }}>
            <div style={{ maxWidth: 400 }}>
              <FormControl>
                <CustomSelect
                  id="demo-simple-select"
                  value={this.state.template}
                  onChange={e => this.handleSelectPlanillaClick(e, templates, gridRef)}
                  displayEmpty>
                  <MenuItem value="">{getLocalizedString("selectTemplate")}</MenuItem>
                  {
                    Object.keys(templates).length > 0 &&
                    templates.map((item, index) => (
                      <MenuItem key={index} value={item.Code}>
                        {`${item.U_template} - ${this.getRole(item.U_rol)}`}
                      </MenuItem>
                    ))
                  }
                </CustomSelect>
              </FormControl>
            </div>
            <Tooltip title={getLocalizedString("createTemplate")}>
              <IconButton aria-label="add" onClick={this.handleOpenCreateTemplateDialog}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            {
              this.state.template !== "" && userData && userData.code === this.state.templateSeleccionado.U_codemp &&
              <>
                <Tooltip title={getLocalizedString("deleteTemplate")}>
                  <IconButton aria-label="delete" onClick={this.handleClickDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={getLocalizedString("updateTemplate")}>
                  <IconButton aria-label="update" onClick={this.handeClickUpdate}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
            <DotsMenu
              icon={
                <Tooltip title={getLocalizedString("exportQuery")}>
                  <GetAppIcon />
                </Tooltip>
              }
            >
              <MenuItem onClick={() => this.handleExportTemplates(false)}>
                {getLocalizedString("exportQueryOnly")}
              </MenuItem>
              <MenuItem onClick={() => this.handleExportTemplates(true)}>
                {getLocalizedString("exportQueryAndTemplates")}
              </MenuItem>
            </DotsMenu>
          </div>
        }
      </div>

    const hijo = (gridRef) =>
      <>
        {
          this.state.setParamDialogIsOpen && this.state.currentParam &&
          <SetParamDialog
            open={this.state.setParamDialogIsOpen}
            params={this.state.currentParam}
            handleClose={this.handleCloseParamDialog}
            handleAgree={(params) => this.handleSelectQueryWithParams(params, gridRef)}
          />
        }
        <TemplateDialog
          open={this.state.templateDialogIsOpen && this.state.templateSeleccionado}
          handleConfirmDelete={() => this.handleConfirmDelete(gridRef)}
          handleCloseDialog={this.handleCloseTemplateDialog}
          template={this.state.templateSeleccionado}
        />
        <SaveTemplate
          open={this.state.createTemplateDialogIsOpen}
          switchStatus={this.state.showOnlyCurrentEmployees}
          level={userData.level}
          color={color}
          handleSelectAfterCreate={this.handleSelectAfterCreate}
          handleCloseDialog={this.handleCloseCreateTemplateDialog}
          templates={templates}
          grid={gridRef}
          query={this.state.queryCodeSelected}
        />
        <UpdateTemplate
          open={this.state.updateDialogIsOpen && this.state.templateSeleccionado}
          switchStatus={this.state.showOnlyCurrentEmployees}
          color={color}
          template={this.state.templateSeleccionado}
          handleCloseDialog={this.handleCloseUpdateDialog}
          grid={gridRef}
        />
        {
          this.state.viewContext &&
          <ContextView
            data={context}
          />
        }
      </>

    return (
      <CommonPage
        isLoading={isLoadingTemplates}
        menuItems={[goBackItem, favItem, contextItem, downloadIcariusSmartItem]}
        ownColumnDef={this.state.gridDef}
        customHeader={selectQueries}
        rowData={queryResult}
        title={title}
        gridTitle={title}
        locale={locale}
        dateFormat={dateFormat}
        hasHelp
        hasSelectAll
        hasExpand
        frameworkComponents={{ RatingRenderer, IndicatorRenderer, RichTextRenderer, NineBoxImageRenderer, ProgressRenderer, ProgressFilter }}
        codeFieldName={this.state.gridDef[0]?.field}
        pathToUseForProcess={`queries&codver=${this.state.queryCodeSelected}`}
      >
        {hijo}
      </CommonPage>
    );
  }
}

export default connect(
  createStructuredSelector({
    filters: getFilters,
    queryResult: getQueryResult,
    context: getContext,
    dateFormat: getDateFormat,
    templates: getTemplatesRows,
    isLoadingTemplates: getIsLoadingTemplates,
    locale: getLocale,
    userData: getUserData,
    color: getAppColor,
  })
)(GridScreen);
