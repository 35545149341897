import { useMemo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getUserRole } from "src/app/selectors";
import {
  getQueryGroupsAction,
  getQueriesAction,
} from "../actions";
import {
  getIsLoading,
  getQueryGroups,
  getQueries,
  getGroupsToFilter,
} from "../selectors";

const useQueries = () => {

  const [gridIsOpen, setGridIsOpen] = useState(false);
  const [group, setGroup] = useState('');
  const [groupName, setGroupName] = useState('');
  const [queryPreset, setQueryPreset] = useState('');
  const [userPreset, setUserPreset] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isLoading = useSelector(getIsLoading);
  const queryGroups = useSelector(getQueryGroups);
  const groupsToFilter = useSelector(getGroupsToFilter);
  const queries = useSelector(getQueries);
  const userRole = useSelector(getUserRole);

  useEffect(() => {
    dispatch(getQueryGroupsAction());
  }, [dispatch])

  const handleOpenGrid = useCallback((newGroup, newGroupName) => {
    setGridIsOpen(true);
    setGroup(newGroup);
    setGroupName(newGroupName || newGroup);
    dispatch(getQueriesAction(newGroup));
  }, [dispatch])

  const handleCloseGrid = () => {
    setGridIsOpen(false);
    setGroup("");
  }

  useEffect(() => {
    if (location.search) {
      const groupFromParams = new URLSearchParams(location.search).get("group");
      const groupNameFromParams = new URLSearchParams(location.search).get("groupName"); // para los de userMenu, donde el groupName es distinto al group
      const queryFromParams = new URLSearchParams(location.search).get("queryCode");
      const userFromParams = new URLSearchParams(location.search).get("employeeCode");

      setUserPreset(userFromParams);
      setQueryPreset(queryFromParams);
      handleOpenGrid(groupFromParams, groupNameFromParams);

      history.replace({
        pathname: userRole !== "E" ? paths.myQueries : paths.queries,
        search: '',
      });
    }
  }, [location.search, history, handleOpenGrid, userRole])

  const filteredGroups = useMemo(() => {
    return queryGroups.filter((group) => !groupsToFilter.includes(group.toLowerCase()))
  }, [queryGroups, groupsToFilter])

  const isFromHiddenGroup = groupsToFilter.includes(group.toLowerCase());

  const state = {
    gridIsOpen,
    group,
    groupName,
    queryPreset,
    userPreset,
  }

  const handlers = {
    handleOpenGrid,
    handleCloseGrid,
  }

  return {
    groups: filteredGroups,
    state,
    handlers,
    isLoading,
    isFromHiddenGroup,
    queries,
  }
}

export default useQueries;