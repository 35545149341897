import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getLocalizedString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FabButton from "@icarius-common/fabButton";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import { deleteImageAction, getGalleryImagesAction, uploadImageAction } from "@icarius-common/galleryDialog/actions";
import { ImagePlaceholder } from "@icarius-icons/index";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { GalleryDialog } from "@icarius-common/galleryDialog";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";
import { yesNoArray } from "@icarius-utils/constants";
import useHandleForm from "./useHandleForm";
import PollQuestion from "./pollQuestion";
import { createPollSettingsAction, modifyPollSettingsAction } from "../../actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    pollNames,
    modes,
    processList,
    roles,
    pollTypes,
    closePoll,
  } = props;

  const images = useSelector(getGalleryImages);
  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);

  const color = useSelector(getAppColor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const createPoll = (dataToSend) => {
    dispatch(createPollSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && closePoll());
  }

  const modifyPoll = (dataToSend) => {
    dispatch(modifyPollSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && closePoll());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("pollFieldsRequiredAndOptions"), severity: "error", duration: 10000 }));
  }

  const openNPSValidationError = () => {
    dispatch(openSnackbarAction({
      msg: 'Para un tipo de encuesta NPS solo es posible crear 1 pregunta con opciones (La cual debe contener las opciones de 1 a 10)',
      severity: "error",
      duration: null,
    }));
  }

  const openNameValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe una encuesta con el nombre ingresado', severity: "error" }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    createQuestion,
    deleteQuestion,
    duplicateQuestion,
    modifyQuestion,
    submit,
  } = useHandleForm(data, createPoll, modifyPoll, openValidationError, openNameValidationError, openNPSValidationError, false, pollNames);

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", 'image');
    setImageIndex(index);
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              disabled={data?.inUse || !isCreate}
              required
              fullWidth
              margin={"none"}
              label={getLocalizedString("pollName")}
              value={formData.pollName}
              onChange={(e) => setFormValue(e.target.value, "pollName")}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={3} md={2} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                disabled={data?.inUse || formData.useForOnboarding === "Y"}
                required
                fullWidth
                label={getLocalizedString("pollFrom")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                value={formData.startDate}
                minDate={isCreate ? new Date() : null}
                onChange={(date) => setFormValue(date, "startDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={3} md={2} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                disabled={formData.useForOnboarding === "Y"}
                required
                fullWidth
                label={getLocalizedString("pollTo")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                minDate={data?.inUse ? createDateFromDDMMYYYY(data.endDate) : formData.startDate} //si esta en uso, que solo se pueda extender
                format="dd/MM/yyyy"
                margin="none"
                value={formData.endDate}
                onChange={(date) => setFormValue(date, "endDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-type-${data ? data.code : "new"}`}>{getLocalizedString("type")}</InputLabel>
              <Select
                disabled={data?.inUse}
                value={formData.type}
                labelId={`label-type-${data ? data.code : "new"}`}
                id={`select-type-${data ? data.code : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "type")}
                margin={"none"}
              >
                {
                  pollTypes?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-role-${data ? data.code : "new"}`}>{getLocalizedString("pollRole")}</InputLabel>
              <Select
                disabled={data?.inUse || formData.useForOnboarding === "Y"}
                value={formData.visualizationRole}
                labelId="document-role-label"
                id="document-role"
                onChange={(e) => setFormValue(e.target.value, "visualizationRole")}
                margin={"none"}
              >
                {
                  roles?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-highlighted`}>{'Ver respuestas al responder'}</InputLabel>
              <Select
                disabled={data?.inUse}
                value={formData.highlighted}
                labelId="label-highlighted"
                id="highlighted"
                onChange={(e) => setFormValue(e.target.value, 'highlighted')}
                margin={"none"}
              >
                {
                  yesNoArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-isAnonymous`}>{'Anónima'}</InputLabel>
              <Select
                disabled={data?.inUse}
                value={formData.isAnonymous}
                labelId="label-isAnonymous"
                id="isAnonymous"
                onChange={(e) => setFormValue(e.target.value, 'isAnonymous')}
                margin={"none"}
              >
                {
                  yesNoArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-mode`}>{'Modalidad'}</InputLabel>
              <Select
                disabled={data?.inUse}
                value={formData.mode}
                labelId="label-mode"
                id="mode"
                onChange={(e) => setFormValue(e.target.value, 'mode')}
                margin={"none"}
              >
                {
                  modes?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
            <TextField
              disabled={data?.inUse}
              required
              fullWidth
              margin={"none"}
              label={getLocalizedString("pollDescription")}
              value={formData.pollDescription}
              onChange={(e) => setFormValue(e.target.value, "pollDescription")}
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} md={2} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-useForOnboarding`}>{'Solo para onboarding'}</InputLabel>
              <Select
                disabled={data?.inUse}
                value={formData.useForOnboarding}
                labelId="label-useForOnboarding"
                id="useForOnboarding"
                onChange={(e) => setFormValue(e.target.value, 'useForOnboarding')}
                margin={"none"}
              >
                {
                  yesNoArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink id={`label-process-${data ? data.code : "new"}`}>{'Proceso especial asociado'}</InputLabel>
              <Select
                value={formData.process}
                labelId={`label-process-${data ? data.code : "new"}`}
                id={`select-process-${data ? data.code : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "process")}
                margin={"none"}
                displayEmpty
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Ninguno'}
                </MenuItem>
                {
                  processList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            ["C", "N"].includes(formData.type) &&
            <>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <TextField
                  required
                  fullWidth
                  label={getLocalizedString("pollFamily")}
                  margin={"none"}
                  value={formData.pollFamily}
                  onChange={(e) => setFormValue(e.target.value, "pollFamily")}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <TextField
                  required
                  fullWidth
                  label={getLocalizedString("pollReference")}
                  margin={"none"}
                  value={formData.pollReference}
                  onChange={(e) => setFormValue(e.target.value, "pollReference")}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            </>
          }
        </Grid>
        {
          formData.isAnonymous === 'N' &&
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              fullWidth
              margin={"none"}
              label={'Puntos para medallero'}
              type="number"
              value={formData.points}
              onChange={(e) => setFormValue(e.target.value, 'points')}
              inputProps={{ min: "0", step: "1" }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = Number.parseFloat(e.target.value).toFixed(0);
                  setFormValue(e.target.value, 'points');
                }
              }}
            />
          </Grid>
        }
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
          <FileInput
            acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.pdf']}
            handleChange={(files) => setFormValue(files, 'files')}
            id={`poll${data?.code || 'create'}`}
          />
          <FileList
            files={formData.files}
            handleClick={() => setFormValue(null, 'files')}
          />
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          {
            !data?.inUse &&
            <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={() => setImagesDialogIsOpen(true)}>
              <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
                {getLocalizedString("groupImagesTitle")}
              </Typography>
            </div>
          }
        </Grid>
        <Grid container item xs={12}>
          {
            formData.questions?.map((question, index) =>
              <PollQuestion
                key={index}
                inUse={data?.inUse}
                index={index}
                data={question}
                hideDuplicate={formData.type === 'N'}
                deleteQuestion={deleteQuestion}
                duplicateQuestion={duplicateQuestion}
                modifyQuestion={modifyQuestion}
              />
            )
          }
        </Grid>
        <Grid container item xs={12} justify={data?.inUse ? "flex-end" : "space-between"} alignItems="center" style={{ paddingBottom: 20 }}>
          {
            !data?.inUse &&
            <FabButton onClick={createQuestion} title={getLocalizedString("newPollQuestion")} />
          }
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </>
  );
}

export default Form;
