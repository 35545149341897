import NAME from "./constants";

export const GET_POLLS = NAME + "/GET_POLLS";
export const GET_POLLS_FULFILLED = NAME + "/GET_POLLS_FULFILLED";
export const GET_POLLS_REJECTED = NAME + "/GET_POLLS_REJECTED";

export const GET_QUESTIONS = NAME + "/GET_QUESTIONS";
export const GET_QUESTIONS_FULFILLED = NAME + "/GET_QUESTIONS_FULFILLED";
export const GET_QUESTIONS_REJECTED = NAME + "/GET_QUESTIONS_REJECTED";

export const GET_ANSWERS = NAME + "/GET_ANSWERS";
export const GET_ANSWERS_FULFILLED = NAME + "/GET_ANSWERS_FULFILLED";
export const GET_ANSWERS_REJECTED = NAME + "/GET_ANSWERS_REJECTED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";