const NAME = "POLL_REWIEW_PAGE";

export default NAME;

export const WORDS_TO_REMOVE = [
    "a",
    "ante",
    "bajo",
    "cabe",
    "con",
    "contra",
    "de",
    "desde",
    "durante",
    "en",
    "entre",
    "hacia",
    "hasta",
    "he",
    "mediante",
    "para",
    "por",
    "según",
    "sin",
    "so",
    "sobre",
    "tras",
    "versus",
    "vía",
    "el",
    "la",
    "lo",
    "los",
    "las",
    "un",
    "uno",
    "una",
    "unas",
    "me",
    "mi",
    "nos",
    "te",
    "ti",
    "vos",
    "os",
    "que",
    "lo ",
];