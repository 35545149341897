import React, { useEffect, useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Card, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReactApexChart from 'react-apexcharts';
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const QuestionGraph = (props) => {

  const {
    colors,
    data,
    theme,
    onClick,
  } = props;

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    setChartData(() => {
      let i = 0;
      const labels = [];
      const numbers1 = [];
      const numbers2 = [];

      for (i = 1; i <= data[0].optionsAmount; i++) {
        labels.push(data[0]["option" + i + "_text"]);
        numbers1.push(data[0]["option" + i + "_amount"]);
        if (data.length > 1) {
          numbers2.push(data[1]["option" + i + "_amount"]);
        }
      }

      const seriesData = [
        {
          name: data[0].pollName,
          data: numbers1.map(item => item !== 0 ? parseFloat(item).toFixed(2) : 0)
        },
      ];

      if (data.length > 1) {
        seriesData[1] = {
          name: data[1].pollName,
          data: numbers2.map(item => item !== 0 ? parseFloat(item).toFixed(2) : 0)
        }
      }

      return {
        id: 'GVGraph',
        series: seriesData,
        options: {
          chart: {
            type: 'bar',
            height: 242,
            toolbar: {
              show: false
            }
          },
          grid: { show: true },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: '45%',
            }
          },
          xaxis: {
            categories: labels,
            labels: {
              rotateAlways: true,
              rotate: -45,
              style: {
                fontSize: '12px',
                colors: 'var(--mainText)',
              },
              minHeight: 70,
            }
          },
          yaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: '12px',
                colors: 'var(--mainText)',
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: data?.length > 1,
            position: 'top',
            horizontalAlign: 'left',
            labels: {
              colors: 'var(--mainText)',
            },
            onItemClick: {
              toggleDataSeries: false,
            },
            onItemHover: {
              highlightDataSeries: false,
            },
          },
          states: {
            active: {
              filter: {
                type: 'none',
              }
            }
          },
          tooltip: {
            marker: {
              show: false,
            },
            followCursor: true,
            theme: 'dark',
            y: {
              formatter: function (val) {
                return formatNumberOrReturnUndefined(val, 0, 2)
              },
              title: {
                formatter: function () {
                  return ''
                }
              }
            },
          }
        }
      };
    })
  }, [data, colors])

  const ModifiedCard = withStyles({
    root: {
      marginTop: 10,
      height: 430,
      marginBottom: 10,
      boxShadow: "0px 0px 0px 0px",
      borderColor: theme === "dark" ? "#303030" : "#cecece",
      borderWidth: theme === "dark" ? "0px" : "1px",
      backgroundColor: 'var(--secondaryBackgroundColor)',
      borderStyle: "solid",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })(Card);

  return (
    <ModifiedCard style={{ cursor: "pointer" }} onClick={onClick}>
      <CardContent style={{ width: '90%' }}>
        <Typography variant="body2" style={{ textAlign: "center", wordWrap: "break-word" }} className="whiteText" gutterBottom>
          {data[0].description}
        </Typography>
        {
          ["O", "L"].includes(data[0].type) ?
            <>
              {
                Boolean(chartData) &&
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type={chartData.options.chart.type}
                  height={chartData.options.chart.height}
                />
              }
            </>
            :
            <Typography
              variant="body1"
              style={{
                textAlign: "center",
                wordWrap: "break-word",
                wordBreak: "break-word",
                marginTop: "20px",
                fontSize: 30,
                fontWeight: 600,
              }}
            >
              {getLocalizedString("clickHereForDetailedAnswers")}
            </Typography>
        }
      </CardContent>
    </ModifiedCard>
  );
}

export default QuestionGraph;