import { useState } from "react";
import { useDispatch } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { executeTableFormAction } from "../actions";

const useProcessExecution = (process) => {

  const dispatch = useDispatch();

  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);

  const handleValidateAndAskProcess = (code) => {
    setSelectedCode(code);
    dispatch(openDialogAction({
      title: "Atención",
      msg: `¿Está seguro que desea ejecutar el proceso: ${process.value}?`,
      onConfirm: () => handleStartExecutionProcess(code),
      onCancel: () => handleCloseParamDialog(),
    }));
  }

  const handleStartExecutionProcess = (code) => {
    if (process?.parameters?.length) {
      //abrir modal de setparam
      setParamDialogIsOpen(true);
      setParamsToUse(process.parameters);
      return;
    }

    // si no tiene parametros
    executeProcess(null, code);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
    setSelectedCode(null);
  }

  const executeProcess = (param, code) => {
    dispatch(executeTableFormAction(
      {
        poll: selectedCode || code,
        process: process.key,
        parameters: param || [],
      }
    ))
      .then(() => {
        handleCloseParamDialog();
      })
  }

  const processExecutionData = {
    paramsToUse,
    paramDialogIsOpen,
  }

  const processExecutionFunctions = {
    handleValidateAndAskProcess,
    executeProcess,
    handleCloseParamDialog,
  }

  return {
    processExecutionData,
    processExecutionFunctions,
  }
}

export default useProcessExecution;
