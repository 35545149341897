import React from "react";
import { Tooltip, Button } from "@material-ui/core";
import { DownloadIcon } from "@icarius-icons";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { downloadPollReviewAction } from "../actions";
import { getTheme } from "@icarius-pages/login/selectors";
import { withStyles } from "@material-ui/styles";

const DownloadPDFButton = (code) => {

    const color = useSelector(getAppColor);
    const { theme } = useSelector(getTheme);
    const dispatch = useDispatch();

    const handleOnClick = () => {
        dispatch(downloadPollReviewAction(code.code))
    }

    const ModifiedButton = withStyles({
        contained: {
            backgroundColor: theme === "dark" ? "#222" : "white",
        },
    })(Button);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 50,
                right: 50,
                zIndex: 2,
            }}
        >
            <Tooltip title={'Descargar resumen de la encuesta'}>
                <ModifiedButton
                    onClick={handleOnClick}
                    className="whiteText"
                    variant="contained"
                    style={{ cursor: "pointer" }}
                >
                    <DownloadIcon htmlColor={color} />
                </ModifiedButton>
            </Tooltip>
        </div>
    )
}

export default DownloadPDFButton;
