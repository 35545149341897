import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  Button
} from "@material-ui/core";
import { AttachFileIcon, DeleteIcon, ImagePlaceholder, PersonIcon } from "@icarius-icons";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getLocalizedString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPublicationSettingsAction, modifyPublicationSettingsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandlePublicationForm from "./useHandlePublicationForm";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import {
  TYPES_USES_LINK_AND_DOWNLOAD,
  TYPES_USES_NUMBER,
  TYPES_USES_ONLY_DOWNLOAD,
  TYPES_USES_ONLY_LINK,
  TYPES_NEEDS_IMAGE
} from "../../constants";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT, IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { getGalleryImagesAction, uploadImageAction, deleteImageAction } from "@icarius-common/galleryDialog/actions";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import GalleryDialog from "@icarius-common/galleryDialog/components/index";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getSocietyPeople } from "../../selectors";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";

const gridStyle = { height: 75, padding: "0px 10px" };

const PublicationForm = (props) => {

  const { color, data, roles, publicationTypes, handleClose } = props;
  const dispatch = useDispatch();
  const images = useSelector(getGalleryImages);
  const societyPeople = useSelector(getSocietyPeople);

  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);
  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);

  const createPublication = (dataToSend) => {
    dispatch(createPublicationSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modifyPublication = (dataToSend) => {
    dispatch(modifyPublicationSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandlePublicationForm(data, createPublication, modifyPublication, openValidationError);

  const getTypeGalleryString = (type) => {
    switch (type) {
      case "C": return "counters";
      case "U": return "favourites";
      case "L": return "downloads";
      case "B": return "modals";
      case "M": return "news";
      default: return "home";
    }
  }

  useEffect(() => {
    dispatch(getGalleryImagesAction(getTypeGalleryString(formData.type))); //variar segun el tipo?
  }, [dispatch, formData.type])

  const handleOpenGallery = () => {
    if (formData.type) {
      setImagesDialogIsOpen(true);
    } else {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar Tipo primero", severity: "warning", duration: 10000 }));
    }
  }

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, getTypeGalleryString(formData.type)));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, getTypeGalleryString(formData.type)));
  }

  const usesLink = () => {
    return [...TYPES_USES_ONLY_LINK, ...TYPES_USES_LINK_AND_DOWNLOAD].includes(formData.type);
  }

  const usesDownload = () => {
    return [...TYPES_USES_ONLY_DOWNLOAD, ...TYPES_USES_LINK_AND_DOWNLOAD].includes(formData.type);
  }

  const linkIsDisabled = () => {
    return TYPES_USES_LINK_AND_DOWNLOAD.includes(formData.type) && Boolean(formData.download);
  }

  const downloadIsDisabled = () => {
    return TYPES_USES_LINK_AND_DOWNLOAD.includes(formData.type) && Boolean(formData.link);
  }

  const handleOpenFile = () => {
    if (!isCreate) {
      previewFromURL(RESOURCES_ENDPOINT + formData.fileName);
    }
  }

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", getLocalizedString("publicationImage"));
    setImageIndex(index);
  }

  const isNewsType = (formData.type === 'B' || formData.type === 'M');
  const employeeSelected = useMemo(() => {
    return societyPeople?.find(item => item.key === formData.employeeCode);
  }, [formData, societyPeople]);
  const hasEmployee = isNewsType && Boolean(employeeSelected);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("publicationName")}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, getLocalizedString("publicationName"))}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={3} md={2} style={gridStyle}> {/* desde */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required
              fullWidth
              label={getLocalizedString("publicationFrom")}
              variant="inline"
              format="dd/MM/yyyy"
              margin="none"
              value={formData.startDate}
              minDate={isCreate ? new Date() : null}
              onChange={(date) => setFormValue(date, getLocalizedString("publicationFrom"))}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={3} md={2} style={gridStyle}> {/* hasta */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required
              fullWidth
              label={getLocalizedString("publicationTo")}
              minDate={data?.inUse ? createDateFromDDMMYYYY(data.endDate) : formData.startDate} //si esta en uso, que solo se pueda extender
              minDateMessage={null}
              variant="inline"
              format="dd/MM/yyyy"
              margin="none"
              value={formData.endDate}
              onChange={(date) => setFormValue(date, getLocalizedString("publicationTo"))}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* tipo */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type-${data ? data.code : "new"}`}>{getLocalizedString("publicationType")}</InputLabel>
            <Select
              disabled={!isCreate}
              value={formData.type}
              labelId={`label-type-${data ? data.code : "new"}`}
              id={`select-type-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, getLocalizedString("publicationType"))}
              margin={"none"}
            >
              {
                publicationTypes && publicationTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* rol */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-role-${data ? data.code : "new"}`}>{getLocalizedString("publicationRole")}</InputLabel>
            <Select
              value={formData.role}
              labelId="document-role-label"
              id="document-role"
              onChange={(e) => setFormValue(e.target.value, getLocalizedString("publicationRole"))}
              margin={"none"}
            >
              {
                roles && roles.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* texto */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={getLocalizedString("publicationText")}
            value={formData.text}
            onChange={(e) => setFormValue(e.target.value, getLocalizedString("publicationText"))}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid // confetti e imagen
          container item alignItems='center' xs={12} md={isNewsType ? 6 : 12}
          style={{ height: 260, padding: "0px 10px", paddingBottom: 20 }}
        >
          {
            isNewsType &&
            <Grid container item alignItems='center' xs={12}>
              <div
                style={{ display: "flex", alignItems: "center", cursor: formData.type === 'M' ? 'default' : "pointer", paddingLeft: 10 }}
                onClick={() => setFormValue(!Boolean(formData.hasConfetti), "hasConfetti")}
              >
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                  {'Utiliza confetti'}
                </Typography>
                <Checkbox style={{ cursor: formData.type === 'M' ? 'default' : "pointer" }} checked={formData.hasConfetti} />
              </div>
            </Grid>
          }
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={handleOpenGallery}>
            <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
              {getLocalizedString("groupImagesTitle")}
            </Typography>
            {
              (TYPES_NEEDS_IMAGE.includes(formData.type) || (formData.link && formData.needsImage && !Boolean(formData.image))) &&
              <Typography style={{ fontWeight: 400, fontSize: 16, marginLeft: 5 }} className="errorColor" variant="caption">
                {"*"}
              </Typography>
            }
          </div>
        </Grid>
        {
          isNewsType &&
          <Grid /* imagen de empleado */
            container item alignItems='center' xs={12} md={6}
            style={{ height: 260, padding: "0px 10px", paddingBottom: 20 }}
          >
            <Grid
              container item xs={12} justify='center' alignItems='center'
              style={{ height: 52 }}
            >
              <Button
                variant='contained'
                className='whiteText'
                onClick={() => setUserSelectionDialogIsOpen(true)}
                startIcon={<PersonIcon htmlColor={color} />}
              >
                Asociar noticia a colaborador
              </Button>
              <div style={{ marginLeft: 20, visibility: formData.employeeCode ? 'visible' : 'hidden' }}>
                <CustomIconButton
                  title={'Borrar colaborador'}
                  type={"delete"}
                  onClick={() => setFormValue('', 'employeeCode')}
                />
              </div>
            </Grid>
            <Grid container item style={{ height: 180 }}>
              {
                hasEmployee &&
                <>
                  <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                      employeeSelected?.FOTOGRAFIA ?
                        <img
                          style={{ maxWidth: 220, maxHeight: 140, borderRadius: '50%' }}
                          src={IMAGES_ENDPOINT + employeeSelected?.FOTOGRAFIA}
                          alt={"imageNotFound"}
                        />
                        :
                        <div
                          className={"employee-image"}
                          style={{
                            borderRadius: '50%',
                            height: 140,
                            width: 140,
                            margin: 0,
                            background: color,
                          }}
                        />
                    }
                  </Grid>
                  <div style={{ marginTop: 10, width: "100%" }}>
                    <Typography variant="h6" className="whiteText" style={{ fontWeight: 400, fontSize: 16, textAlign: 'center' }}>
                      {`Se incluirá la fotografía de ${employeeSelected?.name}`}
                    </Typography>
                  </div>
                </>
              }
            </Grid>
          </Grid>
        }
        {
          formData.type &&
          <div style={{ width: "100%", margin: "0px 10px 10px 10px" }}> {/* DIVISOR */}
            <DialogTitleDivider />
          </div>
        }
        {
          TYPES_USES_NUMBER.includes(formData.type) &&
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Numeros */}
            <TextField
              required
              type="number"
              fullWidth
              label={getLocalizedString("publicationNumber")}
              margin={"none"}
              value={formData.number}
              onChange={(e) => setFormValue(e.target.value.substring(0, 8), getLocalizedString("publicationNumber"))}
              inputProps={{ max: 99999999, maxLength: 8 }}
              onBlur={(e) => e.target.value = e.target.value.substring(0, 8)}
            />
          </Grid>
        }
        {
          usesLink() && !linkIsDisabled() &&
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Link */}
            <TextField
              required={TYPES_USES_ONLY_LINK.includes(formData.type)}
              disabled={linkIsDisabled()}
              fullWidth
              label={getLocalizedString("publicationLink")}
              margin={"none"}
              value={formData.link}
              onChange={(e) => setFormValue(e.target.value, getLocalizedString("publicationLink"))}
              inputProps={{ maxLength: 200 }}
            />
            {
              formData.link && formData.needsImage && !Boolean(formData.image) &&
              <Typography variant="caption" className="errorColor">
                {getLocalizedString("validationURLPreview")}
              </Typography>
            }
          </Grid>
        }
        {
          usesDownload() && !downloadIsDisabled() &&
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Descarga */}
            <input
              disabled={downloadIsDisabled()}
              type="file"
              id="fileInputUploadPublication"
              style={{ display: "none" }}
              onChange={(e) => setFormValue(e, getLocalizedString("publicationDownload"))}
              onClick={e => (e.target.value = null)} //resetearlo para que acepte el mismo archivo
            />
            {
              formData.download ?
                <>
                  <Tooltip title={getLocalizedString("delete")} placement="bottom">
                    <IconButton
                      style={{ maxHeight: 20, maxWidth: 20, margin: 10 }}
                      onClick={() => setFormValue("", getLocalizedString("publicationDownload"))}
                      component={"span"}
                      className="w-32 h-32 mx-4 p-0">
                      <DeleteIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    style={{ fontSize: "0.9rem", cursor: !isCreate ? "pointer" : "" }}
                    className="whiteText"
                    variant="caption"
                    onClick={handleOpenFile}
                  >
                    {formData.fileName.split('/').pop()}
                  </Typography>
                </>
                :
                <Tooltip title={getLocalizedString("mailUploadFile")} placement="bottom">
                  <label htmlFor="fileInputUploadPublication">
                    <IconButton
                      style={{ maxHeight: 20, maxWidth: 20, margin: 10 }}
                      component={"span"}
                      className="w-32 h-32 mx-4 p-0">
                      <AttachFileIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                    </IconButton>
                    <Typography style={{ fontWeight: 400, fontSize: 16, textDecoration: "underline" }} className="whiteText" variant="caption">
                      {getLocalizedString("selectAnnex")}
                    </Typography>
                  </label>
                </Tooltip>
            }
            {
              TYPES_USES_ONLY_DOWNLOAD.includes(formData.type) &&
              <Typography style={{ fontWeight: 400, fontSize: 16, marginLeft: 5 }} className="errorColor" variant="caption">
                {"*"}
              </Typography>
            }
            <Grid item xs={12} style={{ paddingLeft: 5 }}>
              <Typography variant="caption" className="whiteText">
                {getLocalizedString("publicationFileTypeExplanation")}
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={[formData.employeeCode]} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          users={societyPeople}
          title={'Seleccionar colaborador'}
          selectedUsersTitle={'Seleccionado'}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          handleSubmit={(data) => setFormValue(data[0], "employeeCode")}
          singleSelection
        />
      }
    </Grid>
  );
}

export default PublicationForm;
