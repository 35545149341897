import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { formatDate, dateIsAfterToday } from "@icarius-utils/date";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "0px 10px" };

const ABMDialog = (props) => {

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    open,
    loading,
    data,
    isDuplicate,
    processCodes,
    handleSubmit,
    handleClose
  } = props;

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, isDuplicate, handleSubmit, openValidationError);

  const timeArray = useMemo(() => {
    // si no, el dateIsAfterToday compara tambien la hora
    const dateNoTime = new Date(formData.date.getFullYear(), formData.date.getMonth(), formData.date.getDate());
    const startHour = dateIsAfterToday(dateNoTime) ? 0 : (new Date().getHours() + 1);
    const dateToShow = formatDate(formData.date);
    const auxArr = [];

    for (let i = startHour; i < 24; i++) {
      auxArr.push({
        key: i,
        value: `${dateToShow} ${i}:00`,
      })
    }

    return auxArr;
  }, [formData.date])

  const getTitle = () => {
    if (isCreate) return 'Crear programación';
    if (isDuplicate) return 'Duplicar programación';
    return 'Editar programación';
  }

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getTitle()}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Código del proceso */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-code`}>{"Código del proceso"}</InputLabel>
                <Select
                  disabled={Boolean(!isCreate) && !isDuplicate}
                  value={formData.code}
                  labelId={`label-code`}
                  id={`select-code`}
                  onChange={(e) => setFormValue(e.target.value, "code")}
                  margin={"none"}
                >
                  {
                    processCodes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  required
                  minDate={new Date()}
                  style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                  label={'Fecha de ejecución'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={formData['date']}
                  onChange={(e) => setFormValue(e, 'date')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* Hora de ejecución */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-time`}>{"Hora de ejecución"}</InputLabel>
                <Select
                  value={formData.time}
                  labelId={`label-time`}
                  id={`select-time`}
                  onChange={(e) => setFormValue(e.target.value, "time")}
                  margin={"none"}
                >
                  {
                    timeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <div
              style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingLeft: 10 }}
              onClick={() => setFormValue(!Boolean(formData.warning), "warning")}
            >
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'Recibir aviso'}
              </Typography>
              <Checkbox checked={formData.warning} />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ABMDialog;
