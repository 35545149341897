import React, { useCallback, useState, useRef, useEffect } from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import CustomIconButton from '@icarius-common/abmComponents/customIconButton';
import Loader from "@icarius-common/loader";
import { MoreIcon, HighlightPositionsAvailableIcon, HighlightCompetgenceGroupIcon, HighlightEvaluationGroupIcon, TreeOrgChartIcon, AccountTreeIcon, TrendingUpIcon } from "@icarius-icons";

const OwnOrgChart = React.memo((props) => {
    const {
        color,
        setStatsDialogIsOpen,
        setNodeAwaitingDelete,
        setCurrentNode,
        setEditMode,
        setDialogIsOpen,
        setConfirmDialogIsOpen,
        nodes,
        setChartRef,
        handleGoBack,
        hasMultipleBranches,
        setPositionOccupation,
        theme,
        setFilterPositionDialogIsOpen,
        setPotentialDialogIsOpen,
        selectedElements,
        potential,
        setCompetenceGroupDialogIsOpen,
        setEvaluationGroupDialogIsOpen,
        selectedCompetenceGroupElements,
        selectedEvaluationGroupElements,
    } = props;

    const chartRef = useRef();
    const divRef = useRef();
    const nodeIdRef = useRef();
    const [nodePositionOccupation, setNodePositionOccupation] = useState(null);
    const [vacanciesHighlightingInProgress, setVacanciesHighlightingInProgress] = useState(false);

    const editIcon = '<svg width="24" height="24" viewBox="0 0 528.899 528.899"><path fill="#7A7A7A" d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981 c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611 C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069 L27.473,390.597L0.3,512.69z"></path></svg>';
    const detailsIcon = '<svg width="24" height="24" viewBox="0 0 512 512"><path fill="#7A7A7A" d="M447.933,103.629c-0.034-3.076-1.224-6.09-3.485-8.352L352.683,3.511c-0.004-0.004-0.007-0.005-0.011-0.008 C350.505,1.338,347.511,0,344.206,0H89.278C75.361,0,64.04,11.32,64.04,25.237v461.525c0,13.916,11.32,25.237,25.237,25.237 h333.444c13.916,0,25.237-11.32,25.237-25.237V103.753C447.96,103.709,447.937,103.672,447.933,103.629z M356.194,40.931 l50.834,50.834h-49.572c-0.695,0-1.262-0.567-1.262-1.262V40.931z M423.983,486.763c0,0.695-0.566,1.261-1.261,1.261H89.278 c-0.695,0-1.261-0.566-1.261-1.261V25.237c0-0.695,0.566-1.261,1.261-1.261h242.94v66.527c0,13.916,11.322,25.239,25.239,25.239 h66.527V486.763z"></path><path fill="#7A7A7A" d="M362.088,164.014H149.912c-6.62,0-11.988,5.367-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,169.381,368.707,164.014,362.088,164.014z"></path><path fill="#7A7A7A" d="M362.088,236.353H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,241.721,368.707,236.353,362.088,236.353z"></path><path fill="#7A7A7A" d="M362.088,308.691H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.367,11.988-11.988C374.076,314.06,368.707,308.691,362.088,308.691z"></path><path fill="#7A7A7A" d="M256,381.031H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988H256 c6.62,0,11.988-5.367,11.988-11.988C267.988,386.398,262.62,381.031,256,381.031z"></path></svg>'
    const personIcon = '<svg fill="#7A7A7A" width="24" height="24" class="MuiSvgIcon-root MuiSvgIcon-root css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="PersonIcon" tabindex="-1" title="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>'

    const [chart, setChart] = useState(null);
    const [hightlightNodesWithVacancies, setHightlightNodesWithVacancies] = useState(false);
    const [orientation, setOrientation] = useState(0);

    const openStatsDialog = () => {
        setStatsDialogIsOpen(true)
    }

    const handleHighlightClick = () => {
        setVacanciesHighlightingInProgress(true);
        setHightlightNodesWithVacancies(!hightlightNodesWithVacancies);
    }

    useEffect(() => {
        chartRef.current = chart;
        setChartRef(chart)
    }, [chart, setChartRef])

    useEffect(() => {
        if (nodePositionOccupation)
            setPositionOccupation(nodePositionOccupation);
    }, [nodePositionOccupation, setPositionOccupation])

    const showEditForm = useCallback(() => {
        setEditMode(true)
        setDialogIsOpen(true)
    }, [setEditMode, setDialogIsOpen])


    const showDetailsForm = useCallback(() => {
        setEditMode(false)
        setDialogIsOpen(true)
    }, [setEditMode, setDialogIsOpen])

    const showRemoveConfirmDialog = useCallback(((nodeId) => {
        setConfirmDialogIsOpen(true)
        setNodeAwaitingDelete(nodeId)
    }), [setConfirmDialogIsOpen, setNodeAwaitingDelete])


    const editForm = function () {
        nodeIdRef.current = null;
    };

    const editHandler = useCallback((idNode) => {
        showEditForm();
        nodeIdRef.current = idNode;
        if (chartRef.current) {

            const node = chartRef.current.get(nodeIdRef.current);
            if (node) {
                setCurrentNode(node)
            }
        }
    }, [showEditForm, setCurrentNode])


    const detailsHandler = useCallback((idNode) => {
        showDetailsForm();
        nodeIdRef.current = idNode;

        if (chartRef.current) {
            const node = chartRef.current.get(nodeIdRef.current);
            if (node) {
                setCurrentNode(node)
            }
        }
    }, [showDetailsForm, setCurrentNode])


    useEffect(() => {
        editForm.prototype.init = function (obj) {
            this.obj = obj;
        };


        const occupationHandler = (idNode) => {
            setNodePositionOccupation(null)
            const node = chartRef.current.get(idNode);
            if (node) {
                setNodePositionOccupation(node)
            }
        }

        editForm.prototype.show = function (idNode) {
            showEditForm();
            nodeIdRef.current = idNode;
            const node = chartRef.current.get(nodeIdRef.current);
            setCurrentNode(node)
        };

        editForm.prototype.hide = function (showldUpdateTheNode) {

        };

        OrgChart.SEARCH_PLACEHOLDER = "Buscar...";
        OrgChart.RES.IT_IS_LONELY_HERE_LINK = "Comienza a diagramar tu organigrama!";
        OrgChart.IT_IS_LONELY_HERE = '<g transform="translate(-105, 0)" style="cursor:pointer;"  control-add="control-add"><text fill="#039be5" style="font-size: smaller;">Comienza a diagramar tu organigrama!</text></g>'


        OrgChart.templates.invisibleGroup.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.nodeMenuButton = '';
        OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group);
        OrgChart.templates.group.min.imgs = "{val}";
        OrgChart.templates.group.min.img_0 = "";

        // para que salga el circulo con la cantidad
        OrgChart.templates.ula.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
            + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';

        OrgChart.templates.ana.resume =
            '<text class="resume" style="font-size: 18px;" fill="#ffffff" x="230" y="30" text-anchor="end">{val}</text>';
        OrgChart.templates.ana.field_1 =
            '<text class="resume" style="font-size: 18px;" fill="#ffffff" x="10" y="30" text-anchor="right"></text>';
        OrgChart.templates.ana.field_0 = '<text data-width="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFFFFF" x="125" y="75" text-anchor="middle">{val}</text>';

        if (!chart) {
            const newChart = new OrgChart(divRef.current, {
                template: "ana",
                nodes: nodes, //carga de datos
                align: OrgChart.ORIENTATION,
                layout: OrgChart.treeRightOffset,
                enableDragDrop: true,
                nodeMouseClick: OrgChart.action.none,
                nodeMenu: {
                    ocupacion: {
                        icon: personIcon,
                        text: "Ocupación",
                        onClick: occupationHandler
                    },
                    detalles: {
                        icon: detailsIcon,
                        text: "Detalles",
                        onClick: detailsHandler
                    },
                    editar: {
                        icon: editIcon,
                        text: "Editar",
                        onClick: editHandler
                    },
                    add: { text: "Agregar" },
                    remove: { text: "Eliminar" },
                },
                editUI: new editForm(),

                //el menu
                toolbar: {
                    zoom: true,
                    fit: true,
                    expandAll: true
                },

                nodeBinding: {
                    field_0: "Nombre del cargo",
                    field_1: "Cantidad de puestos",
                    field_2: "Puestos ocupados",
                    field_3: "Puestos libres",
                    field_4: "Grado de responsabilidad",
                    resume: "resume"
                },

                tags: {
                    "no-group": {
                        template: "invisibleGroup", //sin grupo                   
                    },
                    "group": {
                        template: "group", //con grupo
                    },
                    "subtree": { //config general
                        subTreeConfig: {
                            //layout: OrgChart.treeRightOffset,
                            collapse: {
                                level: 2,
                            }
                        },
                    },
                    "subtree-min": { //con min
                        min: true,
                    }
                }
            });
            setChart(newChart)
        }
    }, [chart, editHandler, detailsHandler, showEditForm, setCurrentNode, nodes, theme])

    const handleChangeOrientation = (orientation) => {
        setOrientation(orientation);
        if (chart) {
            chart.draw(OrgChart.action.update, null, function () {
                chart.setOrientation(orientation);
            });
        }
    }

    useEffect(() => {
        if (chart) {
            chart.on('click', function (sender, args) {
                if (args.node.tags.indexOf("group") !== -1) {
                    if (args.node.min) {
                        sender.maximize(args.node.id);
                    }
                    else {
                        sender.minimize(args.node.id);
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                    return false;
                }
                return true;
            });

            chart.on('add', function (sender, node) {
                // Traigo el nodo mayor
                if (chart) {
                    const codes = chart.config.nodes.map(node => node["ID del cargo"]).sort((a, b) => a.localeCompare(b));
                    const newId = codes.length > 0 ? Number(codes[codes.length - 1]) + 1 : 1;
                    node["ID del cargo"] = `${newId}`;
                } else {
                    node["ID del cargo"] = "";
                }
                node["Nombre del cargo"] = "";
                node["Cantidad de puestos"] = "";
                node["Puestos ocupados"] = "";
                node["Puestos libres"] = "";
                node["Grado de responsabilidad"] = "";
                node["Etiquetas"] = [];
                node["Potencial motivador del cargo"] = 1;
                node["Fecha actualización del potencial"] = new Date();
                node["resume"] = "0/0/0"
            });

            chart.on('added', function (sender, id) {
                if (id.startsWith("_") && nodeIdRef.current !== id) {
                    showEditForm();
                    nodeIdRef.current = id;
                    if (chartRef.current) {

                        const node = chartRef.current.get(nodeIdRef.current);
                        if (node) {
                            setCurrentNode(node)
                        }
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                }
            });

            chart.nodeMenuUI.on('show', function (sender, args) {
                const node = chartRef.current.get(args.firstNodeId);
                const children = chart.config.nodes.filter(n => n && n["pid"] && n["pid"] === args.firstNodeId);
                if (children.length > 0 || (node && node["Puestos ocupados"] !== 0)) {
                    delete args.menu.remove
                } else {
                    if (!args.menu.hasOwnProperty("remove")) {
                        args.menu.remove = { text: "Eliminar" };
                    }
                }
            });


            chart.on('drop', function (sender, draggedNodeId, droppedNodeId) {
                if (draggedNodeId === "-") {
                    return false
                }

                if (droppedNodeId) {
                    const node = chartRef.current.get(draggedNodeId);
                    const droppedNode = chartRef.current.get(droppedNodeId);
                    node.pid = droppedNode.id;
                    node["ID del cargo superior"] = droppedNode["ID del cargo"];
                    chart.updateNode(node);
                    const updatedDraggedNode = chartRef.current.getNode(droppedNodeId);
                    chart.expand(droppedNodeId, updatedDraggedNode.childrenIds);


                    return true
                }
            });

            chart.on('remove', function (sender, nodeId, newPidsAndStpidsForIds) {
                showRemoveConfirmDialog(nodeId)
                return false;
            });
            chart.load(nodes)
            chart.draw(OrgChart.action.update, null, function () {
                chart.fit();
            });
        }
    }, [chart, orientation, detailsHandler, nodes,
        editHandler, setCurrentNode, showEditForm, showRemoveConfirmDialog, setChart])

    const showSaveConfirmDialog = () => {
        setConfirmDialogIsOpen(true)
    }

    useEffect(() => {
        setTimeout(() => {
            if (!document.hidden) {
                if (chart) {
                    chart.config.nodes.forEach(node => {
                        if (node) {
                            // Limpio antes
                            if (node.hasOwnProperty("tags")) {
                                node.tags = node.tags.filter(item =>
                                    item !== "vacancy" &&
                                    item !== "vacancy-highlight" &&
                                    item !== "highlight" &&
                                    item !== "potential" &&
                                    item !== "competenceGroup" &&
                                    item !== "evaluationGroup"
                                )
                            }

                            if (hightlightNodesWithVacancies && node["Puestos libres"] > 0) {
                                if (node.hasOwnProperty("tags")) {
                                    node["tags"].push("vacancy")
                                } else {
                                    node["tags"] = ["vacancy"]
                                }
                            } else {
                                if (node.hasOwnProperty("tags") && node.tags.includes("vacancy")) {
                                    node.tags = node.tags.filter(item => item !== "vacancy" && item !== "vacancy-highlight")
                                }
                            }

                            if (node["Etiquetas"] && node["Etiquetas"].some(el => selectedElements.includes(el))) {
                                if (node.hasOwnProperty("tags")) {
                                    node["tags"].push("highlight");
                                    if (node["tags"].includes("vacancy")) {
                                        node["tags"].push("vacancy-highlight");
                                    }
                                } else {
                                    node["tags"] = ["highlight"];
                                }
                            }

                            if (
                                potential.initial >= 1 && potential.terminal <= 125 && (
                                    Number(node["Potencial motivador del cargo"]) >= potential.initial &&
                                    Number(node["Potencial motivador del cargo"]) <= potential.terminal
                                )
                            ) {
                                if (node.hasOwnProperty("tags")) {
                                    node["tags"].push("potential");
                                } else {
                                    node["tags"] = ["potential"];
                                }
                            }

                            if (node["Grupo de competencias específicas"] && selectedCompetenceGroupElements.includes(node["Grupo de competencias específicas"])) {
                                if (node.hasOwnProperty("tags")) {
                                    node["tags"].push("competenceGroup");
                                } else {
                                    node["tags"] = ["competenceGroup"];
                                }
                            }

                            if (node["Grupo de evaluación"] && selectedEvaluationGroupElements.includes(node["Grupo de evaluación"])) {
                                if (node.hasOwnProperty("tags")) {
                                    node["tags"].push("evaluationGroup");
                                } else {
                                    node["tags"] = ["evaluationGroup"];
                                }
                            }

                            // Limpio tags no necesarios
                            if (selectedElements.length === 0 && node.tags && node.tags.length > 0) {
                                node.tags = node.tags.filter(item => item !== "highlight" && item !== "vacancy-highlight")
                            }

                            if (selectedCompetenceGroupElements.length === 0 && node.tags && node.tags.length > 0) {
                                node.tags = node.tags.filter(item => item !== "competenceGroup")
                            }

                            if (selectedEvaluationGroupElements.length === 0 && node.tags && node.tags.length > 0) {
                                node.tags = node.tags.filter(item => item !== "evaluationGroup")
                            }


                            if (node.tags && node.tags.length > 0 &&
                                Number(potential.initial) === 1 && Number(potential.terminal) === 125) {
                                node.tags = node.tags.filter(item => item !== "potential")
                            }

                            chart.updateNode(node);
                        }
                    })

                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });

                    setVacanciesHighlightingInProgress(false)
                }
            }
        }, 500);
    }, [chart, hightlightNodesWithVacancies, setVacanciesHighlightingInProgress, selectedElements, potential, selectedEvaluationGroupElements, selectedCompetenceGroupElements])

    const handleeTreeLayout = () => {
        if (chartRef.current) {
            chartRef.current._layoutConfigs['base'].layout = OrgChart.tree;
            chartRef.current.draw();
        }

    }

    const handleRightOffsetTreeLayout = () => {
        if (chartRef.current) {
            chartRef.current._layoutConfigs['base'].layout = OrgChart.treeRightOffset;
            chartRef.current.draw();
        }
    }

    return (
        <>
            {vacanciesHighlightingInProgress && <Loader open={vacanciesHighlightingInProgress} />}
            <Grid container direction="row" justify="center">
                {
                    hasMultipleBranches &&
                    <CustomIconButton
                        title={getLocalizedString("goBack")}
                        onClick={handleGoBack}
                        type={"goBack"}
                    />
                }
                <CustomIconButton
                    title={getLocalizedString("save")}
                    onClick={showSaveConfirmDialog}
                    type={"save"}
                />
                <CustomIconButton
                    title={getLocalizedString("statistics")}
                    onClick={openStatsDialog}
                    type={"graphics"}
                />
                <CustomIconButton
                    title={"Resaltar puestos con vacantes"}
                    onClick={handleHighlightClick}
                    isSelected={hightlightNodesWithVacancies}
                >
                    <HighlightPositionsAvailableIcon />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar por etiquetas"}
                    isSelected={selectedElements && selectedElements.length > 0}
                    onClick={() => setFilterPositionDialogIsOpen(true)}
                >
                    <MoreIcon />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar potencial motivador"}
                    isSelected={potential && (potential.initial !== 1 || potential.terminal !== 125)}
                    onClick={() => setPotentialDialogIsOpen(true)}
                >
                    <TrendingUpIcon />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar por grupo de competencias"}
                    isSelected={selectedCompetenceGroupElements && selectedCompetenceGroupElements.length > 0}
                    onClick={() => setCompetenceGroupDialogIsOpen(true)}
                >
                    <HighlightCompetgenceGroupIcon />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar por grupo de evaluación"}
                    isSelected={selectedEvaluationGroupElements && selectedEvaluationGroupElements.length > 0}
                    onClick={() => setEvaluationGroupDialogIsOpen(true)}
                >
                    <HighlightEvaluationGroupIcon />
                </CustomIconButton>
                <Tooltip title={"Estructura en forma de árbol con desplazamiento a la derecha"}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleRightOffsetTreeLayout} >
                        <AccountTreeIcon htmlColor={color} height="25px" />
                    </Button>
                </Tooltip>
                <Tooltip title={"Estructura en forma de árbol"}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleeTreeLayout} >
                        <TreeOrgChartIcon fill={color} height="25px" />
                    </Button>
                </Tooltip>
                <FormControl style={{ width: "350px", margin: "0px 10px" }}>
                    <InputLabel id="orientation-label">{"Orientación"}</InputLabel>
                    <Select labelId="orientation-label" value={orientation} onClick={(e) => handleChangeOrientation(e.target.value || 0)}>
                        <MenuItem className={"whiteText"} value={0}>{"Arriba"}</MenuItem>
                        <MenuItem className={"whiteText"} value={3}>{"Izquierda"}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <div id="tree" ref={divRef} style={{ maxHeight: "calc(100vh - 200px)" }}></div>
        </>
    );
})

export default OwnOrgChart;