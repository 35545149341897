import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  DocumentTemplatesIcon,
  KpiDefinitionIcon,
  KpiComparisonIcon,
} from "@icarius-icons/index";

const Index = () => {

  const data = [
    {
      name: "Definición de encuestas",
      icon: <DocumentTemplatesIcon />,
      path: paths.pollSettings,
    },
    {
      name: "Análisis de encuestas",
      icon: <KpiDefinitionIcon />,
      path: paths.pollReview,
    },
    {
      name: "Comparación de encuestas",
      icon: <KpiComparisonIcon />,
      path: paths.pollComparison,
    },
  ];

  return (
    <ScreenSelector
      title={'Encuestas'}
      data={data}
    />
  );
}

export default Index;
