import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { getQuestionTypes } from "../../selectors";
import { Typography, Tooltip, InputAdornment, IconButton, TextField, Grid, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, CloseIcon, DoneIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { FileCopyIcon, ImagePlaceholder } from "@icarius-icons/index";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { deleteImageAction, uploadImageAction } from "@icarius-common/galleryDialog/actions";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import { GalleryDialog } from "@icarius-common/galleryDialog";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAppColor } from "src/app/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const PollQuestion = (props) => {

  const {
    index,
    inUse,
    data,
    hideDuplicate,
    deleteQuestion,
    duplicateQuestion,
    modifyQuestion,
  } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const images = useSelector(getGalleryImages);
  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);

  const questionTypes = useSelector(getQuestionTypes);
  const newOptionRef = useRef("");

  const isQuestionWithOptions = data.type === "O" || data.type === "L";

  const handleOptionChange = (text = '', optionIndex, isNewOption) => {
    //si el text viene de la ref del input de la nueva opcion, limpiarlo
    if (isNewOption) {
      newOptionRef.current.value = "";
    }

    const newOptionsArray = [...data.options];
    newOptionsArray.splice(optionIndex, 1, text);

    modifyQuestion(index, newOptionsArray, 'options');
  }

  const handleOptionDelete = (optionIndex) => {
    const newOptionsArray = [...data.options];
    newOptionsArray.splice(optionIndex, 1);

    modifyQuestion(index, newOptionsArray, 'options');
  }

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const handleSelectImage = (imageIndex) => {
    modifyQuestion(index, imageIndex !== null ? images[imageIndex].img : '', 'image');
    setImageIndex(imageIndex);
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <DialogTitleDivider />
      </div>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={inUse ? 12 : 11}>
          <Grid container item alignItems="center" sm={12} md={9} style={gridStyle}>
            <TextField
              disabled={inUse}
              required
              fullWidth
              margin={"none"}
              label={`${getLocalizedString("pollQuestion")} ${index + 1}`}
              value={data.question}
              onChange={(e) => modifyQuestion(index, e.target.value, 'question')}
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid container item alignItems="center" sm={12} md={3} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-question-${index + 1}`}>{getLocalizedString("type")}</InputLabel>
              <Select
                disabled={inUse}
                value={data.type}
                labelId={`label-question-${index + 1}`}
                id={`select-question-${index + 1}`}
                onChange={(e) => modifyQuestion(index, e.target.value, 'type')}
                margin={"none"}
              >
                {
                  questionTypes.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {
          !inUse &&
          <Grid container item xs={1} alignItems="center" justify="space-evenly">
            {
              !hideDuplicate &&
              <Tooltip title={getLocalizedString("duplicate")}>
                <IconButton size={"small"} disableRipple onClick={() => duplicateQuestion(index)}>
                  <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => deleteQuestion(index)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        }
        <Grid container item alignItems="center" justify="flex-end" sm={12} style={{ padding: "0px 10px" }}> {/* opciones */}
          {
            isQuestionWithOptions &&
            <Grid container item xs={12} justify="flex-end" style={{ padding: "5px 0px" }}>
              <Grid container item xs={12} md={6}>
                <Typography className={data?.options.length > 1 ? "whiteText" : "errorColor"} variant="caption">
                  {getLocalizedString("questionAtLeastTwoOption")}
                </Typography>
              </Grid>
            </Grid>
          }
          {
            isQuestionWithOptions && data.options.map((option, optionIndex) => {
              return (
                <Grid container item xs={12} justify="flex-end" key={optionIndex} style={{ padding: "5px 0px" }}>
                  <Grid container item xs={12} md={6}>
                    <TextField
                      disabled={inUse}
                      required
                      fullWidth
                      margin={"none"}
                      label={`${getLocalizedString("pollOption")} ${optionIndex + 1}`}
                      value={option}
                      onChange={(e) => handleOptionChange(e.target.value, optionIndex)}
                      inputProps={{ maxLength: 20 }}
                      InputProps={{
                        endAdornment:
                          inUse ?
                            null
                            : (
                              <InputAdornment style={{ cursor: "pointer" }}>
                                <Tooltip title={getLocalizedString("deletePollOption")}>
                                  <IconButton onClick={() => handleOptionDelete(optionIndex)}>
                                    <CloseIcon className="whiteText" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }
            )
          }
          {
            isQuestionWithOptions && !inUse &&
            <Grid container item xs={12} justify="flex-end" style={{ padding: "5px 0px" }}>
              <Grid container item xs={12} md={6} justify="center" alignItems="center">
                <TextField
                  fullWidth
                  margin={"none"}
                  label={getLocalizedString("pollAddOption")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOptionChange(e.target.value, data.options.length, true);
                    }
                  }}
                  name={"newOption"}
                  inputProps={{ maxLength: 20 }}
                  inputRef={newOptionRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ cursor: "pointer" }}>
                        <Tooltip title={getLocalizedString("pollAddOption")}>
                          <IconButton onClick={() => handleOptionChange(newOptionRef.current.value, data.options.length, true)}>
                            <DoneIcon className="whiteText" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          }
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          {
            !inUse &&
            <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={() => setImagesDialogIsOpen(true)}>
              <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
                {getLocalizedString("groupImagesTitle")}
              </Typography>
            </div>
          }
        </Grid>
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </>
  );
}

export default PollQuestion;
