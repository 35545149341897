import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  useMediaQuery,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import NoteFormLabelMenu from '../inputs/NoteFormLabelMenu';
import NoteLabel from '../inputs/noteLabel';
import NumberFormatCustomNoDecimals from "../inputs/numberFormatCustomNoDecimals";

const gridStyle = { height: 75, padding: "0px 10px" };

const DetailsEditDialog = (props) => {
  const matchesMoreOrEqualThan960 = useMediaQuery(`(min-width:960px)`);

  const {
    dialogIsOpen,
    editMode,
    currentNode,
    handleChangeField,
    cancelRefClick,
    closeRefClick,
    saveRefClick,
    positionClassifications,
    competencyGroupList,
    evaluationGroupList,
  } = props;

  const getTitle = () => {
    if (editMode) return `Editar cargo`;
    return `Ver detalle de cargo`;
  }

  // Elimino el label
  const handleRemoveLabel = (labels, id) => {
    const newLabels = labels.filter(item => item !== id);
    handleChangeField("Etiquetas", newLabels);
  }

  return (
    <Dialog
      open={dialogIsOpen}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getTitle()}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={editMode ? cancelRefClick : closeRefClick} />
        <DialogContent>
          {
            currentNode &&
            <>
              <Grid container item xs={12}>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    disabled={!editMode}
                    fullWidth
                    required
                    margin={"none"}
                    label={"ID del cargo"}
                    value={`${currentNode["ID del cargo"]}`}
                    onChange={m => handleChangeField("ID del cargo", m.target.value)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    disabled={!editMode}
                    fullWidth
                    required
                    margin={"none"}
                    label={"Nombre del cargo"}
                    value={`${currentNode["Nombre del cargo"]}`}
                    onChange={m => handleChangeField("Nombre del cargo", m.target.value)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 100 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <FormControl style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel shrink id="branchLabel">{"Grupo de evaluación"}</InputLabel>
                    <Select
                      disabled={!editMode}
                      displayEmpty
                      labelId="branchLabel"
                      fullWidth
                      onChange={(e) => handleChangeField("Grupo de evaluación", e.target.value)}
                      margin={"none"}
                      value={currentNode["Grupo de evaluación"] || ""}
                    >
                      {
                        evaluationGroupList.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    disabled={!editMode}
                    fullWidth
                    required
                    margin={"none"}
                    label={"Cantidad de puestos"}
                    value={`${currentNode["Cantidad de puestos"]}`}
                    onChange={m => handleChangeField("Cantidad de puestos", m.target.value)}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 4 }}
                    type={"text"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <FormControl style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel id="branchLabel" required>{"Grado de responsabilidad"}</InputLabel>
                    <Select
                      disabled={!editMode}
                      labelId="branchLabel"
                      fullWidth
                      onChange={(e) => handleChangeField("Grado de responsabilidad", e.target.value)}
                      margin={"none"}
                      value={currentNode["Grado de responsabilidad"] || "-"}
                    >
                      {
                        [...Array(13).keys()].map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item}
                            value={!item ? "-" : String(item).padStart(2, '0')}>
                            {!item ? "-" : String(item).padStart(2, '0')}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <FormControl style={{ width: "100%", marginTop: 10 }}>
                    <InputLabel shrink id="branchLabel">{"Grupo de competencias específicas"}</InputLabel>
                    <Select
                      disabled={!editMode}
                      displayEmpty
                      labelId="branchLabel"
                      fullWidth
                      onChange={(e) => handleChangeField("Grupo de competencias específicas", e.target.value)}
                      margin={"none"}
                      value={currentNode["Grupo de competencias específicas"] || ""}
                    >
                      {
                        competencyGroupList.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    required
                    margin={"none"}
                    label={"Puestos ocupados"}
                    value={`${currentNode["Puestos ocupados"]}`}
                    onChange={m => handleChangeField("Puestos ocupados", m.target.value)}
                    style={{ marginTop: 10 }}
                    inputProps={{ min: "0", step: "1", maxLength: 4 }}
                    type={"number"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    fullWidth
                    disabled={!editMode}
                    margin={"none"}
                    style={{ marginTop: 10 }}
                    label={'Potencial motivador (1 a 125)'}
                    value={currentNode["Potencial motivador del cargo"] || "1"}
                    onChange={m => handleChangeField("Potencial motivador del cargo", m.target.value)}
                    InputProps={{ inputComponent: NumberFormatCustomNoDecimals, min: "1", step: "0.1" }}
                  />
                </Grid>
                {matchesMoreOrEqualThan960 &&
                  <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  </Grid>
                }
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <TextField
                    disabled
                    fullWidth
                    required
                    margin={"none"}
                    label={"Puestos libres"}
                    value={`${currentNode["Puestos libres"]}`}
                    onChange={m => handleChangeField("Puestos libres", m.target.value)}
                    style={{ marginTop: 10 }}
                    inputProps={{ min: "0", step: "1", maxLength: 4 }}
                    type={"number"}
                  />
                </Grid>
                <Grid container item alignItems="center" xs={12} md={4} style={gridStyle}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      disabled={!editMode}
                      fullWidth
                      required
                      label={"Fecha actualización del potencial"}
                      cancelLabel={getLocalizedString("cancel")}
                      okLabel={getLocalizedString("ok")}
                      invalidDateMessage=''
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={typeof currentNode["Fecha actualización del potencial"] === "string" ? createDateFromDDMMYYYY(currentNode["Fecha actualización del potencial"]) : currentNode["Fecha actualización del potencial"]}
                      onChange={m => handleChangeField("Fecha actualización del potencial", m)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {
                  currentNode && currentNode["pid"] &&
                  <Grid container item alignItems="center" xs={12} style={gridStyle} >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={currentNode.hasOwnProperty("tags") ? currentNode.tags.includes("assistant") : false}
                          onChange={() => handleChangeField("Asistente", !(currentNode.hasOwnProperty("tags") ? currentNode.tags.includes("assistant") : false))}
                          value={currentNode.hasOwnProperty("tags") ? currentNode.tags.includes("assistant") : false}
                          color="primary"
                        />
                      }
                      label={"Asistente"}
                      labelPlacement="start"
                      style={{ margin: 5, marginLeft: -30 }}
                      disabled={!editMode}
                      required
                    />
                  </Grid>
                }
              </Grid>
              {
                currentNode && currentNode["Etiquetas"] &&
                <div className="flex flex-wrap w-full p-4" style={{ paddingBottom: 8 }}>
                  {
                    currentNode["Etiquetas"].map((item, i) => (
                      <NoteLabel
                        actualLabels={currentNode["Etiquetas"]}
                        labels={positionClassifications}
                        labelCode={item}
                        key={i}
                        className="mt-4 mr-4"
                        onDelete={editMode ? handleRemoveLabel : null}
                      />
                    ))
                  }
                </div>
              }
              {
                editMode && currentNode && currentNode["Etiquetas"] &&
                <NoteFormLabelMenu labels={positionClassifications} values={currentNode["Etiquetas"]} onChange={handleChangeField} />
              }
            </>
          }
        </DialogContent>
      </div>
      <DialogActions>
        {
          editMode &&
          <ButtonDialogAction onClick={cancelRefClick} text={getLocalizedString("cancel")} />
        }
        {
          !editMode &&
          <ButtonDialogAction onClick={closeRefClick} text={getLocalizedString("close")} />
        }
        {
          editMode &&
          <ButtonDialogAction onClick={saveRefClick} isAccept text={getLocalizedString("agree")} />
        }
      </DialogActions>
    </Dialog>
  )
}

export default DetailsEditDialog;