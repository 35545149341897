import React from "react";
import Swiper from "react-id-swiper";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography, Divider } from "@material-ui/core";
import QuestionGraph from "@icarius-pages/pollReview/components/questionGraph";

class QuestionSlider extends React.Component {
  swiper = null;

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.data !== this.props.data ||
      nextProps.color !== this.props.color ||
      nextProps.theme !== this.props.theme
    );
  }

  render() {
    const {
      color,
      colors,
      data,
      handleQuestionClick,
      theme,
    } = this.props;

    let colorFlechaAnterior, colorFlechaSiguiente;

    if (color) {
      colorFlechaAnterior =
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23" +
        color.slice(1) +
        "'%2F%3E%3C%2Fsvg%3E\")";

      colorFlechaSiguiente =
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 27 44'%3E%3Cpath d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z' fill%3D'%23" +
        color.slice(1) +
        "'%2F%3E%3C%2Fsvg%3E\")";
    }
    const params = {
      slidesPerView: 3,
      spaceBetween: 20,
      breakpoints: {
        1300: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        900: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      grabCursor: true,
      rebuildOnUpdate: true,
      containerClass: "main-content-swiper-container",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      renderPrevButton: () => (
        <div style={{ backgroundImage: colorFlechaAnterior }} className="swiper-button-prev"></div>
      ),
      renderNextButton: () => (
        <div style={{ backgroundImage: colorFlechaSiguiente }} className="swiper-button-next"></div>
      ),
      slideToClickedSlide: true,
    };

    const hasData = Boolean(data?.length);

    return (
      <div
        id="pollQuestions"
        className={"encuestas slider-container"}
        style={{ overflow: "hidden", height: hasData && 515, marginBottom: 10, padding: 0 }}>
        <Typography className={"title whiteText"}>
          {getLocalizedString("questions")} {data.length > 0 ? "(" + data.length + ")" : ""}
        </Typography>
        <Divider style={{ background: color, height: 2 }} />
        {
          !hasData ?
            <Typography className="whiteText" align="center" variant="h2" style={{ fontSize: 32, fontWeight: 700, margin: 16 }}>
              {'No hay preguntas en común entre las encuestas seleccionadas.'}
            </Typography>
            :
            <Swiper
              {...params}
              ref={node => {
                if (node) this.swiper = node.swiper;
              }}
              className={"swiper-wrapper"}>
              {
                data.map((item, index) => {
                  return (
                    <div key={index}>
                      <QuestionGraph
                        data={Array.isArray(item) ? item : [item]}
                        onClick={() => handleQuestionClick(item)}
                        colors={colors}
                        theme={theme}
                      />
                    </div>
                  );
                })
              }
            </Swiper>
        }
      </div>
    );
  }
}

export default QuestionSlider;
