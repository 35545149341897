import React from "react";
import Loader from "@icarius-common/loader";
import useFavourites from "@icarius-common/favouriteButton/components/useFavourites";
import GroupSelector from "./groupSelector";
import GridScreen from "./gridScreen";
import useQueries from "./useQueries";

const Queries = () => {

  const {
    state,
    handlers,
    isLoading,
    groups,
    queries,
    isFromHiddenGroup,
  } = useQueries();

  const {
    isLoadingFavourites,
    favouriteList,
    handleChangeFavourite,
  } = useFavourites('CON');

  return (
    <section id={"my-people-page"}>
      <Loader open={isLoading || isLoadingFavourites} />
      {
        state.gridIsOpen
          ?
          <GridScreen
            title={state.groupName}
            queryPreset={state.queryPreset}
            userPreset={state.userPreset}
            queries={queries}
            favouriteList={favouriteList}
            isFromHiddenGroup={isFromHiddenGroup}
            handleChangeFavourite={handleChangeFavourite}
            handleCloseGrid={handlers.handleCloseGrid}
          />
          :
          !isLoading &&
          <GroupSelector
            groups={groups}
            handleClick={handlers.handleOpenGrid}
          />
      }
    </section>
  );
}

export default Queries;