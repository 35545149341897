import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Código de proceso',
            field: 'Código de proceso',
            filter: 'agSetColumnFilter',
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Nombre',
            field: 'Nombre',
            filter: 'agTextColumnFilter',
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Fecha de ejecución',
            field: 'Fecha de ejecución',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Hora de ejecución',
            field: 'Hora de ejecución',
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["Hora de ejecución"] || "")) || "";
            },
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Estado de programación',
            field: 'Estado de programación',
            filter: "agSetColumnFilter",
            cellRenderer: "Renderer",
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Recibir aviso',
            field: 'Recibir aviso string',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Usuario programador',
            field: 'Usuario programador',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Apellidos y nombres',
            field: 'Apellidos y nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Alcance del proceso',
            field: 'Alcance del proceso',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Colaboradores actuales para procesar',
            field: 'Colaboradores actuales para procesar',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Fecha de término',
            field: 'Fecha de término',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Hora de término',
            field: 'Hora de término',
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["Hora de término"] || "")) || "";
            },
        }
    },
    {
        pages: [paths.processScheduling],
        config: {
            headerName: 'Duración del proceso',
            field: 'Duración del proceso',
            filter: "agTextColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["Duración del proceso"] || "")) || "";
            },
        }
    },
]