import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData, getProcessCodes } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import {
  getProcessSchedulingAction,
  deleteProcessSchedulingAction,
  createProcessSchedulingAction,
  updateProcessSchedulingAction,
  duplicateProcessSchedulingAction,
} from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useProcessScheduling = () => {

  const [rowSelected, setRowSelected] = useState('');
  const [abmDialogIsOpen, setABMDialogIsOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const dispatch = useDispatch();

  const data = useSelector(getData);
  const processCodes = useSelector(getProcessCodes);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getProcessSchedulingAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar programación de proceso",
      msg: "¿Desea eliminar la programación del proceso?",
      onConfirm: () => handleDelete(selectedRows[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setABMDialogIsOpen(true);
    setRowSelected(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    if (selectedRows[0]['Estado de programación'] !== 'Programado') {
      dispatch(openSnackbarAction({ msg: "Debe tener estado Programado", severity: "warning" }));
      return;
    }

    setABMDialogIsOpen(true);
    setRowSelected(selectedRows[0]);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    if (selectedRows[0]['Estado de programación'] !== 'Programado') {
      dispatch(openSnackbarAction({ msg: "Debe tener estado Programado", severity: "warning" }));
      return;
    }

    setABMDialogIsOpen(true);
    setIsDuplicate(true);
    setRowSelected(selectedRows[0]);
  }

  const handleCloseABMDialog = () => {
    setABMDialogIsOpen(false);
    setIsDuplicate(false);
    setRowSelected(null);
  }

  const handleDelete = (document) => {
    dispatch(deleteProcessSchedulingAction({ internalCode: document.internalCode }));
  }

  const handleCreate = (dataToSend) => {
    dispatch(createProcessSchedulingAction(dataToSend))
      .then(() => {
        handleCloseABMDialog();
      })
  }

  const handleUpdate = (dataToSend) => {
    dispatch(updateProcessSchedulingAction(dataToSend))
      .then(() => {
        handleCloseABMDialog();
      })
  }

  const handleDuplicate = (dataToSend) => {
    dispatch(duplicateProcessSchedulingAction({
      ...dataToSend,
      originalCode: rowSelected.code,
    }))
      .then(() => {
        handleCloseABMDialog();
      })
  }

  const handleSubmit = (dataToSend) => {
    if (abmDialogIsOpen && isDuplicate) return handleDuplicate(dataToSend);
    if (abmDialogIsOpen && Boolean(rowSelected)) return handleUpdate(dataToSend);
    return handleCreate(dataToSend);
  }

  const dialogState = {
    abmDialogIsOpen,
    isDuplicate,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleOpenDuplicateDialog,
    handleCloseABMDialog,
    handleDelete,
    handleSubmit,
  }

  return {
    isLoading,
    data,
    processCodes,
    rowSelected,
    dialogState,
    handlers
  }
}

export default useProcessScheduling;