import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";

export const CHECK_URL = NAME + "/CHECK_URL";
export const CHECK_URL_REJECTED = NAME + "/CHECK_URL_REJECTED";
export const CHECK_URL_FULFILLED = NAME + "/CHECK_URL_FULFILLED";

export const ORDER = NAME + "/ORDER";
export const ORDER_REJECTED = NAME + "/ORDER_REJECTED";
export const ORDER_FULFILLED = NAME + "/ORDER_FULFILLED";