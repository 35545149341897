import React, { useState } from 'react';
import { Button, Popover, ClickAwayListener, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from "@icarius-icons";

function NoteFormLabelMenu({ labels, values, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleToggleLabel(label) {
        let newLabelList = values;
        if (values.find(item => item === label)) {
            newLabelList = values.filter(item => item !== label)
        } else {
            newLabelList.push(label)
        }
        onChange("Etiquetas", newLabelList);
    }
    return (
        <div>
            <Tooltip title={"Clasificación del cargo"} placement="bottom">

                <Button
                    className="whiteText"
                    disableRipple={true}
                    onClick={handleMenuClick}
                    variant={"outlined"}
                >
                    {"Etiquetar"}
                </Button>
            </Tooltip>
            <Popover
                hideBackdrop={true}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                className="pointer-events-none"
                classes={{
                    paper: "pointer-events-auto py-8 prevent-add-close dialog-container"
                }}
            >
                <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleMenuClose}>
                    <List >
                        {labels.map(item => {
                            return <ListItem
                                key={item.key}
                                button
                                dense
                                onClick={() => handleToggleLabel(item.key)}
                            >
                                {values.includes(item.key) ? <CheckBoxIcon className="list-item-icon text-16 whiteText" color="action" /> : <CheckBoxOutlineBlankIcon className="list-item-icon text-16 whiteText" color="action" />}
                                <ListItemText className="truncate pl-8 whiteText" primary={item.value} disableTypography={true} />
                            </ListItem>
                        })}
                    </List>
                </ClickAwayListener>
            </Popover>
        </div>
    );
}

export default NoteFormLabelMenu;

