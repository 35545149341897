import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getDateFormat = store => store[NAME].dateFormat;
export const getLocale = store => store[NAME].currencyLocalization;

export const getQueryGroups = store => store[NAME].groups;
export const getGroupsToFilter = store => store[NAME].groupsToFilter;
export const getFilters = store => store[NAME].filters;
export const getQueries = store => store[NAME].queries;
export const getQueryResult = store => store[NAME].queryResult;
export const getContext = store => store[NAME].context;

//Templates
export const getTemplatesRows = store => store[NAME].templates;
export const getIsLoadingTemplates = store => store[NAME].isLoadingTemplates;