import * as actionTypes from "./actionTypes";

const initialState = {
  polls: [],
  pollTypes: [],
  questionTypes: [],
  modes: [],
  processList: [],
  roles: [],
  isLoading: false,
  dateFormat: "yyyy/mm/dd",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_POLL_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.GET_POLL_SETTINGS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        polls: action.payload.polls,
        pollTypes: action.payload.pollTypes,
        questionTypes: action.payload.questionTypes,
        roles: action.payload.roles,
        dateFormat: action.payload.dateFormat,
        modes: action.payload.modes,
        processList: action.payload.processList,
      };
    case actionTypes.GET_POLL_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE_POLL_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_POLL_SETTINGS_FULFILLED:
      return { ...state, isLoading: false, polls: action.payload.polls };
    case actionTypes.DELETE_POLL_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE_POLL_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_POLL_SETTINGS_FULFILLED:
      return { ...state, isLoading: false, polls: action.payload.polls };
    case actionTypes.CREATE_POLL_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY_POLL_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_POLL_SETTINGS_FULFILLED:
      return { ...state, isLoading: false, polls: action.payload.polls };
    case actionTypes.MODIFY_POLL_SETTINGS_REJECTED:
      return { ...state, isLoading: false };
    
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
