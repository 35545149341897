import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_POLL_SETTINGS = NAME + "/GET_POLL_SETTINGS";
export const GET_POLL_SETTINGS_REJECTED = NAME + "/GET_POLL_SETTINGS_REJECTED";
export const GET_POLL_SETTINGS_FULFILLED = NAME + "/GET_POLL_SETTINGS_FULFILLED";

export const DELETE_POLL_SETTINGS = NAME + "/DELETE_POLL_SETTINGS";
export const DELETE_POLL_SETTINGS_REJECTED = NAME + "/DELETE_POLL_SETTINGS_REJECTED";
export const DELETE_POLL_SETTINGS_FULFILLED = NAME + "/DELETE_POLL_SETTINGS_FULFILLED";

export const CREATE_POLL_SETTINGS = NAME + "/CREATE_POLL_SETTINGS";
export const CREATE_POLL_SETTINGS_REJECTED = NAME + "/CREATE_POLL_SETTINGS_REJECTED";
export const CREATE_POLL_SETTINGS_FULFILLED = NAME + "/CREATE_POLL_SETTINGS_FULFILLED";

export const MODIFY_POLL_SETTINGS = NAME + "/MODIFY_POLL_SETTINGS";
export const MODIFY_POLL_SETTINGS_REJECTED = NAME + "/MODIFY_POLL_SETTINGS_REJECTED";
export const MODIFY_POLL_SETTINGS_FULFILLED = NAME + "/MODIFY_POLL_SETTINGS_FULFILLED";