import React, { useRef, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import ReactApexChart from 'react-apexcharts';
import useGetRefWidthAndHeight from "@icarius-utils/hooks/useGetRefWidthAndHeight";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

export const getChartWidth = (columnAmount, containerWidth, minWidth) => {
  const acceptableMinWidth = minWidth || columnAmount * 150;
  if (containerWidth < 500 && acceptableMinWidth < 500) return 500; // si los dos son menores a 500, forzar ese de ancho
  if (containerWidth > acceptableMinWidth) return containerWidth;
  return acceptableMinWidth;
}

const PollSelection = (props) => {

  const {
    data,
    years,
    color,
    pollsSelected,
    pollsSelectedColors,
    handlePollClick,
    handleYearClick,
  } = props;

  const ref = useRef();
  const { width: containerWidth } = useGetRefWidthAndHeight(ref);
  const [selectedYear, setSelectedYear] = useState(years?.at(-1));

  // si existe years, data es array de objetos con year y polls, si no hay years, data es un array de polls
  const pollsToUse = years ? data[years?.indexOf(selectedYear)].polls : data;

  const getColorArray = () => {
    const colors = Array(pollsToUse.length).fill(color);
    [...pollsSelected].forEach((pollSelected, pollSelectedIndex) => {
      colors[pollsToUse.findIndex((poll) => poll.code === pollSelected.code)] = pollsSelectedColors?.[pollSelectedIndex] || pollsSelectedColors[0];
    })

    return colors;
  }

  const chartData = {
    series: [
      {
        data: pollsToUse.map(item => item.answersAmount),
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: getChartWidth(pollsToUse.length, containerWidth - 20), // el - 20 es por el padding del div
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection(event, chartContext, config) {
            handlePollClick(pollsToUse[config.dataPointIndex]);
          }
        },
      },
      grid: { show: true },
      colors: getColorArray(),
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      xaxis: {
        categories: pollsToUse.map(item => item.name),
        labels: {
          trim: true,
          style: {
            colors: 'var(--mainText)',
          },
        }
      },
      yaxis: {
        labels: {
          rotate: 0,
          style: {
            fontSize: '12px',
            colors: 'var(--mainText)',
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      states: {
        active: {
          filter: {
            type: 'none',
          }
        }
      },
      tooltip: {
        marker: {
          show: false,
        },
        followCursor: true,
        theme: 'dark',
        y: {
          formatter: function (val) {
            return formatNumberOrReturnUndefined(val, 0, 2)
          },
          title: {
            formatter: function () {
              return ''
            }
          }
        },
      }
    }
  };

  return (
    <>
      <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">
        <div className="flex-col">
          <Typography className="whiteText">
            {
              Boolean(pollsSelected?.length) ?
                <>
                  Encuestas seleccionadas:
                  {
                    <span style={{ paddingLeft: 5, fontWeight: 600 }}>
                      {pollsSelected?.map((item) => item.name).join(" vs. ")}
                    </span>
                  }
                </>
                : 'Encuestas'
            }
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          {
            years?.map((year) => {
              const isSelected = year === selectedYear;
              return (
                <Button
                  key={year}
                  onClick={() => {
                    setSelectedYear(year);
                    handleYearClick();
                  }}
                  disabled={isSelected}
                  style={{
                    marginRight: 5,
                    backgroundColor: isSelected ? "rgba(0, 0, 0, 0.1)" : "",
                  }}
                >
                  {year}
                </Button>
              )
            })
          }
        </div>
      </div>
      <div ref={ref} style={{ padding: 10, overflow: 'auto' }}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type={chartData.options.chart.type}
          height={chartData.options.chart.height}
          width={chartData.options.chart.width}
        />
      </div>
    </>
  );
}

export default PollSelection;
