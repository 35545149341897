import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useProcessScheduling from "./useProcessScheduling";
import ABMDialog from "./ABMDialog";
import Renderer from "./renderer";

const ProcessScheduling = () => {

  const {
    isLoading,
    data,
    processCodes,
    rowSelected,
    dialogState,
    handlers
  } = useProcessScheduling();

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const editButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Programación de procesos de cálculo actuales'}
      gridTitle={'Programación de procesos de cálculo actuales'}
      columnDefPage={paths.processScheduling}
      rowData={data}
      menuItems={[createButton, editButton, duplicateButton, deleteButton]}
      hasExpand
      hasHelp
      frameworkComponents={{ Renderer }}
    >
      {
        dialogState.abmDialogIsOpen &&
        <ABMDialog
          open={dialogState.abmDialogIsOpen}
          loading={isLoading}
          data={rowSelected}
          isDuplicate={dialogState.isDuplicate}
          processCodes={processCodes}
          handleSubmit={handlers.handleSubmit}
          handleClose={handlers.handleCloseABMDialog}
        />
      }
    </CommonPage>
  )
}

export default ProcessScheduling;