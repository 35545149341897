import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getPolls = store => store[NAME].polls;
export const getQuestionTypes = store => store[NAME].questionTypes;
export const getRoles = store => store[NAME].roles;
export const getPollTypes = store => store[NAME].pollTypes;
export const getProcessList = store => store[NAME].processList;
export const getDateFormat = store => store[NAME].dateFormat;
export const getModes = store => store[NAME].modes;
