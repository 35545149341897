import {
  GET_ORGANIZATION_CHART_DATA,
  GET_ORGANIZATION_CHART_DATA_FULFILLED,
  GET_ORGANIZATION_CHART_DATA_REJECTED,
  GET_CLIENT_BRANCHES_DATA,
  GET_CLIENT_BRANCHES_DATA_FULFILLED,
  GET_CLIENT_BRANCHES_DATA_REJECTED,
  SAVE_ORGANIZATION_CHART_DATA,
  SAVE_ORGANIZATION_CHART_DATA_FULFILLED,
  SAVE_ORGANIZATION_CHART_DATA_REJECTED,
  GET_POSITION_OCCUPATION_DATA,
  GET_POSITION_OCCUPATION_DATA_FULFILLED,
  GET_POSITION_OCCUPATION_DATA_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getOrgChart,
  getClientBranches,
  saveOrgChart,
  getPositionOccupationEndpoint,
  recomposePositionsAPI,
} from "@icarius-connection/api";

export const getOrgChartAction = (branch) => async (dispatch) => {
  dispatch({ type: GET_ORGANIZATION_CHART_DATA });
  try {
    let query = ""

    if (branch !== undefined && branch !== "") {
      query = `?code=${branch}`;
    }

    let response = await getOrgChart(query);

    if (response.data.status === "OK") {
      let orgChart = response.data.result.orgchart;
      let positionClassifications = response.data.result.positionClassifications;
      let competencyGroupList = response.data.result.competencyGroupList;
      let evaluationGroupList = response.data.result.evaluationGroupList;

      dispatch({
        type: GET_ORGANIZATION_CHART_DATA_FULFILLED,
        payload: {
          orgChart,
          positionClassifications,
          competencyGroupList,
          evaluationGroupList,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const saveOrgChartAction = (branch, data) => async (dispatch) => {
  dispatch({ type: SAVE_ORGANIZATION_CHART_DATA });
  try {
    let dataToSend = { data: data };

    if (branch !== undefined && branch !== "") {
      dataToSend.branch = branch;
    }

    let response = await saveOrgChart(dataToSend);

    if (response.data.status === "OK") {
      let orgChart = response.data.result.orgchart;

      dispatch({
        type: SAVE_ORGANIZATION_CHART_DATA_FULFILLED,
        payload: { orgChart },
      });

      return response;
    } else {
      dispatch({ type: SAVE_ORGANIZATION_CHART_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: SAVE_ORGANIZATION_CHART_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getClientBranchesAction = () => async (dispatch) => {
  dispatch({ type: GET_CLIENT_BRANCHES_DATA });
  try {
    let response = await getClientBranches();

    if (response.data.status === "OK") {
      let branches = response.data.result;

      dispatch({
        type: GET_CLIENT_BRANCHES_DATA_FULFILLED,
        payload: { branches },
      });

      return response;
    } else {
      dispatch({ type: GET_CLIENT_BRANCHES_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_CLIENT_BRANCHES_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getPositionOccupation = (node) => async (dispatch) => {
  dispatch({ type: GET_POSITION_OCCUPATION_DATA });
  try {
    let query = `?id=${node}`;
    let response = await getPositionOccupationEndpoint(query);
    if (response.data.status === "OK") {

      let positionOccupationData = response.data.result;

      dispatch({
        type: GET_POSITION_OCCUPATION_DATA_FULFILLED,
        payload: {
          positionOccupationData,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_POSITION_OCCUPATION_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_POSITION_OCCUPATION_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const recomposePositionsAction = (code) => async (dispatch) => {
  dispatch({ type: GET_ORGANIZATION_CHART_DATA });
  try {
    let response = await recomposePositionsAPI({ code });

    if (response.data.status === "OK") {
      let orgChart = response.data.result.orgchart;
      let positionClassifications = response.data.result.positionClassifications;
      dispatch({
        type: GET_ORGANIZATION_CHART_DATA_FULFILLED,
        payload: {
          orgChart,
          positionClassifications
        },
      });
      dispatch(openSnackbarAction({ msg: "Recomposición de puestos realizada con éxito", severity: "success" }));

      return response;
    } else {
      dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};