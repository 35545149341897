import * as actionTypes from "./actionTypes";
import {
  getProcessSchedulingAPI,
  createProcessSchedulingAPI,
  updateProcessSchedulingAPI,
  deleteProcessSchedulingAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe una programación con esos datos';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar la programación porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getProcessSchedulingAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getProcessSchedulingAPI();

    let data = response.data && response.data.data;
    let processCodes = response.data && response.data.processList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, processCodes },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteProcessSchedulingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteProcessSchedulingAPI(dataToSend);

    let data = response.data && response.data.data;
    let processCodes = response.data && response.data.processList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data, processCodes },
    });

    dispatch(openSnackbarAction({ msg: "Programación de proceso eliminada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createProcessSchedulingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createProcessSchedulingAPI(dataToSend);

    let data = response.data && response.data.data;
    let processCodes = response.data && response.data.processList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data, processCodes },
    });

    dispatch(openSnackbarAction({ msg: 'Programación de proceso creada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateProcessSchedulingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateProcessSchedulingAPI(dataToSend);

    let data = response.data && response.data.data;
    let processCodes = response.data && response.data.processList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data, processCodes },
    });

    dispatch(openSnackbarAction({ msg: 'Programación de proceso actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateProcessSchedulingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await createProcessSchedulingAPI(dataToSend);

    let data = response.data && response.data.data;
    let processCodes = response.data && response.data.processList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data, processCodes },
    });

    dispatch(openSnackbarAction({ msg: 'Programación de proceso duplicada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};