import NAME from "./constants";

export const GET_QUERY_GROUPS = NAME + "/GET_QUERY_GROUPS";
export const GET_QUERY_GROUPS_FULFILLED = NAME + "/GET_QUERY_GROUPS_FULFILLED";
export const GET_QUERY_GROUPS_REJECTED = NAME + "/GET_QUERY_GROUPS_REJECTED";

export const GET_QUERIES = NAME + "/GET_QUERIES";
export const GET_QUERIES_FULFILLED = NAME + "/GET_QUERIES_FULFILLED";
export const GET_QUERIES_REJECTED = NAME + "/GET_QUERIES_REJECTED";

export const GET_QUERY_RESULT = NAME + "/GET_QUERY_RESULT";
export const GET_QUERY_RESULT_FULFILLED = NAME + "/GET_QUERY_RESULT_FULFILLED";
export const GET_QUERY_RESULT_REJECTED = NAME + "/GET_QUERY_RESULT_REJECTED";

export const CREATE_TEMPLATE_REQUEST = NAME + "/CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_REQUEST_END = NAME + "/CREATE_TEMPLATE_REQUEST_END";

export const GET_TEMPLATES = NAME + "/GET_TEMPLATES";
export const GET_TEMPLATES_FULFILLED = NAME + "/GET_TEMPLATES_FULFILLED";
export const GET_TEMPLATES_REJECTED = NAME + "/GET_TEMPLATES_REJECTED";

export const DELETE_TEMPLATE_REQUEST = NAME + "/DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_REQUEST_END = NAME + "/DELETE_TEMPLATE_REQUEST_END";

export const UPDATE_TEMPLATE_REQUEST = NAME + "/UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_REQUEST_END = NAME + "/UPDATE_TEMPLATE_REQUEST_END";

export const ADD_TEMPLATE = NAME + "/ADD_TEMPLATE";
export const REMOVE_TEMPLATE = NAME + "/REMOVE_TEMPLATE";
export const UPDATE_TEMPLATE = NAME + "/UPDATE_TEMPLATE";

export const RESET_ROWS = NAME + "/RESET_ROWS";