import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  dateFormat: "yyyy/mm/dd",
  currencyLocalization: "da",

  groups: [],
  groupsToFilter: [],

  filters: null,
  queries: [],
  queryResult: [],
  context: null,

  templates: [],
  isLoadingTemplates: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_QUERY_GROUPS:
      return { ...state, isLoading: true };
    case actionTypes.GET_QUERY_GROUPS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        groups: action.payload.groups,
        groupsToFilter: action.payload.groupsToFilter,
      };
    case actionTypes.GET_QUERY_GROUPS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_QUERIES:
      return {
        ...state,
        isLoading: true,
        context: null,
      };
    case actionTypes.GET_QUERIES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        queries: action.payload.queries,
      };
    case actionTypes.GET_QUERIES_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_QUERY_RESULT:
      return { ...state, isLoading: true };
    case actionTypes.GET_QUERY_RESULT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        queryResult: action.payload.queryResult,
        context: action.payload.context,
        dateFormat: action.payload.date_format,
        filters: action.payload.filters,
        currencyLocalization: action.payload.currencyLocalization,
      };
    case actionTypes.GET_QUERY_RESULT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.RESET_ROWS:
      return { ...state, queryResult: [] };

    case actionTypes.GET_TEMPLATES:
      return { ...state, isLoadingTemplates: true };
    case actionTypes.GET_TEMPLATES_FULFILLED:
      return {
        ...state,
        isLoadingTemplates: false,
        templates: action.payload.templates || [],
      };
    case actionTypes.GET_TEMPLATES_REJECTED:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.CREATE_TEMPLATE_REQUEST: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.CREATE_TEMPLATE_REQUEST_END:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.UPDATE_TEMPLATE_REQUEST: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.UPDATE_TEMPLATE_REQUEST_END:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.DELETE_TEMPLATE_REQUEST: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.DELETE_TEMPLATE_REQUEST_END:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.ADD_TEMPLATE: {
      let arr2 = state.templates.length > 0 ? [...state.templates] : [];
      arr2.push(action.payload);
      return { ...state, templates: arr2 };
    }
    case actionTypes.UPDATE_TEMPLATE: {
      let modifiedTemplates = [...state.templates].map((item, index) => {
        if (item.Code === action.payload.Code) {
          item.U_columnstate = JSON.stringify(action.payload.U_columnstate);
          item.U_filterstate = JSON.stringify(action.payload.U_filterstate);
          item.U_sortstate = JSON.stringify(action.payload.U_sortstate);
          item.U_groupstate = JSON.stringify(action.payload.U_groupstate);
          item.U_pivotstate = JSON.stringify(action.payload.U_pivotstate);
          item.U_selstate = JSON.stringify(action.payload.U_selstate);
          item.U_graphicstate = JSON.stringify(action.payload.U_graphicstate);
        }
        return item;
      });

      return { ...state, templates: modifiedTemplates };
    }
    case actionTypes.REMOVE_TEMPLATE: {
      let arrFinal = [...state.templates].filter(field => field.Code !== action.payload);
      return { ...state, templates: arrFinal };
    }
    default:
      return state;
  }
}