import React, { useState } from "react";
import ReactWordcloud from 'react-wordcloud';
import { Grid, Typography } from "@material-ui/core";
import DoughnutChart from "./doughnut/doughnutChart";
import { WORDS_TO_REMOVE } from '../../constants';
import SectionContainer from './sectionContainer';
import { SentimentGood, SentimentBad, SentimentNeutral } from "@icarius-icons"
import SentimentDialog from "./sentimentDialog";
import paths from "@icarius-localization/paths";

const WordsPollData = (props) => {

    const {
        questionData,
        sentiments,
        forceGridDataUpdate,
    } = props;

    const [gridData, setGridData] = useState(null);

    const sentimentsData = [
        {
            icon: <SentimentBad />,
            data: sentiments['Muy Negativa']
        },
        {
            icon: <SentimentNeutral />,
            data: sentiments['Neutra']
        },
        {
            icon: <SentimentGood />,
            data: sentiments['Muy Positiva']
        },
    ]

    const handleSentimentClick = (type, data) => {
        setGridData({ type, data });
    }

    const getFilteredWords = (wordsArray) => {
        return wordsArray.filter(word => {
            const formattedWord = word.toLowerCase().replace(/[^ña-zá-ú]/g, "");
            return formattedWord.length > 1 && !WORDS_TO_REMOVE.includes(formattedWord)
        });
    }

    const getWordsArray = () => {
        const answersArray = questionData.map((question) => question.RESPUESTA);
        return getFilteredWords(answersArray.reduce((prev, current) => {
            return (
                [
                    ...prev,
                    ...current.split(" ").map((answer) => answer.toLowerCase().replace(/[^a-zá-ú]/g, ""))]
            )
        }, []));
    }

    const getCountArray = () => {
        const countArray = getWordsArray().reduce((prev, current) => {
            return (prev[current] ? prev[current]++ : prev[current] = 1, prev)
        }, [])
        return countArray;
    }

    const getCloudData = () => {
        const countArray = getCountArray();
        return Object.keys(countArray).map((item, index) => {
            return (
                {
                    text: item,
                    value: Object.values(countArray)[index],
                }
            )
        }).sort((a, b) => b.value - a.value);
    }

    const words = getCloudData();

    const wordCloudOptions = {
        colors: ["var(--mainText)"],
        enableTooltip: false,
        deterministic: true,
        fontFamily: "roboto",
        fontSizes: [25, 70],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 0,
        scale: "sqrt",
        spiral: "rectangular",
        transitionDuration: 2000
    };

    return (
        <>
            <SectionContainer ignoreContainer>
                <div style={{ height: 400, minWidth: 400, maxWidth: 800, margin: '0 auto', paddingTop: 20 }}>
                    <ReactWordcloud words={words} options={wordCloudOptions} />
                </div>
            </SectionContainer>
            <Grid container direction="row">
                <DoughnutChart words={words} isTopFive={true} />
                <DoughnutChart words={words} />
            </Grid>
            <SectionContainer title="Análisis de sentimientos" useBigWidth>
                <Grid container style={{ paddingTop: 20 }}>
                    {
                        sentimentsData.map((sentiment, index) => {
                            return (
                                <Grid
                                    key={index}
                                    container item xs={4} direction='column' alignItems="center" justify="center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleSentimentClick(index, sentiment.data)}
                                >
                                    {sentiment.icon}
                                    <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                        {sentiment.data.length}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Typography className='whiteText' style={{ fontSize: 20, textAlign: 'center', paddingTop: 30 }}>
                    {'Haga clic en cada uno para ver más información'}
                </Typography>
            </SectionContainer>
            <SectionContainer title={'Todas las respuestas'} useBigWidth useBigHeight={questionData.length > 5}>
                {
                    questionData.map((question, index) => {
                        return (
                            <Typography key={index} className='whiteText' style={{ fontSize: 20, margin: '5px 0px' }}>
                                {question.RESPUESTA}
                            </Typography>
                        )
                    })
                }
            </SectionContainer>
            {
                Boolean(gridData?.data) &&
                <SentimentDialog
                    open={Boolean(gridData?.data)}
                    type={gridData?.type}
                    data={gridData?.data}
                    path={paths.pollReviewSentiments}
                    handleClose={() => {
                        setGridData(null);
                        forceGridDataUpdate();
                    }}
                />
            }
        </>
    );
}

export default WordsPollData;
