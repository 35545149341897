import React from "react";
import NumberFormat from 'react-number-format';
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

class NumberFormatCustomNoDecimals extends React.Component {
    render() {
        const { inputRef, onChange, ...other } = this.props;
        const n = formatNumberOrReturnUndefined(11111.1, 2);
        const decimalSeparator = n.substring(n.length - 3, n.length - 2);
        const thousandSeparator = n.substring(2, 3);

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: this.props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                isNumericString
                fixedDecimalScale
                decimalScale={0}
                inputProps={{ maxLength: 3 }}
                isAllowed={this.checkLength}
            />
        );
    }

    checkLength = (inputObj) => {
        const { value } = inputObj;
        return value <= 125 || value === undefined;
    };

}
export default NumberFormatCustomNoDecimals;