/* eslint-disable array-callback-return */
import {
  GET_QUERY_GROUPS,
  GET_QUERY_GROUPS_FULFILLED,
  GET_QUERY_GROUPS_REJECTED,
  GET_QUERIES,
  GET_QUERIES_FULFILLED,
  GET_QUERIES_REJECTED,
  GET_QUERY_RESULT,
  GET_QUERY_RESULT_FULFILLED,
  GET_QUERY_RESULT_REJECTED,
  GET_TEMPLATES_REJECTED,
  GET_TEMPLATES_FULFILLED,
  GET_TEMPLATES,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_REQUEST_END,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST_END,
  UPDATE_TEMPLATE_REQUEST_END,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE,
  ADD_TEMPLATE,
  REMOVE_TEMPLATE,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getTemplatesAPI,
  createTemplateAPI,
  deleteTemplateAPI,
  updateTemplateAPI,
  getQueryGroupsAPI,
  getQueriesAPI,
  getQueryResultAPI,
  exportQueryAPI,
  importQueryAPI,
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

export const getQueryGroupsAction = () => async (dispatch) => {
  dispatch({ type: GET_QUERY_GROUPS });
  try {
    let response = await getQueryGroupsAPI();
    let groups = response.data && response.data.groups;
    let groupsToFilter = response.data && response.data.groupsToFilter;

    dispatch({
      type: GET_QUERY_GROUPS_FULFILLED,
      payload: { groups, groupsToFilter },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_QUERY_GROUPS_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getQueriesAction = (group) => async (dispatch) => {
  dispatch({ type: GET_QUERIES });
  try {
    let response = await getQueriesAPI(`?group=${group}`);
    let queries = response.data && response.data.result;

    dispatch({
      type: GET_QUERIES_FULFILLED,
      payload: {
        queries: queries,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_QUERIES_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getQueryResultAction = (query) => async (dispatch) => {
  dispatch({ type: GET_QUERY_RESULT });
  try {
    let response = await getQueryResultAPI(query);
    let dateFormat = response.data && response.data.date_format;
    let currencyLocalization = response.data && response.data.currency_localization;
    let filters = response.data && response.data.filters;
    let result = response.data && response.data.result;
    let context = response.data && response.data.context;
    let status = response.data && response.data.status;
    let error = response.data && response.data.error;

    if (status !== "OK") {
      dispatch({ type: GET_QUERY_RESULT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedString("errorExecutingQuery"), severity: "error" }));
      return;
    }

    if (error) {
      dispatch(openSnackbarAction({ msg: error, severity: "error", duration: null }));
    }

    dispatch({
      type: GET_QUERY_RESULT_FULFILLED,
      payload: {
        dateFormat,
        currencyLocalization,
        filters,
        context,
        queryResult: result,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_QUERY_RESULT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const exportQueryAction = (query) => async (dispatch) => {
  try {
    const response = await exportQueryAPI(query);
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importQueryAction = (query) => async (dispatch) => {
  try {
    const response = await importQueryAPI(query);
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getTemplatesAction = (code) => async (dispatch) => {
  dispatch({ type: GET_TEMPLATES });
  try {
    let response = await getTemplatesAPI(`?grid=@USER_${code}`);
    dispatch({
      type: GET_TEMPLATES_FULFILLED,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_TEMPLATES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createTemplateAction = (query, rol, nombre, estados) => async (dispatch) => {
  dispatch({ type: CREATE_TEMPLATE_REQUEST });
  try {
    let response = await createTemplateAPI({
      U_funcion: `@USER_${query}`,
      U_template: nombre,
      U_rol: rol,
      U_columnstate: estados.column,
      U_groupstate: estados.group,
      U_filterstate: estados.filter,
      U_pivotstate: estados.pivot,
      U_selstate: estados.showCurrentEmployees,
      U_graphicstate: estados.chart,
    });
    if (response.data && (response.data.status === "OK" || response.data.status !== "ERROR_DUPLICADO")) {
      dispatch({ type: ADD_TEMPLATE, payload: response.data.result });
    }
    dispatch({ type: CREATE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: CREATE_TEMPLATE_REQUEST_END });
  }
};

export const deleteTemplateAction = (code) => async (dispatch) => {
  dispatch({ type: DELETE_TEMPLATE_REQUEST });
  try {
    let response = await deleteTemplateAPI({
      code: code,
    });

    dispatch({ type: DELETE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: DELETE_TEMPLATE_REQUEST_END });
  }
};

export const deleteTemplateLocallyAction = (code) => async (dispatch) => {
  dispatch({ type: REMOVE_TEMPLATE, payload: code });
};

export const updateTemplateAction = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_TEMPLATE_REQUEST });
  try {
    let response = await updateTemplateAPI(data);

    if (response.data && response.data.status === "OK") {
      dispatch({ type: UPDATE_TEMPLATE, payload: data });
    }
    dispatch({ type: UPDATE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: UPDATE_TEMPLATE_REQUEST_END });
  }
};