import React, { useState } from 'react';
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { CloseIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

const FilterPositionDialog = ({
  open,
  handleClose,
  handleSave,
  labels,
}) => {
  const [selectedElements, setSelectedElements] = useState([]);
  const clearList = () => setSelectedElements([]);
  const handleToggleLabel = (item) => {
    let clonedSelectedElements = [...selectedElements];
    const elementIndex = selectedElements.indexOf(item);
    if (elementIndex > -1) {
      clonedSelectedElements.splice(elementIndex, 1);
    } else {
      clonedSelectedElements.push(item);
    }
    setSelectedElements(clonedSelectedElements);
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Resaltar por etiquetas"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <List >
            {labels.map(item => {
              return <ListItem
                key={item.key}
                button
                dense
                onClick={() => handleToggleLabel(item.key)}
              >
                {selectedElements.includes(item.key) ? <CheckBoxIcon className="list-item-icon text-16 whiteText" color="action" /> : <CheckBoxOutlineBlankIcon className="list-item-icon text-16 whiteText" color="action" />}
                <ListItemText className="truncate pl-8 whiteText" primary={item.value} disableTypography={true} />
              </ListItem>
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={clearList} text={"Limpiar"} />
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
          <ButtonDialogAction onClick={() => handleSave(selectedElements)} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default FilterPositionDialog;