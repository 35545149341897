import React from "react";
import { Grid, Typography } from "@material-ui/core";

const PercentageList = (props) => {

    const { wordsArray, countArray, colors, getWordPercentage } = props;

    return (
        <>
            {
                wordsArray.map((word, index) => {
                    return (
                        <Grid container direction='row' alignItems="center" item key={index} style={{ minHeight: 45 }}>
                            <div style={{ height: 25, width: 25, backgroundColor: colors[index] }} />
                            <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600, paddingLeft: 20, paddingRight: 25 }}>
                                {`${getWordPercentage(index, countArray)}%`}
                            </Typography>
                            <Typography className='whiteText' style={{ fontSize: 20, textTransform: 'capitalize' }}>
                                {word}
                            </Typography>
                        </Grid>
                    )
                })
            }
        </>
    );
}

export default PercentageList;
