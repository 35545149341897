import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax3,
	numberFormatterMin0Max3,
	numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorId"),
            field: "ID",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorPositionName"),
            field: "Nombre del cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorPositionQuantity"),
            field: "Cantidad de puestos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorOccupiedPositions"),
            field: "Puestos ocupados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorFreePositions"),
            field: "Puestos libres",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorResponsabilityDegree"),
            field: "Grado de responsabilidad",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorSuperior"),
            field: "Cargo superior",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: getLocalizedString("positionEditorAssistant"),
            field: "Asistente",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: "Potencial motivador",
            field: "Potencial motivador del cargo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: "Fecha actualización del potencial",
            field: "Fecha actualización del potencial",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: "Etiquetas",
            field: "Etiquetas",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: "Grupo de competencias específicas",
            field: "Grupo de competencias específicas",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.positionEditor],
        config: {
            headerName: "Grupo de evaluación",
            field: "Grupo de evaluación",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["positionOccupation"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("positionEditorAssistant"),
            field: "Asistente",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["positionOccupation"],
        config: {
            headerName: getLocalizedString("section"),
            field: "Seccion",
            filter: "agSetColumnFilter",
        }
    },
]