import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD, dateIsBeforeToday } from "@icarius-utils/date";
import * as yup from 'yup';

const useHandleForm = (data, isDuplicate, submitCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'code') {
        return isCreate ? "" : data['Código de proceso'];
      }

      if (fieldName === 'date') {
        const auxDate = isCreate ? new Date() : createDateFromDDMMYYYY(data['Fecha de ejecución']);
        if (dateIsBeforeToday(auxDate)) return new Date();
        return auxDate;
      }

      if (fieldName === 'time') {
        return isCreate ? '' : parseInt(data['Hora de ejecución'].substring(0, 2));
      }

      if (fieldName === 'warning') {
        return isCreate ? false : data['Recibir aviso'];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'date',
      'time',
      'warning',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'code': yup.string().required(),
      'date': yup.string().required(),
      'time': yup.string().required(),
      'warning': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        'date': formatDateYYYYMMDD(formData.date),
      };

      if (!isCreate && !isDuplicate) { // es editar
        dataToSend.internalCode = data.internalCode;
      }

      submitCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
