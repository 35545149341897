import React from "react";
import {
    Grid,
    Divider,
    Typography,
} from "@material-ui/core";

const ParticipationData = (props) => {

    const {
        data,
        color,
        pollsSelectedColors,
        hideLabel,
    } = props;

    if (!data.every((item) => item.availableVoters)) return null;

    return (
        <Grid item xs={12} id="participationData">
            <div className={"slider-container"} style={{ padding: 0 }}>
                <Typography className={"title"}>
                    {'Participación'}
                </Typography>
                <Divider style={{ background: color, height: 2 }} />
            </div>
            <Grid container item alignItems="center" justify="center" direction="column" style={{ padding: 20 }}>
                {
                    !hideLabel &&
                    <Typography className="whiteText" align="center" style={{ marginTop: 20, fontSize: 20, textDecoration: 'underline' }}>
                        {'De acuerdo con el filtro aplicado/publicaciones asociadas:'}
                    </Typography>
                }
                <Grid container item xs={12} justify="center">
                    {
                        data.map((poll, index) => {
                            return (
                                <Grid key={poll.code} container item xs={12} md={6} alignItems="center" justify="center" direction="column" style={{ padding: 20 }}>
                                    {
                                        data?.length > 1 &&
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: 20, height: 20, background: pollsSelectedColors[index], marginRight: 10 }} />
                                            <Typography className="whiteText" align="center" style={{ fontSize: 18 }}>
                                                {poll.name}
                                            </Typography>
                                        </div>
                                    }
                                    <Typography className="whiteText" align="center" style={{ fontSize: 24, fontWeight: 600 }}>
                                        {`Porcentaje de participación de encuesta ${(poll.answersAmount * 100 / poll.availableVoters).toFixed(2)}%`}
                                    </Typography>
                                    <Typography className="whiteText" align="center" style={{ fontSize: 20, fontStyle: 'italic', marginTop: 5 }}>
                                        {`Total de participantes ${poll.answersAmount}, sobre un universo total de ${poll.availableVoters}`}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ParticipationData;