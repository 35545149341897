import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { MD_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";

const SectionContainer = (props) => {

    const {
        title,
        useRightBorder,
        useBigWidth,
        useBigHeight,
        ignoreContainer,
        children,
    } = props;

    const { theme } = useSelector(getTheme);
    const showRightBorder = useMediaQuery(`(min-width:${MD_DEVICE_WIDTH_BREAKPOINT}px)`);

    const titleStyle = {
        fontSize: 30,
        fontWeight: 600,
        padding: 20,
        textShadow: theme === "dark" ? '1px 1px 2px black' : ''
    };

    const containerStyle = {
        width: '100%',
        backgroundColor: "var(--secondaryBackgroundColor)",
        borderTop: '10px solid var(--mainBackgroundColor)',
        borderRight: (showRightBorder && useRightBorder) ? '10px solid var(--mainBackgroundColor)' : "0px",
    };

    return (
        <div style={containerStyle}>
            <div style={{ border: '1px solid var(--headerBorder)' }}>
                {
                    title &&
                    <Typography className='whiteText' align="center" style={titleStyle}>
                        {title}
                    </Typography>
                }
                {
                    !ignoreContainer && children &&
                    <Grid container direction='column' item xs={12} alignItems="center" style={{ paddingBottom: 20 }}>
                        <div
                            style={{
                                height: useBigHeight ? 500 : 225,
                                width: useBigWidth ? '70%' : 350,
                                display: 'flex',
                                flexDirection: 'column',
                                overflowY: 'auto',
                            }}
                        >
                            {children}
                        </div>
                    </Grid>
                }
                {ignoreContainer && children}
            </div>
        </div>
    );
}

export default SectionContainer;
