import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { createPollSettingsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandlePollForm from "../poll/useHandleForm";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const DuplicateDialog = (props) => {

  const { open, pollNames, data, isImport, handleClose } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("pollFieldsRequired"), severity: "error", duration: 10000 }));
  }

  const openNameValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe una encuesta con el nombre ingresado', severity: "error" }));
  }

  const createPoll = (dataToSend) => {
    dispatch(createPollSettingsAction(dataToSend));
  }

  const { formData, setFormValue, submit } = useHandlePollForm(data, createPoll, null, openValidationError, openNameValidationError, null, true, pollNames);

  const handleSave = () => {
    const isValid = submit();
    if (isValid) handleClose();
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isImport ? "Importar encuesta" : getLocalizedString("pollDuplicationTitle")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} direction="column">
          <Typography variant="caption" className="whiteText" style={{ fontSize: "0.9rem" }}>{getLocalizedString("pollDuplicationMessage")}</Typography>
          <Typography variant="caption" className="whiteText" style={{ fontSize: "0.9rem" }}>{getLocalizedString("pollDuplicationMessage2")}</Typography>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={getLocalizedString("pollName")}
              value={formData.pollName}
              onChange={(e) => setFormValue(e.target.value, "pollName")}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          {
            data.useForOnboarding === "N" &&
            <>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* desde */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    required
                    fullWidth
                    label={getLocalizedString("pollFrom")}
                    cancelLabel={'Cancelar'}
                    okLabel={'Aceptar'}
                    invalidDateMessage=''
                    minDateMessage=''
                    maxDateMessage=''
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData.startDate}
                    minDate={new Date()}
                    onChange={(date) => setFormValue(date, "startDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* hasta */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    required
                    fullWidth
                    label={getLocalizedString("pollTo")}
                    cancelLabel={'Cancelar'}
                    okLabel={'Aceptar'}
                    invalidDateMessage=''
                    minDateMessage=''
                    maxDateMessage=''
                    minDate={formData.startDate}
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData.endDate}
                    onChange={(date) => setFormValue(date, "endDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSave} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
