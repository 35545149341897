import React, { useState } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import WordsData from './wordsData';
import GridData from './gridData';
import NPSData from "./npsData";
import paths from "@icarius-localization/paths";

const QuestionData = React.forwardRef((props, ref) => {

    const [count, setCount] = useState(0);

    // estado usado solamente para que se haga rerender en GridData cuando se cierre la grilla de sentimientos, 
    // y se vuelva a setear correctamente su context, sino, al hacer un filtro por ejemplo, intenta aplicarlo en el context de la grilla de sentimientos
    // que es la ultima que se crea
    // es horrible pero es la unica forma de forzarlo sin tener que modificar todos los setCtx par que se guarden en un store general en lugar de una variable sin poder recuperar el valor anterior

    const {
        isOnboarding,
        isNPS,
        data,
        name,
        type,
        sentiments,
        color,
        dateFormat,
        societySelected,
        locale,
        gridTheme,
        country,
        isAnonymous,
    } = props;

    return (
        <Grid item xs={12}>
            <div className={"slider-container"} ref={ref} style={{ padding: 0 }}>
                <Typography className={"title"}>
                    {`Respuestas de "${name}"`}
                </Typography>
                <Divider style={{ background: color, height: 2 }} />
            </div>
            {
                type === 'D' &&
                <WordsData
                    questionData={data}
                    sentiments={sentiments}
                    forceGridDataUpdate={() => setCount(prev => prev + 1)}
                />
            }
            {
                isNPS && type === 'O' &&
                <NPSData data={data} />
            }
            <GridData
                data={data}
                dateFormat={dateFormat}
                societySelected={societySelected}
                locale={locale}
                country={country}
                count={count}
                color={color}
                gridTheme={gridTheme}
                path={isAnonymous ? paths.pollReview : "nonAnonymousPollReview"}
                isNPS={isNPS}
                isOnboarding={isOnboarding}
            />
        </Grid>
    );
})

export default QuestionData;
