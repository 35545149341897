import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPollSettingsAction } from "../actions";
import { getLoading, getPolls, getRoles, getPollTypes, getModes, getProcessList } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { RESET_STATE } from "../actionTypes";
import { getFiltersIsLoading } from "@icarius-common/filtersDialog/selectors";
import { importIcariusFile, exportIcariusFile } from "@icarius-utils/exportImportIcarius";

const usePollSettings = () => {

  const [createPollIsOpen, setCreatePollIsOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState(null);
  const [isImport, setIsImport] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPollSettingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDuplicationDialog = (data) => {
    setDuplicateData(data);
    setIsImport(false);
  }

  const handleCloseDuplicationDialog = () => {
    setDuplicateData(null);
    setIsImport(false);
  }

  const importPoll = () => {
    importIcariusFile(dispatch, (data) => {
      setIsImport(true);
      setDuplicateData(data);
    });
  }

  const exportPoll = (poll) => {
    exportIcariusFile(dispatch, poll, poll.pollName);
  }

  const color = useSelector(getAppColor);
  const roles = useSelector(getRoles);
  const polls = useSelector(getPolls);
  const pollTypes = useSelector(getPollTypes);
  const modes = useSelector(getModes);
  const processList = useSelector(getProcessList);
  const isLoadingLocal = useSelector(getLoading);
  const filtersIsLoading = useSelector(getFiltersIsLoading);
  const isLoading = isLoadingLocal || filtersIsLoading;

  return {
    color,
    roles,
    polls,
    modes,
    processList,
    pollTypes,
    isLoading,
    createPollIsOpen,
    duplicateData,
    isImport,
    setCreatePollIsOpen,
    handleOpenDuplicationDialog,
    handleCloseDuplicationDialog,
    exportPoll,
    importPoll,
  }
}

export default usePollSettings;