import React, { useState } from 'react';
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@material-ui/core";
import NumberFormatCustomNoDecimals from "../inputs/numberFormatCustomNoDecimals";

const INITIAL_POTENTIAL = 1;
const TERMINAL_POTENTIAL = 125;
const gridStyle = { height: 75, padding: "0px 10px" };

const FilterPositionDialog = ({
  open,
  handleClose,
  handleSave,
}) => {
  const [initialPotential, setInitialPotential] = useState(INITIAL_POTENTIAL);
  const [terminalPotential, setTerminalPotential] = useState(TERMINAL_POTENTIAL);
  const clearState = () => {
    setInitialPotential(INITIAL_POTENTIAL);
    setTerminalPotential(TERMINAL_POTENTIAL)
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Resaltar potencial motivador"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* initial */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Potencial desde'}
                value={initialPotential}
                onChange={(e) => setInitialPotential(e.target.value)}
                InputProps={{ inputComponent: NumberFormatCustomNoDecimals, min: "1", step: "0.1" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* terminal */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Potencial hasta'}
                value={terminalPotential}
                onChange={(e) => setTerminalPotential(e.target.value)}
                InputProps={{ inputComponent: NumberFormatCustomNoDecimals, min: "1", step: "0.1" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={clearState} text={"Limpiar"} />
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
          <ButtonDialogAction onClick={() => handleSave(Number(initialPotential), Number(terminalPotential))} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default FilterPositionDialog;