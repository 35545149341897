import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, Typography } from "@material-ui/core";
import FabButton from "@icarius-common/fabButton";

const NoPublications = ({ onClick }) => {

    return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 18, fontWeight: 700, marginBottom: 16 }}>
                {getLocalizedString("noPublications")}
            </Typography>
            <Typography color="textSecondary" className="whiteText" variant="h3" style={{ fontSize: 16, fontWeight: 400, width: 270, textAlign: "center" }}>
                {getLocalizedString("publicationExplanation")}
            </Typography>
            <div style={{ marginTop: 30 }}>
                <FabButton
                    ignoreIcon
                    useColorBackground
                    useWhiteTextAlways
                    onClick={onClick}
                    title={getLocalizedString("newPublication")}
                />
            </div>
        </Grid>
    )
}

export default NoPublications;