import React from 'react';

export default class Renderer extends React.Component {

    getReactContainerClasses() {
        return ['div-outer-div'];
    }

    render() {
        let textColor = 'var(--mainText)';

        if (this.props.data.isExpired) {
                textColor = "#D50000";
        }

        return (
            <div className="div-percent-bar" style={{ width: "100%", color: textColor }}>
                <div className="div-percent-value">{this.props.value}</div>
            </div>
        );
    }
}