import NAME from "./constants";

export const GET_ORGANIZATION_CHART_DATA = NAME + "/GET_ORGANIZATION_CHART_DATA";
export const GET_ORGANIZATION_CHART_DATA_FULFILLED = NAME + "/GET_ORGANIZATION_CHART_DATA_FULFILLED";
export const GET_ORGANIZATION_CHART_DATA_REJECTED = NAME + "/GET_ORGANIZATION_CHART_DATA_REJECTED";
export const GET_CLIENT_BRANCHES_DATA = NAME + "/GET_CLIENT_BRANCHES_DATA"
export const GET_CLIENT_BRANCHES_DATA_FULFILLED = NAME + "/GET_CLIENT_BRANCHES_DATA_FULFILLED"
export const GET_CLIENT_BRANCHES_DATA_REJECTED = NAME + "/GET_CLIENT_BRANCHES_DATA_REJECTED"
export const SAVE_ORGANIZATION_CHART_DATA = NAME + "/SAVE_ORGANIZATION_CHART_DATA";
export const SAVE_ORGANIZATION_CHART_DATA_FULFILLED = NAME + "/SAVE_ORGANIZATION_CHART_DATA_FULFILLED";
export const SAVE_ORGANIZATION_CHART_DATA_REJECTED = NAME + "/SAVE_ORGANIZATION_CHART_DATA_REJECTED";
export const GET_POSITION_OCCUPATION_DATA = NAME + "/GET_POSITION_OCCUPATION_DATA";
export const GET_POSITION_OCCUPATION_DATA_FULFILLED = NAME + "/GET_POSITION_OCCUPATION_DATA_FULFILLED";
export const GET_POSITION_OCCUPATION_DATA_REJECTED = NAME + "/GET_POSITION_OCCUPATION_DATA_REJECTED";