import { getSocietySelected } from "@icarius-pages/login/selectors";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getIsExportingGrid as getExportingGrid } from "src/app/selectors";
import {
    getPollReviewQuestionsAction,
    getPollReviewAnswersAction,
    getPollReviewAction,
} from "../actions";
import {
    getQuestions,
    getDateFormat,
    getAnswers,
    getIsNPS,
    getIsAnonymous,
    getLocale,
    getData,
    getIsLoading,
} from "../selectors";

const usePollReview = () => {

    const [showOnboarding, setShowOnboarding] = useState(false);
    const [pollSelected, setPollSelected] = useState(null);
    const [questionName, setQuestionName] = useState(null);
    const [questionType, setQuestionType] = useState(null);
    const [sentiments, setSentiments] = useState(null);
    const divRef = useRef();

    const dispatch = useDispatch();
    const isExportingGrid = useSelector(getExportingGrid);
    const isLoadingAnalytics = useSelector(getIsLoading);
    const polls = useSelector(getData);
    const questions = useSelector(getQuestions);
    const answers = useSelector(getAnswers);
    const isNPS = useSelector(getIsNPS);
    const isAnonymous = useSelector(getIsAnonymous);
    const societySelected = useSelector(getSocietySelected);
    const dateFormat = useSelector(getDateFormat);
    const locale = useSelector(getLocale);
    const country = useSelector(getCountry);
    const years = (polls || [])?.map(item => item.year);

    const isLoading = (isLoadingAnalytics || isExportingGrid);

    useEffect(() => {
        dispatch(getPollReviewAction(showOnboarding));
    }, [dispatch, showOnboarding])

    const handleClearPollSelected = () => {
        setPollSelected(null);
    };

    const handleSelectPoll = (newPoll) => {
        if (pollSelected?.code !== newPoll.code) {
            setPollSelected(newPoll);

            dispatch(getPollReviewQuestionsAction(newPoll.code))
                .then((resp) => {
                    if (resp.status === 200) {
                        const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
                        const element = document.getElementById('participationData');
                        const posi = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                        setTimeout(() => {
                            window.scrollTo({ top: posi, behavior: 'smooth' });
                        }, 200);
                    }
                })
        } else {
            handleClearPollSelected();
        }
    };

    const handleSelectQuestion = (questionData) => {
        setQuestionName(questionData.description);
        setQuestionType(questionData.type);
        setSentiments(questionData.sentiments);

        dispatch(getPollReviewAnswersAction(pollSelected.code, questionData.code))
            .then((resp) => {
                if (resp.status === 200) {
                    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
                    const element = divRef.current;
                    const posi = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    setTimeout(() => {
                        window.scrollTo({ top: posi, behavior: 'smooth' });
                    }, 200);
                }
            })
    };

    const handleSwitchOnboarding = () => {
        handleClearPollSelected();
        setShowOnboarding(prev => !prev);
    };

    const state = {
        pollSelected,
        questionName,
        questionType,
        sentiments,
        showOnboarding,
    };

    const handlers = {
        handleClearPollSelected,
        handleSelectPoll,
        handleSelectQuestion,
        handleSwitchOnboarding,
    };

    return {
        divRef,
        polls,
        years,
        questions,
        answers,
        isNPS,
        isAnonymous,
        societySelected,
        dateFormat,
        locale,
        country,
        isLoading,
        state,
        handlers,
    }
}

export default usePollReview;
