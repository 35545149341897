import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  answers: [],
  questions: [],
  isNPS: false,
  isAnonymous: false,
  isLoading: false,
  locale: "es",
  dateFormat: "yyyy/mm/dd",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_POLLS:
      return {
        ...state,
        isLoading: true,
        data: [],
        questions: [],
        answers: [],
      };
    case actionTypes.GET_POLLS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case actionTypes.GET_POLLS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_QUESTIONS:
      return {
        ...state,
        isLoading: true,
        questions: [],
        answers: [],
      };
    case actionTypes.GET_QUESTIONS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        questions: action.payload.data,
        isNPS: action.payload.isNPS,
        isAnonymous: action.payload.isAnonymous,
      };
    case actionTypes.GET_QUESTIONS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_ANSWERS:
      return {
        ...state,
        isLoading: true,
        answers: [],
      };
    case actionTypes.GET_ANSWERS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        answers: action.payload.data,
        dateFormat: action.payload.date_format,
      };
    case actionTypes.GET_ANSWERS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
