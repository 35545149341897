import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { applyGeographicalDivision } from "@icarius-table/utils";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import CommonPage from "@icarius-common/commonPage";
import { getGeographicalDivisions } from "src/app/selectors";

const GridData = (props) => {

    const {
        data,
        dateFormat,
        locale,
        path,
        isNPS,
        isOnboarding,
    } = props;

    const geographicalDivisions = useSelector(getGeographicalDivisions);

    const ownColumnDef = useMemo(() => {
        const auxOwnColumnDef = applyGeographicalDivision(getColumnDefByPage(path), geographicalDivisions);
        const answerColumn = auxOwnColumnDef.find((item) => item.field === 'RESPUESTA');
        answerColumn.headerName = isNPS ? 'NPS' : 'Respuesta';

        if (isOnboarding) {
            const onboardingProcessColumn = {
                headerName: "Proceso de onboarding",
                field: "PROCESO DE ONBOARDING",
                filter: "agSetColumnFilter",
            };
            auxOwnColumnDef.splice(2, 0, onboardingProcessColumn);
        }

        return auxOwnColumnDef;
    }, [isNPS, isOnboarding, path, geographicalDivisions])

    return (
        <div style={{ marginBottom: 70 }}>
            <CommonPage
                gridTitle={getLocalizedString("pollReview")}
                ownColumnDef={ownColumnDef}
                rowData={data}
                dateFormat={dateFormat}
                locale={locale}
                gridHeight={500}
                hasSelectAll
                hasExpand
            />
        </div>
    );
}

export default GridData;
