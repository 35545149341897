import React, { useEffect } from "react"
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getIsAdmin } from "src/app/selectors";

const styles = () => ({
  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },
  cssFocused: {
    color: "var(--mainText) !important",
  },
  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});;

const ContextView = ({ data, classes }) => {

  const isAdmin = useSelector(getIsAdmin);

  useEffect(() => {
    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
    const element = document.getElementById('contextResult');
    const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: posi, behavior: 'smooth' });
  }, [])

  return (
    <div id="contextResult">
      {
        isAdmin && data.result &&
        <div style={{ margin: '20px 0px 10px' }}>
          <TextField
            disabled
            fullWidth
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            label={'Resultado del SQL del contexto'}
            value={JSON.stringify(data.result)}
            InputLabelProps={{
              classes: {
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
          />
        </div>
      }
      {
        data.comment &&
        <div style={{ margin: '20px 0px 10px' }}>
          <TextField
            disabled
            fullWidth
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            label={'Comentarios del contexto'}
            value={data.comment}
            InputLabelProps={{
              classes: {
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
          />
        </div>
      }
    </div>
  );
}

export default withStyles(styles)(ContextView);