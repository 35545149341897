import React from "react";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getTheme } from "@icarius-pages/login/selectors";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonPage from "@icarius-common/commonPage";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import SetParamDialog from "@icarius-common/setParamDialog";
import { shadeHexColor } from "@icarius-utils/colors";
import PollSelection from "./pollSelection";
import QuestionSlider from "./questionSlider";
import QuestionData from "./questionData/questionData";
import DownloadPDFButton from "./downloadPDFButton";
import useProcessExecution from "./useProcessExecution";
import ParticipationData from "./participationData";
import usePollReview from "./usePollReview";

const PollReview = () => {

  const {
    divRef,
    years,
    polls,
    questions,
    answers,
    isNPS,
    isAnonymous,
    societySelected,
    dateFormat,
    locale,
    country,
    isLoading,
    state,
    handlers,
  } = usePollReview();

  const {
    processExecutionData,
    processExecutionFunctions,
  } = useProcessExecution(state.pollSelected?.process);

  const gridTheme = useSelector(getTheme);
  const color = useSelector(getAppColor);

  const history = useHistory();

  const helpItems = [
    <MenuItemWithIcon
      key={"1"}
      value={"https://www.youtube.com/embed/gvm4lL3KZlo"}
      text={getLocalizedString("pollSliderTitle")}
      type={"video"}
    />
  ]

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString('goBack')}
      onClick={() => history.push(paths.pollScreenSelector)}
      type={'goBack'}
    />
  )

  const executeButton = () => Boolean(state.pollSelected?.process) && (
    <CustomIconButton
      title={`Ejecutar proceso: ${state.pollSelected.process.value}`}
      onClick={() => processExecutionFunctions.handleValidateAndAskProcess(state.pollSelected.code)}
      type={"execute"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={getLocalizedString("pollReview")}
      menuItems={[goBackButton, executeButton]}
      helpItems={helpItems}
      isNotGridPage
      hasHelp
      hasSwitch
      handleSwitchClick={handlers.handleSwitchOnboarding}
      switchValue={state.showOnboarding}
      switchLabelOn={'Ver encuestas de onboarding'}
      switchLabelOff={'Ver encuestas de onboarding'}
    >
      {
        !isLoading && !Boolean(polls?.length) &&
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {getLocalizedString("noPollReviewData")}
          </Typography>
        </Grid>
      }
      {
        Boolean(state.pollSelected) && !isLoading &&
        <DownloadPDFButton code={state.pollSelected.code} />
      }
      {
        Boolean(polls.length) &&
        <>
          <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content" style={{ overflow: 'initial' }}>
            <PollSelection
              years={years}
              data={polls}
              color={color}
              pollsSelected={state.pollSelected ? [state.pollSelected] : []}
              pollsSelectedColors={[shadeHexColor(color, 0.3)]}
              handlePollClick={handlers.handleSelectPoll}
              handleYearClick={handlers.handleClearPollSelected}
            />
          </Grid>
          {
            Boolean(state.pollSelected) &&
            <ParticipationData
              data={[state.pollSelected]}
              color={color}
              hideLabel={state.showOnboarding}
            />
          }
          {
            Boolean(state.pollSelected) && Boolean(questions.length) &&
            <QuestionSlider
              key={state.pollSelected.code}
              data={questions}
              color={color}
              colors={[color]}
              theme={gridTheme.theme}
              handleQuestionClick={handlers.handleSelectQuestion}
            />
          }
          {
            Boolean(state.pollSelected) && Boolean(answers.length) &&
            <QuestionData
              isOnboarding={state.showOnboarding}
              isNPS={isNPS}
              isAnonymous={isAnonymous}
              data={answers}
              type={state.questionType}
              name={state.questionName}
              sentiments={state.sentiments}
              dateFormat={dateFormat}
              societySelected={societySelected}
              locale={locale}
              country={country}
              gridTheme={gridTheme}
              color={color}
              ref={divRef}
            />
          }
        </>
      }
      {
        processExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={processExecutionData.paramDialogIsOpen}
          params={processExecutionData.paramsToUse}
          handleClose={processExecutionFunctions.handleCloseParamDialog}
          handleAgree={processExecutionFunctions.executeProcess}
        />
      }
    </CommonPage>
  );
}

export default PollReview;
