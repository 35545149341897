import * as actionTypes from "./actionTypes";
import {
  getPublicationSettingsAPI,
  deletePublicationSettingsAPI,
  createPublicationSettingsAPI,
  modifyPublicationSettingsAPI,
  verifyURLHasImageAPI,
  orderPublicationSettingsAPI
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_IMAGE_TYPE":
    case "INVALID_FILE":
    case "ERROR_FILE_EXTENSION":
      errorString = getLocalizedString("invalidFile");
      break;
    case "IMAGE_SIZE_EXCEEDED":
      errorString = getLocalizedString("imageSizeExceeded");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getPublicationSettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getPublicationSettingsAPI();

    let publications = response.data && response.data.data;
    let publicationTypes = response.data && response.data.types;
    let societyPeople = response.data && response.data.societyPeople;
    let roles = response.data && response.data.roles;
    let dateFormat = response.data && response.data.date_format;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { publications, publicationTypes, societyPeople, roles, dateFormat },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deletePublicationSettingsAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deletePublicationSettingsAPI({ code });

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createPublicationSettingsAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createPublicationSettingsAPI(publication);

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyPublicationSettingsAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyPublicationSettingsAPI(publication);

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationModifiedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getURLHasImageAction = (link) => async (dispatch) => {
  dispatch({ type: actionTypes.CHECK_URL });
  try {
    let response = await verifyURLHasImageAPI({ link });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CHECK_URL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CHECK_URL_FULFILLED,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CHECK_URL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const orderPublicationSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.ORDER });
  try {
    let response = await orderPublicationSettingsAPI(data);

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ORDER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ORDER_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: 'Orden guardado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ORDER_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};