import { useState } from "react";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";
import { TYPES_USES_LINK_AND_DOWNLOAD, TYPES_USES_NUMBER, TYPES_USES_ONLY_DOWNLOAD, TYPES_USES_ONLY_LINK } from "../../constants";
import { getFileName, getFileExtension, makeFile } from "@icarius-utils/fileUpload";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getURLHasImageAction } from "../../actions";

const useHandlePublicationForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  //ver si se setean bien los datos en el modify

  const [name, setName] = useState(isCreate ? "" : data.name);
  const [text, setText] = useState(isCreate ? "" : data.text);
  const [type, setType] = useState(isCreate ? "" : data.type);
  const [role, setRole] = useState(isCreate ? "" : data.visualizationRole);
  const [employeeCode, setEmployeeCode] = useState(isCreate ? "" : data.employeeCode);
  const [startDate, setStartDate] = useState(isCreate ? null : createDateFromDDMMYYYY(data.startDate));
  const [endDate, setEndDate] = useState(isCreate ? null : createDateFromDDMMYYYY(data.endDate));
  const [number, setNumber] = useState(isCreate ? "" : (data.number || ""));
  const [link, setLink] = useState(isCreate ? "" : (data.link || ""));
  const [download, setDownload] = useState(isCreate ? "" : (data.download || ""));
  const [fileName, setFileName] = useState(isCreate ? "" : (data.download || ""));
  const [image, setImage] = useState(isCreate ? "" : (data.image || ""));
  const [needsImage, setNeedsImage] = useState(false); //para la verificacion de preview para tipo Enlace
  const [hasConfetti, setHasConfetti] = useState(isCreate ? false : Boolean(data.confetti === 'Y'));

  const datesAreValid = (dateStart, dateEnd) => {
    const newStartDate = dateStart.setHours(0, 0, 0, 0).valueOf();
    const newEndDate = dateEnd.setHours(0, 0, 0, 0).valueOf();

    return newStartDate <= newEndDate;
  }

  const dataIsValid = async () => {

    let isValid = true;

    //validaciones en comun
    if (!name || name === "") isValid = false;
    if (!text || text === "") isValid = false;
    if (!type || type === "") isValid = false;
    if (!role || role === "") isValid = false;
    if (!startDate) isValid = false;
    if (!endDate) isValid = false;
    if (startDate && endDate && !datesAreValid(startDate, endDate)) isValid = false;

    if (type === "B" && (!image || image === "")) isValid = false; //si es NOTICIA DESTACADA, que tenga imagen

    //si tiene link y descarga dispoonibles, que no use ambos a la vez
    if (TYPES_USES_LINK_AND_DOWNLOAD.includes(type) && link && download) isValid = false;

    if (TYPES_USES_NUMBER.includes(type) && (!number || number === "")) isValid = false; //si es tipo NUMERO, que tenga number
    if (TYPES_USES_ONLY_DOWNLOAD.includes(type) && (!download || download === "")) isValid = false; //si tiene solo descarga, que download este
    if (TYPES_USES_ONLY_LINK.includes(type)) { //validaciones de Enlace (tipo U)
      if (link && link !== "") { //si tiene link, validar su preview
        const urlIsValid = await dispatch(getURLHasImageAction(link))
          .then(resp => {
            const urlHasImage = resp.data.hasImage;
            if (!urlHasImage && (!image || image === "")) {
              setNeedsImage(true);
              return false;
            }
            return true;
          });

        if (!urlIsValid) {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }

    return isValid;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const dataToSend = {
        name: name,
        text: text,
        startDate: formatDateYYYYMMDD(startDate),
        endDate: formatDateYYYYMMDD(endDate),
        visualizationRole: role,
        type: type,
        image: image,
        confetti: hasConfetti ? "Y" : "N",
        employeeCode: employeeCode,
      }

      if (TYPES_USES_NUMBER.includes(type)) {
        dataToSend.number = number;
      }

      if ([...TYPES_USES_LINK_AND_DOWNLOAD, ...TYPES_USES_ONLY_LINK].includes(type)) {
        dataToSend.link = link;
      }

      if ([...TYPES_USES_LINK_AND_DOWNLOAD, ...TYPES_USES_ONLY_DOWNLOAD].includes(type)) {
        if (!isCreate && download === data.download) { //si es EDITAR, y el archivo es el original, mandar null
          dataToSend.download = data.download; //mandar el original
          dataToSend.file = null;
          dataToSend.fileName = null;
          dataToSend.fileExtension = null;
        } else { //no es editar, o es editar pero cambio el archivo
          dataToSend.file = download;
          dataToSend.fileName = getFileName(fileName);
          dataToSend.fileExtension = getFileExtension(fileName);
        }
      }

      if (!isCreate) {
        dataToSend.code = data.code;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const handleChangeStartDate = (newStartDate) => {
    //si la nueva fecha inicio es mayor a la fecha fin, borrar la fecha fin
    setStartDate(newStartDate);
    if (endDate && !datesAreValid(newStartDate, endDate)) {
      setEndDate(null);
    }
  }

  const handleFile = (e) => {
    if (!e?.target?.files[0]) {
      setDownload("");
      setFileName("");
      return;
    }

    let originalFile = e.target.files[0];
    let fileExtension = getFileExtension(originalFile.name);

    if (originalFile.size > 5 * 1024 * 1024) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("mailInvalidFileSize"), severity: "error" }));
      return;
    }

    const EXT_FILE_VALIDAS = ['jpg', 'bmp', 'png', 'jpeg', 'rar', 'zip', 'gz', 'tar', 'xls', 'xlsx', 'txt', 'rtf', 'doc', 'docx', 'pdf', 'html'];

    if (!EXT_FILE_VALIDAS.includes(fileExtension)) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("publicationInvalidFileType"), severity: "error" }));
      return;
    }

    makeFile(originalFile, (base64) => {
      setDownload(base64);
    })

    setFileName(originalFile.name);
  }

  const setFormValue = (value, fieldName) => {
    switch (fieldName) {
      case getLocalizedString("publicationName"):
        setName(value);
        break;
      case getLocalizedString("publicationFrom"):
        handleChangeStartDate(value);
        break;
      case getLocalizedString("publicationTo"):
        setEndDate(value);
        break;
      case getLocalizedString("publicationType"):
        setType(value);
        handleFile();
        setLink("");
        setNeedsImage(false);
        break;
      case getLocalizedString("publicationRole"):
        setRole(value);
        break;
      case getLocalizedString("publicationText"):
        setText(value);
        break;
      case getLocalizedString("publicationNumber"):
        setNumber(value);
        break;
      case getLocalizedString("publicationLink"):
        setLink(value);
        setNeedsImage(false);
        break;
      case getLocalizedString("publicationDownload"):
        handleFile(value);
        break;
      case getLocalizedString("publicationImage"):
        setImage(value);
        break;
      case 'hasConfetti':
        setHasConfetti(value);
        break;
      case 'employeeCode':
        setEmployeeCode(value);
        break;
      default: break;
    }
  }

  const formData = { name, text, type, role, startDate, endDate, number, link, download, fileName, image, needsImage, hasConfetti, employeeCode };

  return { isCreate, formData, setFormValue, submit };
}

export default useHandlePublicationForm;
