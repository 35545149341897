import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
	numberComparatorMax3,
	numberFormatterMin0Max3,
	numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("answer"),
            field: "RESPUESTA",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("votos"),
            field: "VOTOS",
        }
    },
    {
        pages: ["nonAnonymousPollReview"],
        config: {
            headerName: "Código de empleado",
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["nonAnonymousPollReview"],
        config: {
            headerName: "Apellido y nombres",
            field: "APELLIDO Y NOMBRES",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["nonAnonymousPollReview"],
        config: {
            headerName: "Cargo",
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("function"),
            field: "FUNCION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: "Centro de costo de gestión",
            field: "CENTRO DE COSTO DE GESTION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },

    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["nonAnonymousPollReview"],
        config: {
            headerName: "Obra o Faena",
            field: "OBRA O FAENA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("category"),
            field: "CATEGORIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["nonAnonymousPollReview"],
        config: {
            headerName: "Especialidad",
            field: "Especialidad",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("sex"),
            field: "SEXO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: "Edad",
            field: "EDAD",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("civilState"),
            field: "ESTADO CIVIL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },

    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "COMUNA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("region"),
            field: "REGION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "SINDICATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config:
        {
            headerName: getLocalizedString("shift"),
            field: "TURNO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.pollReview, "nonAnonymousPollReview"],
        config: {
            headerName: getLocalizedString("contratista"),
            field: "CONTRATISTA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        },
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("answer"),
            field: "Respuesta",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.pollReviewSentiments],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
]