import React, { useMemo } from "react";
import { Doughnut } from 'react-chartjs-2';
import { pollReviewColors, pollReviewTop5Colors } from "@icarius-utils/colors"
import { Grid } from "@material-ui/core";
import PercentageList from './percentageList';
import AmountList from './amountList';
import SectionContainer from '../sectionContainer';

const DoughnutChart = (props) => {

    const { words, isTopFive } = props;

    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const getSubarray = (dataArray) => {
        if (isTopFive) {
            return dataArray.slice(0, 5);
        }
        return dataArray.slice(5, 35);
    }

    const getWordPercentage = (index, countArray) => {
        const total = countArray.reduce((prev, current) => prev + current, 0)
        return (countArray[index] * 100 / total).toFixed(2);
    }

    const chartOthersColors = useMemo(() => shuffleArray(pollReviewColors), []);
    const colors = isTopFive ? pollReviewTop5Colors : chartOthersColors;

    const chartOptions = {
        legend: {
            display: false,
        },
    }

    const wordsArray = getSubarray(words.map(i => i.text));
    const countArray = getSubarray(words.map(i => i.value));

    const chartData = {
        labels: wordsArray,
        datasets: [
            {
                data: countArray,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    }

    return (
        <Grid container direction='row' item xs={12} md={6} alignItems="center">
            <SectionContainer useRightBorder={isTopFive} title={isTopFive ? 'Top 5' : 'Otros'}>
                <div style={{ width: 200, height: 200, margin: '0 auto' }}>
                    <Doughnut data={chartData} options={chartOptions} width={200} height={200} />
                </div>
            </SectionContainer>
            <SectionContainer title={'Porcentajes'} useRightBorder={isTopFive}>
                <PercentageList
                    wordsArray={wordsArray}
                    countArray={countArray}
                    colors={colors}
                    getWordPercentage={getWordPercentage}
                />
            </SectionContainer>
            <SectionContainer title={'Cantidades'} useRightBorder={isTopFive}>
                <AmountList
                    wordsArray={wordsArray}
                    countArray={countArray}
                    colors={colors}
                    getWordPercentage={getWordPercentage}
                />
            </SectionContainer>
        </Grid>
    );
}

export default DoughnutChart;
