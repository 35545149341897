import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CommonPage from "@icarius-common/commonPage";
import OwnOrgChart from './orgChart';
import { getAppColor } from "src/app/selectors";
import {
  getOrgChart,
  getCompetencyGroupList,
  getEvaluationGroupList,
  getBranches,
  getLoading,
  getPositionClassifications,
  getPositionOccupationData,
} from "../selectors";
import {
  getOrgChartAction,
  getClientBranchesAction,
  saveOrgChartAction,
  getPositionOccupation,
  recomposePositionsAction,
} from "../actions";
import { getTheme } from "@icarius-pages/login/selectors";
import StatisticsGridDialog from "./dialogs/statisticsGridDialog";
import ConfirmActionDialog from "./dialogs/confirmActionDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DetailsEditDialog from './dialogs/detailsEditDialog';
import DialogBranches from "./dialogs/dialogBranches";
import FilterPositionDialog from './dialogs/filterPositionDialog';
import CompetenceGroupDialog from './dialogs/competenceGroupDialog';
import EvaluationGroupDialog from './dialogs/evaluationGroupDialog';
import PotentialPositionDialog from './dialogs/potentialPositionDialog';
import moment from "moment";

const PositionEditor = () => {

  const dispatch = useDispatch();
  const chartRef = useRef();

  const [branch, setBranch] = useState("");
  const [nodesToUse, setNodesToUse] = useState(null);
  const [nodeAwaitingDelete, setNodeAwaitingDelete] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [branchDialogIsOpen, setBranchDialogIsOpen] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedCompetenceGroupElements, setSelectedCompetenceGroupElements] = useState([]);
  const [selectedEvaluationGroupElements, setSelectedEvaluationGroupElements] = useState([]);
  const [potential, setPotential] = useState({ initial: 1, terminal: 125 });
  const [filterPositionDialogIsOpen, setFilterPositionDialogIsOpen] = useState(false);
  const [competenceGroupDialogIsOpen, setCompetenceGroupDialogIsOpen] = useState(false);
  const [evaluationGroupDialogIsOpen, setEvaluationGroupDialogIsOpen] = useState(false);
  const [potentialDialogIsOpen, setPotentialDialogIsOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [statsDialogIsOpen, setStatsDialogIsOpen] = useState(false);
  const [title, setTitle] = useState("Estructura de cargos");
  const [positionOccupation, setPositionOccupation] = useState(null);

  const orgChart = useSelector(getOrgChart);
  const competencyGroupList = useSelector(getCompetencyGroupList);
  const evaluationGroupList = useSelector(getEvaluationGroupList);
  const branches = useSelector(getBranches);
  const color = useSelector(getAppColor);
  const gridTheme = useSelector(getTheme);
  const loading = useSelector(getLoading);
  const positionClassifications = useSelector(getPositionClassifications);
  const positionOccupationData = useSelector(getPositionOccupationData);

  useEffect(() => {
    dispatch(getOrgChartAction(branch));
  }, [dispatch, branch])

  useEffect(() => {
    setNodesToUse(orgChart.map(member => {
      let data = {
        id: member["ID"],
        pid: member["ID del cargo superior"],
        "ID del cargo": member["ID"],
        "ID del cargo superior": member["ID del cargo superior"],
        "Nombre del cargo": member["Nombre del cargo"],
        "Cantidad de puestos": member["Cantidad de puestos"],
        "Puestos ocupados": member["Puestos ocupados"],
        "Puestos libres": member["Puestos libres"],
        "Grado de responsabilidad": member["Grado de responsabilidad"],
        "Potencial motivador del cargo": member["Potencial motivador del cargo"],
        "Fecha actualización del potencial": member["Fecha actualización del potencial"],
        "Etiquetas": member["Etiquetas"],
        "Grupo de competencias específicas": member["Grupo de competencias específicas"],
        "Grupo de evaluación": member["Grupo de evaluación"],
        resume: `${member["Cantidad de puestos"]}/${member["Puestos ocupados"]}/${member["Puestos libres"]}`
      }

      if (member["Asistente"] === "Y") {
        data.tags = ["assistant"]
      }

      return data;
    }))
  }, [orgChart])

  useEffect(() => {
    if (positionOccupation !== null) {
      dispatch(getPositionOccupation(positionOccupation.id))
        .then(result => result.data.status === "OK" && setStatsDialogIsOpen(true));
    }
  }, [dispatch, positionOccupation])

  useEffect(() => {
    dispatch(getClientBranchesAction());
  }, [dispatch])

  useEffect(() => {
    if (!dialogIsOpen) {
      if (branch) {
        const foundBranch = branches.find(b => b.code === branch);
        if (foundBranch) {
          setTitle(`Estructura de cargos: ${foundBranch["name"]}`)
        }
      } else {
        setTitle(`Estructura de cargos`)
      }
    } else {
      setTitle(`Estructura de cargos`)
    }
  }, [dialogIsOpen, branch, branches])

  useEffect(() => {
    if (branches.length > 1) {
      setBranchDialogIsOpen(true)
    } else if (branches.length === 1) {
      setBranch(branches[0].code)
    }
  }, [branches])

  const highlightPositions = (elements) => {
    setSelectedElements(elements);
    setFilterPositionDialogIsOpen(false);
  }

  const highlightCompetenceGroups = (elements) => {
    setSelectedCompetenceGroupElements(elements);
    setCompetenceGroupDialogIsOpen(false);
  }
  const highlightEvaluationGroups = (elements) => {
    setSelectedEvaluationGroupElements(elements);
    setEvaluationGroupDialogIsOpen(false);
  }

  const highlightPotential = (initial, terminal) => {
    setPotential({ initial, terminal });
    setPotentialDialogIsOpen(false);
  }


  const saveChart = () => {
    // Tengo que agarrar los nodos y convertirlos a algo que entienda el server
    let result = [];

    chartRef.current.config.nodes.map(member => {
      const superiorPosition = member.pid;
      if (superiorPosition && superiorPosition.startsWith("_")) {
        // Tengo que recuperar el ID real
        const parent = chartRef.current.config.nodes.find(item => item.id === superiorPosition);
        if (parent && parent["ID del cargo"]) {
          member["ID del cargo superior"] = parent["ID del cargo"];
        }
      }

      let data = {
        "ID": member["ID del cargo"],
        "ID del cargo superior": member["ID del cargo superior"] || member["pid"],
        "Nombre del cargo": member["Nombre del cargo"],
        "Cantidad de puestos": member["Cantidad de puestos"],
        "Puestos ocupados": member["Puestos ocupados"],
        "Puestos libres": member["Puestos libres"],
        "Grado de responsabilidad": member["Grado de responsabilidad"] || "-",
        "Potencial motivador del cargo": member["Potencial motivador del cargo"] || 1,
        "Fecha actualización del potencial": typeof member["Fecha actualización del potencial"] === "string" ? member["Fecha actualización del potencial"] : moment(member["Fecha actualización del potencial"]).format("DD/MM/YYYY"),
        "Asistente": member.hasOwnProperty("tags") ? member.tags.includes("assistant") ? "Y" : "N" : "N",
        "Etiquetas": member["Etiquetas"],
        "Grupo de competencias específicas": member["Grupo de competencias específicas"],
        "Grupo de evaluación": member["Grupo de evaluación"],
      }

      if (member.hasOwnProperty("tags") && member.tags.includes("assistant")) {
        member["Asistente"] = "Y"
      } else {
        member["Asistente"] = "N"
      }

      return result.push(data)
    })

    dispatch(saveOrgChartAction(branch, result));
  }

  const isNumeric = (str) => {
    if (str === 0) return true
    if (str === "0") return true
    if (typeof str === "number") return true
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const handleChangeField = (key, text) => {
    if (key === "Cantidad de puestos") {
      //Limito el input!
      if (text.length > 4) {
        return;
      }
    }

    if (key === "Cantidad de puestos" && !isNumeric(text)) {
      showSnackbarError("Ingrese un valor numérico válido.")
      return;
    }


    if (key === "Potencial motivador del cargo" && !isNumeric(text)) {
      showSnackbarError("Ingrese un valor numérico entre 1 y 125.")
      return;
    }

    if (key === "Potencial motivador del cargo" && (text < 1 || text > 125)) {
      showSnackbarError("El potencial motivador del cargo puede ir de 1 a 125.")
      return;
    }


    let modifiedNode = JSON.parse(JSON.stringify(currentNode));
    if (key === "Asistente") {
      if (modifiedNode.hasOwnProperty("tags")) {
        if (text) {
          modifiedNode.tags.push("assistant")
        } else {
          delete modifiedNode.tags;
        }
      } else if (text) {
        modifiedNode.tags = ["assistant"]
      }
    } else {
      modifiedNode[key] = text;
    }

    if (key === "Cantidad de puestos") {
      modifiedNode["Puestos libres"] = parseInt(text) - (parseInt(currentNode["Puestos ocupados"]) || 0);

      if (!currentNode["Puestos ocupados"] && currentNode["Puestos ocupados"] !== 0) {
        modifiedNode["Puestos ocupados"] = 0;

      }
    }

    if (key === "Grupo de competencias específicas") {
      modifiedNode["Grupo de competencias específicas"] = text;
    }


    if (key === "Grupo de evaluación") {
      modifiedNode["Grupo de evaluación"] = text;
    }

    if (key === "Etiquetas") {
      modifiedNode["Etiquetas"] = text;
    }

    if (key === "Potencial motivador del cargo") {
      modifiedNode["Fecha actualización del potencial"] = moment();
    }

    setCurrentNode(modifiedNode)
  }

  const closeConfirmActionDialog = () => {
    setConfirmDialogIsOpen(false)
  }

  const setChartRef = (chart) => {
    chartRef.current = chart;
  }

  const deleteNode = () => {
    if (chartRef.current) {
      let oldNode = chartRef.current.get(nodeAwaitingDelete);
      if (oldNode) {
        chartRef.current.removeNode(nodeAwaitingDelete);
        setNodeAwaitingDelete(null)
      }
    }
  }

  const cancelRefClick = () => {
    setDialogIsOpen(false)
    if (editMode && currentNode["Nombre del cargo"] === "" && currentNode["Cantidad de puestos"] === "") {
      chartRef.current.removeNode(currentNode["id"]);
    }
    setEditMode(false)
  };

  const closeRefClick = () => {
    setDialogIsOpen(false)
    setEditMode(false)
  }

  const saveRefClick = () => {
    let node = JSON.parse(JSON.stringify(currentNode));
    let msg = ""
    if (node) {
      if (!currentNode["ID del cargo"]) {
        msg = "Ingrese el ID del cargo";
      } else if (!isNumeric(currentNode["ID del cargo"])) {
        msg = "El ID del cargo debe ser un número";
      } else if (!currentNode["Nombre del cargo"]) {
        msg = "Ingrese el nombre del cargo";
      } else if (!isNumeric(currentNode["Cantidad de puestos"])) {
        msg = "La cantidad de puestos debe ser un número";
      } else if (!isNumeric(currentNode["Puestos libres"])) {
        msg = "La cantidad de puestos libres debe ser un número";
      } else if (!isNumeric(currentNode["Puestos ocupados"])) {
        msg = "La cantidad de puestos ocupados debe ser un número";
      } else if (currentNode["Cantidad de puestos"] === 0 || currentNode["Cantidad de puestos"] === "0") {
        msg = "La cantidad de puestos no puede ser cero";
      } else if (!isNumeric(currentNode["Potencial motivador del cargo"])) {
        msg = "El potencial motivador del cargo debe ser un número";
      }

      let puestosTotales = parseInt(currentNode["Cantidad de puestos"]) || 0;
      let puestosOcupados = parseInt(currentNode["Puestos ocupados"]) || 0;
      if (puestosTotales < puestosOcupados) {
        showSnackbarError("La cantidad de puestos no puede ser menor a la cantidad de puestos ocupados.")
        return;
      }

      const coincidence = chartRef.current.config.nodes.find(n => n["id"] !== currentNode["id"] && n["ID del cargo"] === currentNode["ID del cargo"])

      if (coincidence) {
        showSnackbarError("Ya existe un cargo con el mismo ID")
        return false;
      }

      let oldId = "";
      if (currentNode["id"] !== currentNode["ID del cargo"]) {
        oldId = node["id"];
      }

      Object.keys(currentNode).forEach(e => node[e] = currentNode[e])
      node["resume"] = `${node["Cantidad de puestos"]}/${node["Puestos ocupados"]}/${node["Puestos libres"]}`;

      if (!currentNode.hasOwnProperty("tags") && node.hasOwnProperty("tags")) {
        const index = node.tags.indexOf("assistant");
        if (index > -1) {
          node.tags.splice(index, 1);
        }
      }
      if (msg) {
        showSnackbarError(msg)
      } else {
        //         Si cambie el id, tengo que actualizar el pid de los otros
        const children = nodesToUse.filter(n => n && n["ID del cargo superior"] && n["ID del cargo superior"] === oldId);
        children.forEach(e => {
          let childNode = chartRef.current.get(e.id);
          childNode["ID del cargo superior"] = node["ID del cargo"];
          updateNode(childNode);
        })

        updateNode(node);

        showSnackbarSuccess("Modificación realizada con éxito")
        setDialogIsOpen(false)
      }
    } else {
      msg = "Ocurrió un error editando el nodo.";
      showSnackbarError(msg)
    }

  };

  const updateNode = (node) => {
    chartRef.current.updateNode(node);
    setDialogIsOpen(false)
  }

  const showSnackbarError = useCallback((msg) => {
    dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
  }, [dispatch])

  const showSnackbarSuccess = useCallback((msg) => {
    dispatch(openSnackbarAction({ msg: msg, severity: "success" }));
  }, [dispatch])

  const handleGoBack = () => {
    setBranch("")
    setBranchDialogIsOpen(true)
  }

  const handleRecompose = () => {
    handleCloseStatisticsDialog();
    dispatch(recomposePositionsAction(branch));
  }

  const handleCloseStatisticsDialog = () => {
    setStatsDialogIsOpen(false)
    setPositionOccupation(null)
  }

  return (
    <CommonPage
      title={title}
      gridTitle={"Estructura de cargos"}
      isNotGridPage
      isLoading={loading}
    >
      {
        branches.length > 1 &&
        <DialogBranches
          branches={branches}
          setBranch={setBranch}
          branch={branch}
          open={branchDialogIsOpen}
          setBranchDialogIsOpen={setBranchDialogIsOpen}
        />
      }
      <div style={{ height: '100%' }}>
        {
          !loading && !branchDialogIsOpen &&
          <OwnOrgChart
            nodes={nodesToUse}
            dispatch={dispatch}
            color={color}
            setStatsDialogIsOpen={setStatsDialogIsOpen}
            selectedElements={selectedElements}
            potential={potential}
            selectedCompetenceGroupElements={selectedCompetenceGroupElements}
            selectedEvaluationGroupElements={selectedEvaluationGroupElements}
            setEditMode={setEditMode}
            setDialogIsOpen={setDialogIsOpen}
            setBranchDialogIsOpen={setBranchDialogIsOpen}
            setFilterPositionDialogIsOpen={setFilterPositionDialogIsOpen}
            setCompetenceGroupDialogIsOpen={setCompetenceGroupDialogIsOpen}
            setEvaluationGroupDialogIsOpen={setEvaluationGroupDialogIsOpen}
            setConfirmDialogIsOpen={setConfirmDialogIsOpen}
            setPotentialDialogIsOpen={setPotentialDialogIsOpen}
            setCurrentNode={setCurrentNode}
            setNodeAwaitingDelete={setNodeAwaitingDelete}
            setChartRef={setChartRef}
            handleGoBack={handleGoBack}
            hasMultipleBranches={branches.length > 1 ? true : false}
            setPositionOccupation={setPositionOccupation}
            theme={gridTheme}
          />
        }
      </div>
      <ConfirmActionDialog
        open={confirmDialogIsOpen}
        nodeId={nodeAwaitingDelete}
        handleClose={closeConfirmActionDialog}
        deleteNode={deleteNode}
        saveChart={saveChart}
      />
      {
        dialogIsOpen &&
        <DetailsEditDialog
          dialogIsOpen={dialogIsOpen}
          editMode={editMode}
          currentNode={currentNode}
          positionClassifications={positionClassifications}
          competencyGroupList={competencyGroupList}
          evaluationGroupList={evaluationGroupList}
          handleChangeField={handleChangeField}
          cancelRefClick={cancelRefClick}
          closeRefClick={closeRefClick}
          saveRefClick={saveRefClick}
          handleClose={() => setDialogIsOpen(false)}
        />
      }
      <StatisticsGridDialog
        title={title}
        open={statsDialogIsOpen}
        statistics={orgChart}
        color={color}
        chart={chartRef.current}
        theme={gridTheme}
        positionOccupation={positionOccupation}
        positionOccupationData={positionOccupationData}
        setPositionOccupation={setPositionOccupation}
        setStatsDialogIsOpen={setStatsDialogIsOpen}
        handleRecompose={handleRecompose}
        handleClose={handleCloseStatisticsDialog}
        positionClassifications={positionClassifications}
        evaluationGroupList={evaluationGroupList}
      />
      <FilterPositionDialog
        open={filterPositionDialogIsOpen}
        handleClose={() => setFilterPositionDialogIsOpen(false)}
        handleSave={highlightPositions}
        labels={positionClassifications}
      />
      <PotentialPositionDialog
        open={potentialDialogIsOpen}
        handleClose={() => setPotentialDialogIsOpen(false)}
        handleSave={highlightPotential}
      />
      <CompetenceGroupDialog
        open={competenceGroupDialogIsOpen}
        handleClose={() => setCompetenceGroupDialogIsOpen(false)}
        handleSave={highlightCompetenceGroups}
        competencyGroupList={competencyGroupList}
      />
      <EvaluationGroupDialog
        open={evaluationGroupDialogIsOpen}
        handleClose={() => setEvaluationGroupDialogIsOpen(false)}
        handleSave={highlightEvaluationGroups}
        evaluationGroupList={evaluationGroupList}
      />
    </CommonPage>
  );
}

export default PositionEditor;