import React, { useMemo, useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import Item from "./poll/item";
import DuplicateDialog from "../components/dialogs/duplicateDialog";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import usePollSettings from "./usePollSettings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const PollSettings = ({ history }) => {

  const [viewFiltered, setViewFiltered] = useState(true);
  const {
    color,
    roles,
    polls,
    modes,
    processList,
    pollTypes,
    isLoading,
    createPollIsOpen,
    duplicateData,
    isImport,
    setCreatePollIsOpen,
    handleOpenDuplicationDialog,
    handleCloseDuplicationDialog,
    exportPoll,
    importPoll,
  } = usePollSettings();

  const customItems = (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={viewFiltered}
            onChange={() => setViewFiltered(prev => !prev)}
            value={viewFiltered}
            color="primary"
          />
        }
        label={viewFiltered ? 'Activas' : 'Todas'}
        labelPlacement="start"
        style={{ marginRight: 5 }}
      />
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={() => history.push(paths.pollScreenSelector)}
        type={"goBack"}
      />
      <CustomIconButton
        title={"Importar encuesta"}
        onClick={importPoll}
        type={"upload"}
      />
    </>
  )

  const pollNames = polls?.map(poll => poll.pollName) || [];

  const getIsActive = ({startDate, endDate}) => {
    const newEndDate = createDateFromDDMMYYYY(endDate).setHours(0, 0, 0, 0).valueOf();
    const newStartDate = createDateFromDDMMYYYY(startDate).setHours(0, 0, 0, 0).valueOf();
    const today = new Date().setHours(0, 0, 0, 0).valueOf();

    return today >= newStartDate && today <= newEndDate;
  }

  const filteredPolls = useMemo(() => {
    if (!viewFiltered) return polls;
    return polls.filter(getIsActive);
  }, [polls, viewFiltered])

  return (
    <ABMLayout
      title={getLocalizedString("pollSettings")}
      isLoading={isLoading}
      showCreateButton={!createPollIsOpen}
      createButtonTitle={getLocalizedString("newPoll")}
      createButtonOnClick={() => setCreatePollIsOpen(true)}
      customItems={[customItems]}
      useCustomItemsEnd
    >
      {
        createPollIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Item
            data={null}
            roles={roles}
            pollTypes={pollTypes}
            modes={modes}
            color={color}
            closeNewPoll={() => setCreatePollIsOpen(false)}
            isLoading={isLoading}
            pollNames={pollNames}
            processList={processList}
          />
        </Grid>
      }
      {
        filteredPolls.map((poll) =>
          <Grid container item xs={12} style={{ margin: "10px 0px" }} key={poll.code}>
            <Item
              openDuplicateDialog={(data) => handleOpenDuplicationDialog(data)}
              data={poll}
              roles={roles}
              pollTypes={pollTypes}
              modes={modes}
              color={color}
              isLoading={isLoading}
              exportPoll={exportPoll}
              pollNames={pollNames}
              processList={processList}
              getIsActive={getIsActive}
            />
          </Grid>
        )
      }
      {
        Boolean(duplicateData) &&
        <DuplicateDialog
          open={Boolean(duplicateData)}
          data={duplicateData}
          isImport={isImport}
          pollNames={pollNames}
          handleClose={handleCloseDuplicationDialog}
        />
      }
    </ABMLayout>
  )
}

export default PollSettings;